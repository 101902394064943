import { TableCell, TableHead, TableRow } from '@mui/material/'

const TablePdlToLiquidateHeader = ({ classes, setOpenDatePicker }) => {
	return (
		<TableHead>
			<TableRow className={classes.tableRow}>
				<TableCell className={classes.table}>Incluir</TableCell>
				<TableCell className={classes.table}>Perfil Inversor</TableCell>
				<TableCell className={classes.table}>IBAN</TableCell>
				<TableCell className={classes.table}>Proyecto</TableCell>
				<TableCell className={classes.table}>Importe Inversión</TableCell>
				<TableCell className={classes.table}>Liquidación</TableCell>
				<TableCell className={classes.table}>Ingreso recibido</TableCell>
				<TableCell className={classes.table} align='right'>
					<span>Fecha de Liquidación</span>
					<span onClick={() => setOpenDatePicker(true)} className={classes.ChangeValue + ' ' + classes.ChangeValueFont}>
						Cambiar
					</span>
				</TableCell>
				<TableCell className={classes.table}>Intereses anual</TableCell>
				<TableCell className={classes.table}>Intereses</TableCell>
				<TableCell className={classes.table}>Retención</TableCell>
				<TableCell className={classes.table}>Importe Retención</TableCell>
				<TableCell className={classes.table}>A Pagar</TableCell>
			</TableRow>
		</TableHead>
	)
}

export default TablePdlToLiquidateHeader
