import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle'

const styles = (theme) => ({
  ChangeValue: {
    display: 'block',
    position: 'relative',
    top: -3,
    height: '0px',
  },
  ChangeValueFont: {
    fontSize: '0.6rem',
    color: 'blue',
    cursor: 'pointer',
  },
  ProjectTableGridItem: {
    margin: '2% auto',
  },
  ModalWidth: {
    minWidth: '240px',
  },
  LoaderContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  table: {
    fontSize: '0.7rem !important',
    paddingLeft: 0,
    whiteSpace: 'nowrap',
  },
  tableRow: {
    '& th:nth-child(2)': {
      zIndex: '10',
      position: 'sticky !important',
      width: '5em',
      left: '0',
      top: 'auto',
      borderTopWidth: '1px',
      backgroundColor: '#fff',
    },
  },
  ...modalStyle(theme),
})

export default styles
