import { useEffect, useState } from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import { styled } from '@mui/material/styles'
import { Box, LinearProgress, linearProgressClasses } from '@mui/material'
import { Euro } from '@mui/icons-material'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import role from 'config/roles/roles'
import { matchCurrencyToDecimal } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import styles from '../styles/projectInvestmentsProgressBarStyles'

const useStyles = makeStyles(styles)

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
	height: 7,
	borderRadius: 5,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
		backgroundColor: theme.palette.mode === 'light' ? '#c0bbac' : '#308fe8',
	},
}))

const ProjectInvestmentsProgressBars = ({ totalInvestment, projectCost, totalInvertible, invertible, userInfo }) => {
	const classes = useStyles()

	const [total, setTotal] = useState(0)

	useEffect(() => {
		if (
			(totalInvertible && Number(matchCurrencyToDecimal(totalInvertible)) < 1) ||
			(!invertible && userInfo.role === role.INVESTOR_ROLE)
		) {
			return setTotal(99.99)
		}
		const currencyTotalInvertible = typeof totalInvestment === 'string' ? totalInvestment : '0.00 €'

		setTotal(
			(Number(matchCurrencyToDecimal(currencyTotalInvertible)) / Number(matchCurrencyToDecimal(projectCost))) * 100,
		)
	}, [totalInvestment])

	return (
		<>
			{
				<Box sx={{ flexGrow: 1, height: 75 }}>
					<Typography className={classes.title}>
						<Euro /> Capital invertido
					</Typography>
					<div>
						<BorderLinearProgress variant='determinate' value={total} />
						<GridContainer className={classes.textContainer}>
							<GridItem xs={6} className={classes.investmentContainer}>
								{total < 99.99 && (
									<>
										<Typography className={classes.smallText}>Inversión actual</Typography>
										<Typography className={classes.smallText + ' ' + classes.textNoWrap}>{totalInvestment}</Typography>
									</>
								)}
							</GridItem>

							<GridItem xs={6} className={classes.totalContainer}>
								<Typography className={classes.endDateTitle}>Total</Typography>
								<Typography className={classes.smallText + ' ' + classes.endText + ' ' + classes.textNoWrap}>
									{projectCost}
								</Typography>
							</GridItem>
						</GridContainer>
					</div>
				</Box>
			}
		</>
	)
}

export default ProjectInvestmentsProgressBars
