import { useContext, useEffect } from 'react'
import { format } from 'date-fns'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import GridContainer from 'components/Grid/GridContainer'
import CustomInput from 'components/CustomInput/CustomInput'
import GridItem from 'components/Grid/GridItem'
import { LiquidatedProjectContext } from 'contexts/liquidatedProjectContext'

const ProjectInputs = () => {
	const { selectedProject, handleLiquidatedProjectOnBlur, setSelectedProject, handleSubmitData } =
		useContext(LiquidatedProjectContext)
	const {
		_id,
		finishDateUpdated,
		projectAddress,
		startDateUpdated,
		interiorDesignExpenses,
		purchasePrice,
		maintenanceExpenses,
		acquisitionExpenses,
		constructionExpenses,
		totalInvestmentBeforeExpenses,
		findersManagementFee,
		totalInvestment,
		netSellingPrice,
		plusvalia,
		totalDuration,
		marketingExpenses,
		salePriceFinished,
		liquidityProvider,
		benefit,
		profitMargin,
	} = selectedProject

	useEffect(() => {
		setSelectedProject({
			...selectedProject,
			// eslint-disable-next-line no-use-before-define
			totalInvestmentBeforeExpenses: calculateSum([
				interiorDesignExpenses,
				purchasePrice,
				maintenanceExpenses,
				acquisitionExpenses,
				constructionExpenses,
			]),
		})
	}, [interiorDesignExpenses, purchasePrice, maintenanceExpenses, acquisitionExpenses, constructionExpenses, _id])

	useEffect(() => {
		setSelectedProject({
			...selectedProject,
			// eslint-disable-next-line no-use-before-define
			totalInvestment: calculateSum([totalInvestmentBeforeExpenses, findersManagementFee]),
		})
	}, [totalInvestmentBeforeExpenses, findersManagementFee, _id])

	useEffect(() => {
		setSelectedProject({
			...selectedProject,
			// eslint-disable-next-line no-use-before-define
			netSellingPrice: calculateSum([-liquidityProvider, salePriceFinished, -marketingExpenses, -plusvalia]),
		})
	}, [totalInvestment, liquidityProvider, salePriceFinished, marketingExpenses, plusvalia, _id])
	useEffect(() => {
		setSelectedProject({
			...selectedProject,
			// eslint-disable-next-line no-use-before-define
			benefit: calculateSum([netSellingPrice, -totalInvestment]),
		})
	}, [netSellingPrice, totalInvestment, _id])

	useEffect(() => {
		if (benefit === '' || totalInvestment === '' || totalInvestment === 0) {
			setSelectedProject({ ...selectedProject, profitMargin: 0 })
		} else {
			setSelectedProject({
				...selectedProject,
				profitMargin: `${Number((benefit / totalInvestment) * 100).toFixed(2)} %`,
			})
		}
	}, [benefit, totalInvestment, _id])

	const calculateSum = (values) => {
		let result = 0
		values.map((value) => value !== '' && (result = parseFloat(result) + parseFloat(value)))
		return result.toFixed(2)
	}

	return (
		<>
			{selectedProject._id && (
				<GridItem xs={12} style={{ margin: '2% auto' }}>
					<Card>
						<form id='projectInputForm' onSubmit={handleSubmitData}>
							<GridContainer style={{ marginBottom: '26px', padding: '0 15px' }}>
								<GridItem xs={12} style={{ backgroundColor: '#c0bbac' }}>
									<Typography variant='h6' sx={{ marginBottom: '0' }}>
										Datos del proyecto - {projectAddress}
									</Typography>
								</GridItem>
								{finishDateUpdated && (
									<>
										<GridItem xs={12} md={6} sx={{ margin: '0 auto' }}>
											<CustomInput
												labelText={'Fecha de inicio de proyecto *'}
												id='projectStartDate'
												formControlProps={{
													fullWidth: true,
												}}
												inputProps={{
													value: finishDateUpdated && format(new Date(startDateUpdated), 'dd/MM/yyyy'),
													type: 'text',
													required: true,
													disabled: true,
												}}
											/>
										</GridItem>
										<GridItem xs={12} md={6}>
											<CustomInput
												labelText={'Fecha de fin de proyecto *'}
												id='projectEndDate'
												formControlProps={{
													fullWidth: true,
												}}
												inputProps={{
													value: finishDateUpdated && format(new Date(finishDateUpdated), 'dd/MM/yyyy'),
													type: 'text',
													required: true,
													disabled: true,
												}}
											/>
										</GridItem>
										<GridItem xs={12} md={6}>
											<CustomInput
												labelText={'Duración total(meses) *'}
												id='totalDuration'
												formControlProps={{
													fullWidth: true,
												}}
												inputProps={{
													value: finishDateUpdated && totalDuration(finishDateUpdated, startDateUpdated),
													type: 'number',
													required: true,
													disabled: true,
												}}
											/>
										</GridItem>
									</>
								)}
								<GridItem xs={12} md={6}>
									<CustomInput
										labelText='Precio de compra del inmueble *'
										id='purchasePrice'
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											value: purchasePrice,
											onChange: (e) => setSelectedProject({ ...selectedProject, purchasePrice: e.target.value }),
											onBlur: (e) => {
												handleLiquidatedProjectOnBlur(e)
											},
											type: 'number',
											required: true,
										}}
									/>
								</GridItem>

								<GridItem xs={12} md={6}>
									<CustomInput
										labelText='Gastos de adquisicion del inmueble *'
										id='acquisitionExpenses'
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											value: acquisitionExpenses,
											onChange: (e) => setSelectedProject({ ...selectedProject, acquisitionExpenses: e.target.value }),
											onBlur: (e) => {
												handleLiquidatedProjectOnBlur(e)
											},
											type: 'number',
											required: true,
										}}
									/>
								</GridItem>
								<GridItem xs={12} md={6}>
									<CustomInput
										labelText='Gastos de construcción del inmueble *'
										id='constructionExpenses'
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											value: constructionExpenses,
											onChange: (e) =>
												setSelectedProject({
													...selectedProject,
													constructionExpenses: e.target.value,
												}),
											onBlur: (e) => {
												handleLiquidatedProjectOnBlur(e)
											},
											type: 'number',
											required: true,
										}}
									/>
								</GridItem>
								<GridItem xs={12} md={6}>
									<CustomInput
										labelText='Gastos de mantenimineto del inmueble *'
										id='maintenanceExpenses'
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											value: maintenanceExpenses,
											onChange: (e) => setSelectedProject({ ...selectedProject, maintenanceExpenses: e.target.value }),
											onBlur: (e) => {
												handleLiquidatedProjectOnBlur(e)
											},
											type: 'number',
											required: true,
										}}
									/>
								</GridItem>
								<GridItem xs={12} md={6}>
									<CustomInput
										labelText='Gastos de interiorismo *'
										id='interiorDesignExpenses'
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											value: interiorDesignExpenses,
											onChange: (e) =>
												setSelectedProject({
													...selectedProject,
													interiorDesignExpenses: e.target.value,
												}),
											onBlur: (e) => {
												handleLiquidatedProjectOnBlur(e)
											},
											type: 'number',
											required: true,
										}}
									/>
								</GridItem>
								<GridItem xs={12} md={6}>
									<CustomInput
										labelText='Inversión total antes de gastos de gestión *'
										id='totalInvestmentBeforeExpenses'
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											value: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
												totalInvestmentBeforeExpenses,
											),
											required: true,
											disabled: true,
										}}
									/>
								</GridItem>
								<GridItem xs={12} md={6}>
									<CustomInput
										labelText='Finders + management fee *'
										id='findersManagementFee'
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											value: findersManagementFee,
											onChange: (e) =>
												setSelectedProject({
													...selectedProject,
													findersManagementFee: e.target.value,
												}),
											onBlur: (e) => {
												handleLiquidatedProjectOnBlur(e)
											},
											type: 'number',
											required: true,
										}}
									/>
								</GridItem>
								<GridItem xs={12} md={6}>
									<CustomInput
										labelText='Inversión total *'
										id='totalInvestment'
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											value: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
												totalInvestment,
											),
											required: true,
											disabled: true,
										}}
									/>
								</GridItem>
								<GridItem xs={12} md={6}>
									<CustomInput
										labelText='Proveedor de Liquidez *'
										id='liquidityProvider'
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											value: liquidityProvider,
											onChange: (e) => setSelectedProject({ ...selectedProject, liquidityProvider: e.target.value }),
											onBlur: (e) => {
												handleLiquidatedProjectOnBlur(e)
											},
											type: 'number',
											required: true,
										}}
									/>
								</GridItem>
								<GridItem xs={12} md={6}>
									<CustomInput
										labelText='Precio de venta del inmueble(s) terminado(s) *'
										id='salePriceFinished'
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											value: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
												salePriceFinished,
											),
											required: true,
											disabled: true,
										}}
									/>
								</GridItem>
								<GridItem xs={12} md={6}>
									<CustomInput
										labelText='Gastos de comercialización *'
										id='marketingExpenses'
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											value: marketingExpenses,
											onChange: (e) => setSelectedProject({ ...selectedProject, marketingExpenses: e.target.value }),
											onBlur: (e) => {
												handleLiquidatedProjectOnBlur(e)
											},
											type: 'number',
											required: true,
										}}
									/>
								</GridItem>
								<GridItem xs={12} md={6}>
									<CustomInput
										labelText='Plusvalía *'
										id='plusvalia'
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											value: plusvalia,
											onChange: (e) => setSelectedProject({ ...selectedProject, plusvalia: e.target.value }),
											onBlur: (e) => {
												handleLiquidatedProjectOnBlur(e)
											},
											type: 'number',
											required: true,
										}}
									/>
								</GridItem>
								<GridItem xs={12} md={6}>
									<CustomInput
										labelText='Precio neto de venta *'
										id='netSellingPrice'
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											value: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
												netSellingPrice,
											),
											required: true,
											disabled: true,
										}}
									/>
								</GridItem>
								<GridItem xs={12} md={6}>
									<CustomInput
										labelText='Beneficio *'
										id='benefit'
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											value: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(benefit),
											required: true,
											disabled: true,
										}}
									/>
								</GridItem>
								<GridItem xs={12} md={6}>
									<CustomInput
										labelText='Margen de beneficio *'
										id='profitMargin'
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											value: profitMargin,
											required: true,
											disabled: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</form>
					</Card>
				</GridItem>
			)}
		</>
	)
}

export default ProjectInputs
