import { differenceInCalendarDays, format } from 'date-fns'
import { es } from 'date-fns/locale'

export const pdlsMapper = (pdls, liquidityDate) => {
	return pdls?.map((item) => {
		const name = !!item?.profileInvestor?.businessName
			? item?.profileInvestor?.businessName
			: !!item?.profileInvestor?.representativeName
			? `${item?.profileInvestor?.representativeName} ${item?.profileInvestor?.representativeLastName}`.trim()
			: `${item?.profileInvestor?.name} ${item?.profileInvestor?.lastName}`

		const differenceInDays = !!item?.incomeRecivedDate
			? differenceInCalendarDays(new Date(liquidityDate), new Date(item?.incomeRecivedDate))
			: 0

		const fee =
			((Number(item?.amountPdl) * (Number(item?.typeOfInterest?.replace('%', '')) / 100)) / 360) * differenceInDays

		const retentionInNumber = Number(item?.profileInvestor?.retention?.replace('%', '')) / 100

		const retentionFee = Number(fee) * retentionInNumber

		const liquidation =
			item?.liquidationFields?.liquidation || item?.liquidation || item.profileInvestor.liquidation || 'pay'

		const toPay =
			liquidation === 'benefits-only'
				? fee - retentionFee
				: Number(item.amountPdl) + (1 - retentionInNumber) * (fee || 0)

		return {
			_id: item._id,
			name: name,
			fee: fee,
			retentionFee: retentionFee,
			liquidationFields: {
				...item?.liquidationFields,
				toPay: toPay,
				liquidation: liquidation,
				benefit: fee,
				retentionAmount: retentionFee,
				retention: item?.profileInvestor?.retention,
			},
			profileInvestor: item.profileInvestor,
			contractId: item.contractId,
			ContractUrl: item.ContractUrl,
			createdAt: item.createdAt,
			amountPdl: item.amountPdl,
			incomeRecivedDate: item.incomeRecivedDate,
			typeOfInterest: item.typeOfInterest,
			liquidityDate: liquidityDate,
		}
	})
}

export const pdlsExportExcelMapper = (pdls, projectAddress, liquidityDate) => {
	return pdls?.map((item) => {
		const name = !!item?.profileInvestor?.businessName
			? item?.profileInvestor?.businessName
			: !!item?.profileInvestor?.representativeName
			? `${item?.profileInvestor?.representativeName} ${item?.profileInvestor?.representativeLastName}`.trim()
			: `${item?.profileInvestor?.name} ${item?.profileInvestor?.lastName}`

		const differenceInDays = !!item?.incomeRecivedDate
			? differenceInCalendarDays(new Date(liquidityDate), new Date(item?.incomeRecivedDate))
			: 0

		const fee =
			((Number(item?.amountPdl) * (Number(item?.typeOfInterest?.replace('%', '')) / 100)) / 360) * differenceInDays

		const retentionInNumber = Number(item?.profileInvestor?.retention?.replace('%', '')) / 100

		const retentionFee = Number(fee) * retentionInNumber

		const liquidation = item?.liquidationFields?.liquidation ?? item?.liquidation ?? 'pay'

		const toPay =
			liquidation === 'benefits-only'
				? fee - retentionFee
				: Number(item.amountPdl) + (1 - retentionInNumber) * (fee || 0)

		const liquidationType = {
			pay: 'Pagar',
			reinvest: 'Reinvertir',
			'benefits-only': 'Pagar solo beneficios',
		}

		return {
			_id: item._id,
			name: name,
			iban: item.profileInvestor.bankAccountNumber,
			projectAddress,
			fee: fee || 0,
			retentionFee: retentionFee || 0,
			liquidation: liquidationType[liquidation] || 'Pagar',
			toPay: toPay || 0,
			retention: item.profileInvestor.retention,
			amountPdl: item.amountPdl,
			incomeRecivedDate: !!item.incomeRecivedDate
				? format(new Date(item?.incomeRecivedDate), 'dd/MM/yyyy', { locale: es })
				: 'Sin confirmar',
			typeOfInterest: item.typeOfInterest,
			liquidityDate: format(new Date(liquidityDate), 'dd/MM/yyyy', { locale: es }),
		}
	})
}
