/**
 * Calculates the total import of a list of prescribers.
 *
 * @param {number[]} prescribersImport - An array of import amounts,
 * each represented as a string with commas as thousands separators.
 * @return {number} The total import amount.
 */
export const getTotalImport = (prescribersImport) => {
	/**
	 * This function removes commas from a string representing a number
	 * and converts it into a float.
	 * @param {string} numStr - A string representing a number with commas.
	 * @return {number} The number as a float.
	 */

	const removeCommasAndParse = (acc, currentNumString) =>
		acc + parseFloat(currentNumString.replace('.', '').replace(/,/g, '.').replace('€', ''))

	// Reduce the array of import amounts to a single number,
	// adding each amount to the accumulator.
	return prescribersImport.reduce(removeCommasAndParse, 0)
}
