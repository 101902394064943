import { useSelector, useDispatch } from 'react-redux'
import { useContext, useEffect } from 'react'
import { format, differenceInCalendarDays, set } from 'date-fns'
import { TableCell, TableRow, Input } from '@mui/material/'
import { makeStyles, MenuItem, FormControl, Select, Checkbox } from '@material-ui/core'
import { replaceStringToNum } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import {
	handleBenefit12,
	ibanValidator,
	changeIncomeReciveDate,
	calculateToPay,
} from 'shared/helpers/functions-helpers'
import { matchDate } from 'shared/matchData/matchData'
import { matchNumberToCurrency } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import { LiquidatedProjectContext } from 'contexts/liquidatedProjectContext'
import { registerEraserHeadProject } from 'local_redux/actions/eraserHeadActions'
import { updateEraserHeadProject } from 'local_redux/actions/eraserHeadActions'
import { calculateRetentionAmount } from '../functions'
import { fv } from 'shared/helpers/fv'
import styles from '../styles/investmentsTableStyles'

const InvestmentTableBody = ({ tirCoinvestor }) => {
	const dispatch = useDispatch()
	const useStyles = makeStyles(styles)
	const classes = useStyles()

	const { selectedProject, investmentsBySelectedProject, setInvestmentsBySelectedProject } =
		useContext(LiquidatedProjectContext)
	const { depositDate, projectCost, startDateUpdated } = selectedProject

	const { investmentsByProject } = useSelector((state) => state.investmentsByProject)

	//Handle Liquidation pay or reinvest input and send info to eraser
	useEffect(() => {
		const data = investmentsBySelectedProject.map((project) => {
			const depositDateFormat = new Date(
				format(set(new Date(depositDate), { hours: 0, minutes: 0, seconds: 0 }), "yyyy-MM-dd'T'HH:mm:ss"),
			)
			const incomeRecivedDateFormat = format(
				set(project.incomeRecivedDate ? new Date(project.incomeRecivedDate) : new Date(), {
					hours: 0,
					minutes: 0,
					seconds: 0,
				}),
				"yyyy-MM-dd'T'HH:mm:ss",
			)
			const startDateUpdatedFormat = format(
				set(new Date(startDateUpdated), { hours: 0, minutes: 0, seconds: 0 }),
				"yyyy-MM-dd'T'HH:mm:ss",
			)
			const days = project.incomeRecivedDate
				? differenceInCalendarDays(
						depositDateFormat,
						new Date(changeIncomeReciveDate(incomeRecivedDateFormat, startDateUpdatedFormat)),
				  )
				: 0
			const reversion =
				tirCoinvestor && tirCoinvestor > 0
					? Number(fv(tirCoinvestor / 100, days / 365, 0, -replaceStringToNum(project.amount), 0)).toFixed(2)
					: 0
			const benefit =
				tirCoinvestor && tirCoinvestor > 0
					? (
							fv(tirCoinvestor / 100, days / 365, 0, -replaceStringToNum(project.amount), 0) -
							replaceStringToNum(project.amount)
					  ).toFixed(2)
					: 0
			const benefit12 = handleBenefit12(
				differenceInCalendarDays(new Date(depositDate), new Date(project.incomeRecivedDate)),
				replaceStringToNum(project.amount),
			)
			const profit12LessBenefit = parseFloat(benefit12 - Number(benefit))
			const beforeFee = profit12LessBenefit * 0.0287248560013554 + profit12LessBenefit
			const fee = (beforeFee - profit12LessBenefit) * project.feeRate

			project.benefit = Number(benefit)
			project.toPay = calculateToPay(project.retention, benefit, project.amount, project.liquidation)
			project.investorTIR = tirCoinvestor ? Number(tirCoinvestor).toFixed(2) : 0
			project.retentionAmount = calculateRetentionAmount(project.retention, Number(benefit))
			project.taxBase = parseFloat((benefit12 - Number(benefit)).toFixed(2))
			project.fee = project.incomeRecivedDate ? parseFloat(fee.toFixed(2)) : 0
			project.reversion = reversion

			let investmentInfo = {
				projectId: project.project._id,
				investmentId: project._id,
				benefit: Number(benefit),
				reversion: reversion,
				taxBase: parseFloat((benefit12 - Number(benefit)).toFixed(2)),
				feeRate: project.incomeRecivedDate ? parseFloat(project.feeRate.toFixed(2)) : 0,
				fee: project.incomeRecivedDate ? parseFloat(fee.toFixed(2)) : 0,
				investment: true,
			}

			dispatch(registerEraserHeadProject(investmentInfo))
			return project
		})

		setInvestmentsBySelectedProject(data)
	}, [tirCoinvestor])

	const handleChangeLiquidation = (e, info) => {
		const { name, value } = e.target

		const data = investmentsBySelectedProject.map((item) => {
			if (item._id === name) {
				item.csvProfileInclude = value === 'reinvest' ? false : true
				item.liquidation = value
				item.toPay = calculateToPay(item.retention, item.benefit, item.amount, value)
			}
			return item
		})

		setInvestmentsBySelectedProject(data)

		let investmentInfo = {
			projectId: info.investmentProject._id,
			investmentId: info._id,
			liquidation: value,
			csvProfileInclude: value === 'reinvest' ? false : true,
			investment: true,
		}

		dispatch(registerEraserHeadProject(investmentInfo))
	}

	const handleChangeTaxBase = (event) => {
		const { id, value } = event.target
		const data = investmentsBySelectedProject.map((item) => {
			if (item._id === id) {
				item.taxBase = value
			}
			return item
		})

		setInvestmentsBySelectedProject(data)
	}
	//Handle onBlur taxBase
	const handleOnBlurTaxBase = (event, info) => {
		const { value } = event.target

		let investmentInfo = {
			projectId: info.investmentProject._id,
			investmentId: info._id,
			taxBase: value,
			investment: true,
		}

		dispatch(registerEraserHeadProject(investmentInfo))
	}
	//Handle change fee rate
	const handleChangefeeRate = (event) => {
		const { id, value } = event.target

		const data = investmentsBySelectedProject.map((item) => {
			let benefit12 = handleBenefit12(
				differenceInCalendarDays(new Date(depositDate), new Date(item.incomeRecivedDate)),
				replaceStringToNum(item.amount),
			)
			const profit12LessBenefit = parseFloat(benefit12 - Number(value))
			const beforeFee = profit12LessBenefit * 0.0287248560013554 + profit12LessBenefit
			const fee = (beforeFee - profit12LessBenefit) * value

			if (item._id === id) {
				item.feeRate = value
				item.fee = item.incomeRecivedDate ? parseFloat(fee.toFixed(2)) : 0
			}
			return item
		})

		setInvestmentsBySelectedProject(data)
	}
	//Handle onBlur feeRate  and related values to eraser
	const handleOnBlurfeeRate = (event, info) => {
		const { value } = event.target

		const benefit12 = handleBenefit12(
			differenceInCalendarDays(new Date(selectedProject.depositDate), new Date(info.incomeRecivedDate)),
			replaceStringToNum(info.amount),
		)
		const profit12LessBenefit = parseFloat(benefit12 - Number(value))
		const beforeFee = profit12LessBenefit * 0.0287248560013554 + profit12LessBenefit
		const fee = (beforeFee - profit12LessBenefit) * value

		let investmentInfo = {
			projectId: info.investmentProject._id,
			investmentId: info._id,
			feeRate: info.incomeRecivedDate ? parseFloat(value) : 0,
			fee: info.incomeRecivedDate ? parseFloat(fee.toFixed(2)) : 0,
			investment: true,
		}

		dispatch(registerEraserHeadProject(investmentInfo))
	}
	const handleChangefee = (event) => {
		const { id, value } = event.target
		const data = investmentsBySelectedProject.map((item) => {
			if (item._id === id) {
				item.fee = Number(value)
			}
			return item
		})

		setInvestmentsBySelectedProject(data)
	}
	//Handle onBlur feeRate  and related values to eraser
	const handleOnBlurfee = (event, info) => {
		const { value } = event.target
		let investmentInfo = {
			projectId: info.investmentProject._id,
			investmentId: info._id,
			fee: parseFloat(value),
			investment: true,
		}

		dispatch(registerEraserHeadProject(investmentInfo))
	}
	//Handle include profile in csv file and send data to eraser
	const handleChangeIncludeOnCsv = (event) => {
		const { id } = event.target
		let value = ''
		const data = investmentsBySelectedProject.map((item) => {
			if (item._id === id) {
				value = !item.csvProfileInclude
				item.csvProfileInclude = !value ? false : true
			}
			return item
		})

		let investmentInfo = {
			projectId: selectedProject._id,
			investmentId: id,
			csvProfileInclude: value,
			investment: true,
		}

		dispatch(updateEraserHeadProject(investmentInfo))
		setInvestmentsBySelectedProject(data)
	}

	return (
		<>
			{investmentsByProject &&
				investmentsBySelectedProject?.map(
					({
						_id,
						liquidation,
						investorProfile,
						incomeRecivedDate,
						amount,
						retention,
						benefit,
						reversion,
						retentionAmount,
						project: investmentProject,
						investorTIR,
						toPay,
						feeRate,
						taxBase,
						benefitForIRR,
						csvProfileInclude,
						fee,
					}) => (
						<TableRow
							className={classes.tableRow}
							key={_id}
							sx={{
								'&:last-child td, &:last-child th': { border: 0 },
							}}
						>
							{/* INCLUDE IN CSV */}
							<TableCell className={classes.table} component='th' scope='row'>
								<Checkbox
									id={_id}
									checked={csvProfileInclude}
									onChange={handleChangeIncludeOnCsv}
									label=''
									size='small'
									color='default'
								/>
							</TableCell>
							{/* INVESTOR PROFILE */}
							<TableCell className={classes.table} component='th' scope='row'>
								{investorProfile.businessName}
							</TableCell>
							{/* IBAN */}
							<TableCell
								className={classes.table}
								style={{
									color:
										(liquidation === 'pay' || liquidation === 'benefits-only') &&
										ibanValidator(investorProfile.bankAccountNumber)
											? 'red'
											: 'green',
								}}
							>
								{!investorProfile.bankAccountNumber ? 'Sin IBAN' : investorProfile.bankAccountNumber}
							</TableCell>
							{/* INCOME RECIVED DATE - FECHA DE INVERSION*/}
							<TableCell className={classes.table} align='right'>
								{incomeRecivedDate
									? matchDate(
											changeIncomeReciveDate(incomeRecivedDate, format(new Date(startDateUpdated), 'yyyy-MM-dd')),
											true,
									  )
									: 'No confirmado'}
							</TableCell>
							{/* FECHA DE DEVOLUCIÓN  */}
							<TableCell className={classes.table} align='right'>
								{format(depositDate, 'dd/MM/yyyy')}
							</TableCell>
							{/* IMPORTE */}
							<TableCell className={classes.table} align='right'>
								{amount}
							</TableCell>
							{/* LIQUIDACION */}
							<TableCell className={classes.table} align='right'>
								<FormControl variant='standard' sx={{ m: 1, minWidth: 120 }}>
									<Select
										className={classes.table}
										value={liquidation}
										name={_id}
										onChange={(e) => handleChangeLiquidation(e, { investmentProject, _id })}
									>
										<MenuItem value={'pay'}>Pagar</MenuItem>
										<MenuItem value={'reinvest'}>Reinvertir</MenuItem>
										<MenuItem value={'benefits-only'}>Pagar solo beneficios</MenuItem>
									</Select>
								</FormControl>
							</TableCell>
							{/* PARTICIPACIÓN */}
							<TableCell className={classes.table} align='right'>
								{parseFloat((replaceStringToNum(amount) / replaceStringToNum(projectCost)) * 100).toFixed(2)}%
							</TableCell>
							{/* RETENCIÓN */}
							<TableCell className={classes.table} align='right'>
								{retention}
							</TableCell>
							{/* REVERSION */}
							<TableCell className={classes.table} align='right'>
								{reversion ? matchNumberToCurrency(reversion) : 0}
							</TableCell>
							{/* BENEFICIO */}
							<TableCell className={classes.table} align='right'>
								{benefit ? matchNumberToCurrency(benefit) : 0}
							</TableCell>
							{/* IMPORTE DE RETENCIÓN */}
							<TableCell className={classes.table} align='right'>
								{retentionAmount}
							</TableCell>
							{/* TIR INVERSOR */}
							<TableCell className={classes.table} align='right'>
								<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
									<Input
										className={classes.table}
										disableUnderline={true}
										style={{
											maxWidth: 40,
										}}
										id={_id}
										value={investorTIR ? investorTIR : ''}
										inputProps={{
											placeholder: incomeRecivedDate ? 'TIR Inversor *' : 'Sin Fecha de Ingreso',
											disabled: incomeRecivedDate ? false : true,
											required: true,
											disabled: true,
										}}
									/>
									{incomeRecivedDate ? '%' : ''}
								</div>
							</TableCell>
							{/* A PAGAR */}
							<TableCell
								className={classes.table}
								align='right'
								style={{
									minWidth: 70,
								}}
							>
								{toPay ? toPay : '0 €'}
							</TableCell>
							{/* DAYS */}
							<TableCell
								className={classes.table}
								align='right'
								style={{
									minWidth: 90,
								}}
							>
								{incomeRecivedDate
									? differenceInCalendarDays(
											new Date(
												format(
													set(new Date(depositDate), { hours: 0, minutes: 0, seconds: 0 }),
													"yyyy-MM-dd'T'HH:mm:ss",
												),
											),
											new Date(
												changeIncomeReciveDate(
													format(
														set(new Date(incomeRecivedDate), { hours: 0, minutes: 0, seconds: 0 }),
														"yyyy-MM-dd'T'HH:mm:ss",
													),
													format(
														set(new Date(startDateUpdated), { hours: 0, minutes: 0, seconds: 0 }),
														"yyyy-MM-dd'T'HH:mm:ss",
													),
												),
											),
									  )
									: 'No Recibido'}
							</TableCell>
							{/* BENEFICIO PARA TIR */}
							<TableCell
								className={classes.table}
								align='right'
								style={{
									minWidth: 154,
								}}
							>
								{incomeRecivedDate ? benefitForIRR : 'No Recibido'}
							</TableCell>
							{/* BASE IMPONIBLE */}
							<TableCell
								className={classes.table}
								align='right'
								style={{
									minWidth: 205,
								}}
							>
								<Input
									className={classes.table}
									disableUnderline={true}
									id={_id}
									value={taxBase ? taxBase : ''}
									inputProps={{
										placeholder: incomeRecivedDate ? 'Base Imponible *' : 'Sin Fecha de Ingreso',
										onChange: handleChangeTaxBase,
										onBlur: (e) => {
											handleOnBlurTaxBase(e, {
												investmentProject,
												_id,
											})
										},
										style: { textAlign: 'right' },
										type: 'number',
										required: true,
										disabled: incomeRecivedDate ? false : true,
									}}
								/>
								{incomeRecivedDate ? '€' : ''}
							</TableCell>
							{/* FEE TASA */}
							<TableCell
								className={classes.table}
								align='right'
								style={{
									minWidth: 107,
								}}
							>
								<Input
									className={classes.table}
									disableUnderline={true}
									id={_id}
									value={feeRate ? feeRate : ''}
									inputProps={{
										placeholder: incomeRecivedDate ? 'Tarifa tasa (50%) *' : 'Sin Fecha de Ingreso',
										onChange: handleChangefeeRate,
										onBlur: (e) =>
											handleOnBlurfeeRate(e, {
												investmentProject,
												_id,
												investorTIR,
												incomeRecivedDate,
												feeRate,
												amount,
												benefitForIRR,
											}),
										style: { textAlign: 'right' },
										type: 'number',
										required: true,
										disabled: incomeRecivedDate ? false : true,
									}}
								/>
								{incomeRecivedDate ? '%' : ''}
							</TableCell>
							{/* FEE */}
							<TableCell
								className={classes.table}
								align='right'
								style={{
									minWidth: 95,
								}}
							>
								<Input
									className={classes.table}
									disableUnderline={true}
									id={_id}
									value={fee ? fee : ''}
									inputProps={{
										placeholder: incomeRecivedDate ? 'Fee *' : 'Sin Fecha de Ingreso',
										onChange: handleChangefee,
										onBlur: (e) => handleOnBlurfee(e, { investmentProject, _id }),
										style: { textAlign: 'right' },
										type: 'number',
										required: true,
										disabled: incomeRecivedDate ? false : true,
									}}
								/>
								{incomeRecivedDate ? '€' : ''}
							</TableCell>
						</TableRow>
					),
				)}
		</>
	)
}

export default InvestmentTableBody
