import { cardTitle } from 'assets/jss/material-dashboard-pro-react'
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle'

const styles = (theme) => ({
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
	},
	rootItem: {
		padding: '0 !important',
	},
	loader: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '10px',
	},
	label: {
		fontSize: '1.2em',
		color: '#3C4858',
	},
	option: { display: 'flex', alignItems: 'flex-start' },
	disabled: { pointerEvents: 'none', cursor: 'not-allowed' },
	...modalStyle(theme),
})

export default styles
