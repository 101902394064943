import { createRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format, isPast } from 'date-fns'
import SweetAlert from 'react-bootstrap-sweetalert'
import {
	Select as Selectable,
	FormControlLabel,
	Switch,
	makeStyles,
	FormControl,
	InputLabel,
	MenuItem,
	Typography,
	DialogContent,
	DialogActions,
} from '@material-ui/core'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import { DatePicker } from '@material-ui/pickers'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomInput from 'components/CustomInput/CustomInput'
import TransparencyList from './TransparencyList'
import TransparencyListDocuments from './TransparencyListDocuments'
import ConfirmUpdateFinishedDateModal from './ConfirmUpdateFinishedDateModal'
import { matchProjectStatus } from 'shared/matchData/matchData'
import { matchCurrencyToDecimal } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'
import { getAllUsersOwners } from 'local_redux/actions/ownerActions'
import styles from '../styles/projectModalStyles'

const useStyles = makeStyles(styles)

const InputsForms = ({
	data,
	setData,
	startDateUpdated,
	setStartDateUpdated,
	finishDateUpdated,
	setFinishDateUpdated,
	imagesValidation,
	imagesUrl,
	setImagesUrl,
	videosUrl,
	setVideosUrl,
}) => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const fileInput = createRef()
	const simplePdfFile = createRef()
	const deedOfPurchaseFile = createRef()
	const simpleNotePriorToPurchaseFile = createRef()
	const simpleNotePurchasedPropertyFile = createRef()
	const acquisitionExpensesFile = createRef()
	const billsOfExpensesFile = createRef()
	const deedOfSaleFile = createRef()
	const officialVideoFile = createRef()
	const blueprintEA = createRef()

	const [confirmUpdateFinishedDateModal, setConfirmUpdateFinishedDateModal] = useState(false)
	const [confirmUpdateFinishedDate, setConfirmUpdateFinishedDate] = useState('')
	const [sizeVideoError, setSizeVideoError] = useState('')
	const [viewOfficialVideoModal, setViewOfficialVideoModal] = useState(false)
	const [viewImageProjectModal, setViewImageProjectModal] = useState(false)

	const { successOwnerGetUsers, ownerGetUsersData } = useSelector((state) => state.ownersGetUsers)
	const { userInfo } = useSelector((state) => state.userLogin)

	useEffect(() => {
		dispatch(getAllUsersOwners())
	}, [dispatch])

	const selectImageHandler = () => {
		fileInput.current.click()
	}
	const handleImageChange = (e) => {
		e.preventDefault()

		let imageFiles = e.target.files[0]

		const imagesURL = {
			name: imageFiles.name,
			url: URL.createObjectURL(imageFiles),
		}
		setData({ ...data, coverImage: imageFiles })
		setImagesUrl(imagesURL)
	}
	const handleRemoveImage = () => {
		setData({ ...data, coverImage: '' })
		setImagesUrl('')
	}
	const selectFileHandler = (currentFile) => {
		currentFile.current.click()
	}
	const handleFileChange = (e) => {
		e.preventDefault()
		setData({ ...data, [e.target.name]: e.target.files[0] })
	}
	const handleFileRemove = (file) => {
		setData({ ...data, [file]: undefined })
	}
	const handleVideoFileChange = (e) => {
		e.preventDefault()
		let videoFile = e.target.files[0]

		const sizeTotalMb = Math.ceil(videoFile.size / 1024 / 1024)

		if (sizeTotalMb > 500) {
			return setSizeVideoError('El video no puede ser mayor a 500 Mb')
		}

		const videosURL = {
			name: videoFile.name,
			url: URL.createObjectURL(videoFile),
		}
		setData({ ...data, coverVideo: videoFile })
		setVideosUrl(videosURL)
	}
	const handleCloseConfirmUpdateFinishedDateModal = () => {
		setConfirmUpdateFinishedDateModal(false)
	}

	return (
		<>
			<GridContainer>
				<GridItem xs={12} md={6}>
					<CustomInput
						labelText='Inversión Total *'
						id='total-investment'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: matchCurrencyToDecimal(data.totalInvestment),
							onChange: (e) =>
								setData({
									...data,
									totalInvestment: e.target.value,
									projectCost: (Number(e.target.value) * (Number(data?.admissibleCoinvestment || 0) / 100)).toFixed(2),
								}),
							type: 'number',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12} md={6}>
					<CustomInput
						labelText='% Coinversión Admisible * Un valor entre 0 y 100'
						id='admissible-coinvest'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: data.admissibleCoinvestment,
							onChange: (e) =>
								setData({
									...data,
									admissibleCoinvestment: e.target.value,
									projectCost: (Number(matchCurrencyToDecimal(data.totalInvestment)) * (e.target.value / 100)).toFixed(
										2,
									),
								}),
							type: 'number',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12} md={6}>
					<CustomInput
						labelText='Importe Invertible *'
						id='invertible-cost'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: data.projectCost,
							disabled: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12} md={6}>
					<FormControl fullWidth>
						<InputLabel htmlFor='project-status'>Estado *</InputLabel>
						<Selectable
							MenuProps={{
								className: classes.selectMenu,
							}}
							classes={{
								select: classes.select,
							}}
							value={matchProjectStatus(data?.status || '')}
							onChange={(e) => setData({ ...data, status: e.target.value })}
							inputProps={{
								name: 'project-status',
								id: 'project-status',
							}}
						>
							<MenuItem
								disabled
								classes={{
									root: classes.selectMenuItem,
								}}
							>
								Estado
							</MenuItem>
							{[
								'Inicia pronto',
								'Obras en Curso',
								'En Venta',
								'Reservado',
								'Finalizado',
								'Liquidado',
								'No Publicado',
							].map((status, index) => (
								<MenuItem
									classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
									value={status}
									key={index}
								>
									{status}
								</MenuItem>
							))}
						</Selectable>
					</FormControl>
				</GridItem>
			</GridContainer>
			<GridContainer spacing={3} style={{ marginTop: 40 }}>
				<GridItem xs={12} md={6}>
					<div style={{ border: '3px solid #e1dcdc', padding: '10px', borderRadius: '6px' }}>
						<GridContainer>
							<GridItem xs={12}>
								<Typography variant='body1' className={classes.modalSubtitle}>
									Estimado en Dossier
								</Typography>
							</GridItem>
							<GridItem xs={12}>
								<DatePicker
									value={data.startDateDossier}
									onChange={(e) => setData({ ...data, startDateDossier: e })}
									label='Fecha de inicio Dossier *'
									className={classes.dateInput}
								/>
							</GridItem>
							<GridItem xs={12}>
								<DatePicker
									value={data.finishDateDossier}
									minDate={data.startDateDossier}
									minDateMessage={'Por favor revise este valor'}
									onChange={(e) => setData({ ...data, finishDateDossier: e })}
									label='Fecha de fin Dossier *'
									className={classes.dateInput}
								/>
							</GridItem>
							<GridItem xs={12}>
								<CustomInput
									labelText='Valor de Venta en Dossier *'
									id='project-sale-value-dossier'
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										value: matchCurrencyToDecimal(data.projectSaleValueDossier),
										onChange: (e) => {
											setData({ ...data, projectSaleValueDossier: e.target.value })
										},
										type: 'number',
										required: true,
									}}
								/>
							</GridItem>
						</GridContainer>
					</div>
				</GridItem>
				<GridItem xs={12} md={6}>
					<div style={{ border: '3px solid #e1dcdc', padding: '10px', borderRadius: '6px' }}>
						<GridContainer>
							<GridItem xs={12}>
								<Typography variant='body1' className={classes.modalSubtitle}>
									Nueva Estimación
								</Typography>
							</GridItem>
							<GridItem xs={12}>
								<DatePicker
									value={startDateUpdated}
									onChange={(e) => {
										setStartDateUpdated(e)
									}}
									label='Fecha de inicio Actualizada *'
									className={classes.dateInput}
								/>
							</GridItem>

							<GridItem xs={12}>
								<DatePicker
									value={finishDateUpdated}
									onChange={(e) => {
										if (
											e &&
											data.finishDateUpdated &&
											format(new Date(e), 'dd-MM-yyyy') !== format(new Date(data.finishDateUpdated), 'dd-MM-yyyy') &&
											isPast(new Date(data.startDateUpdated))
										) {
											setConfirmUpdateFinishedDate(e)
											setConfirmUpdateFinishedDateModal(true)
										} else {
											setFinishDateUpdated(e)
										}
									}}
									label='Fecha de fin Actualizada *'
									className={classes.dateInput}
								/>
							</GridItem>

							<GridItem xs={12}>
								<CustomInput
									labelText='Valor de Venta en Actualizado *'
									id='project-sale-value-updated'
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										value: matchCurrencyToDecimal(data.projectSaleValueUpdated),
										onChange: (e) => {
											setData({ ...data, projectSaleValueUpdated: e.target.value })
										},
										type: 'number',
										required: true,
									}}
								/>
							</GridItem>
						</GridContainer>
					</div>
				</GridItem>
			</GridContainer>
			<GridContainer spacing={3} style={{ marginTop: 40 }}>
				<GridItem xs={12}>
					<CustomInput
						labelText='Proyecto / Dirección *'
						id='project-address'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: data.projectAddress,
							onChange: (e) => {
								setData({ ...data, projectAddress: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12}>
					<CustomInput
						labelText={'Descripción *'}
						id='description'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: data.description,
							onChange: (e) => {
								setData({ ...data, description: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12} md={6}>
					<CustomInput
						labelText={'Ciudad '}
						id='city'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: data.city,
							onChange: (e) => {
								setData({ ...data, city: e.target.value })
							},
							type: 'text',
						}}
					/>
				</GridItem>
				<GridItem xs={12} md={6}>
					<CustomInput
						labelText='Superficie del Inmueble m² *'
						id='project-surface'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: data.projectSurface,
							onChange: (e) => {
								setData({ ...data, projectSurface: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
				{successOwnerGetUsers && (
					<GridItem xs={12} style={{ marginBottom: 20 }}>
						<GridContainer>
							<GridItem xs={12} md={6}>
								<FormControl fullWidth>
									<InputLabel
										htmlFor='owner'
										classes={{
											root: classes.lineHeightSelect,
										}}
									>
										Propietario
									</InputLabel>
									<Selectable
										MenuProps={{
											className: classes.selectMenu,
										}}
										className={classes.select}
										value={data.owner || ''}
										onChange={(e) => setData({ ...data, owner: e.target.value })}
										inputProps={{
											name: 'owner',
											id: 'owner',
										}}
									>
										<MenuItem
											disabled
											classes={{
												root: classes.selectMenuItem,
											}}
										>
											Seleccione un propietario para este inmueble
										</MenuItem>
										{ownerGetUsersData?.length > 0 &&
											ownerGetUsersData.map((ownerItem) => (
												<MenuItem
													classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
													value={ownerItem?._id}
													key={ownerItem?._id}
												>
													{`${ownerItem?.name} ${ownerItem?.lastName}`}
												</MenuItem>
											))}
									</Selectable>
								</FormControl>
							</GridItem>
						</GridContainer>
					</GridItem>
				)}
				<GridItem xs={12} md={2}>
					<FormControlLabel
						control={
							<Switch
								checked={data.invertible}
								onChange={(e) => {
									setData({ ...data, invertible: e.target.checked })
								}}
								value='Invertible'
								classes={{
									switchBase: classes.switchBase,
									checked: classes.switchChecked,
									thumb: classes.switchIcon,
									track: classes.switchBar,
								}}
							/>
						}
						classes={{
							label: classes.label,
						}}
						label='Invertible'
					/>
				</GridItem>
				<GridItem xs={12} md={2}>
					<FormControlLabel
						control={
							<Switch
								checked={data.published}
								onChange={(e) => {
									setData({
										...data,
										published: e.target.checked,
									})
								}}
								value={'Publicar'}
								classes={{
									switchBase: classes.switchBase,
									checked: classes.switchChecked,
									thumb: classes.switchIcon,
									track: classes.switchBar,
								}}
							/>
						}
						classes={{
							label: classes.label,
						}}
						label={'Publicar'}
					/>
				</GridItem>
				<GridItem xs={12}>
					<GridContainer>
						{!imagesValidation && (
							<div style={{ display: 'none' }} className='fileinput'>
								<input
									type='file'
									name='images'
									accept='.png, .jpg, .jpeg'
									capture='camera'
									id='project-camera'
									onChange={handleImageChange}
									ref={fileInput}
								/>
							</div>
						)}
					</GridContainer>
				</GridItem>
				<TransparencyList
					deedOfPurchaseFile={deedOfPurchaseFile}
					simpleNotePriorToPurchaseFile={simpleNotePriorToPurchaseFile}
					simpleNotePurchasedPropertyFile={simpleNotePurchasedPropertyFile}
					acquisitionExpensesFile={acquisitionExpensesFile}
					billsOfExpensesFile={billsOfExpensesFile}
					deedOfSaleFile={deedOfSaleFile}
					simplePdfFile={simplePdfFile}
					blueprintEA={blueprintEA}
					handleImageChange={handleImageChange}
					data={data}
					setData={setData}
					selectFileHandler={selectFileHandler}
					handleFileRemove={handleFileRemove}
					handleFileChange={handleFileChange}
				/>
				<TransparencyListDocuments
					officialVideoFile={officialVideoFile}
					data={data}
					setData={setData}
					sizeVideoError={sizeVideoError}
					setViewOfficialVideoModal={setViewOfficialVideoModal}
					simplePdfFile={simplePdfFile}
					fileInput={fileInput}
					imagesValidation={imagesValidation}
					selectImageHandler={selectImageHandler}
					setViewImageProjectModal={setViewImageProjectModal}
					handleRemoveImage={handleRemoveImage}
					selectFileHandler={selectFileHandler}
					handleFileRemove={handleFileRemove}
					handleFileChange={handleVideoFileChange}
				/>
			</GridContainer>
			{confirmUpdateFinishedDateModal && (
				<ConfirmUpdateFinishedDateModal
					confirmUpdateFinishedModal={confirmUpdateFinishedDateModal}
					handleCloseConfirmUpdateFinishedDateModal={handleCloseConfirmUpdateFinishedDateModal}
					data={data}
					setData={setData}
					confirmUpdateFinishedDate={confirmUpdateFinishedDate}
					setFinishDateUpdated={setFinishDateUpdated}
				/>
			)}
			{sizeVideoError !== '' && (
				<SweetAlert
					warning
					style={{ display: 'block' }}
					title={sizeVideoError}
					onConfirm={() => setSizeVideoError('')}
					confirmBtnText='Cerrar'
					confirmBtnStyle={{
						backgroundColor: '#ebe6e2',
						color: '#333333',
						padding: '10px',
						width: '15rem',
						borderRadius: '3px',
					}}
				></SweetAlert>
			)}
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modal + ' ' + classes.modalResponsive,
				}}
				onClose={() => setViewOfficialVideoModal(false)}
				open={viewOfficialVideoModal}
			>
				<DialogTitle>Video Oficial del Proyecto</DialogTitle>
				<DialogContent>
					<GridContainer>
						<GridItem xs={12}>
							<video
								controls
								style={{
									width: '100%',
									overflow: 'hidden',
									objectFit: 'cover',
									borderRadius: '3px',
									marginBottom: '5px',
								}}
							>
								{/* <source src={videosUrl?.url || `${axios.defaults.baseURL}/${data.coverVideo}`} type='video/mp4' />
								<source src={videosUrl?.url || `${axios.defaults.baseURL}/${data.coverVideo}`} type='video/mkv' /> */}
								<source src={videosUrl?.url || getUrlToOpenFile(data.coverVideo, userInfo)} type='video/mp4' />
								<source src={videosUrl?.url || getUrlToOpenFile(data.coverVideo, userInfo)} type='video/mkv' />
							</video>
						</GridItem>
					</GridContainer>
				</DialogContent>
				<DialogActions className={classes.modalFooter}>
					<GridContainer>
						<GridItem xs={12}>
							<Button onClick={() => setViewOfficialVideoModal(false)} block color='primary'>
								Cerrar
							</Button>
						</GridItem>
					</GridContainer>
				</DialogActions>
			</Dialog>

			<Dialog onClose={() => setViewImageProjectModal(false)} open={viewImageProjectModal}>
				<DialogTitle>Imagen del proyecto.</DialogTitle>
				<DialogContent>
					<img
						// src={imagesUrl?.url || `${axios.defaults.baseURL}/${data.coverImage}`}
						src={imagesUrl?.url || getUrlToOpenFile(data.coverImage, userInfo)}
						style={{
							width: '100%',
							maxHeight: '200px',
							overflow: 'hidden',
							objectFit: 'cover',
							borderRadius: '3px',
							marginBottom: '5px',
						}}
					/>
				</DialogContent>
				<DialogActions className={classes.modalFooter}>
					<GridContainer>
						<GridItem xs={12}>
							<Button onClick={() => setViewImageProjectModal(false)} block color='primary'>
								Cerrar
							</Button>
						</GridItem>
					</GridContainer>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default InputsForms
