import customDropdownStyle from 'assets/jss/material-dashboard-pro-react/components/customDropdownStyle'

const styles = (theme) => ({
  ...customDropdownStyle(theme),
  confirmBtnCssClass: {
    backgroundColor: '#ebe6e2',
    color: '#333333',
    padding: '10px',
    width: '5rem',
    borderRadius: '3px',
    '&:hover': {
      color: '#333333',
    },
  },
  marginCero: { margin: '0% auto' },
  marginTwo: { margin: '2% auto' },
  msjStyle: { height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center' },
  headerItem: {
    minWidth: '240px',
  },
  DropItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f3f1f1',
    },
    minWidth: '240px',
  },
  selectedStyles: {
    backgroundColor: '#f3f1f1',
  },
  Loader: {
    position: 'absolute',
    top: '50%',
    right: '50%',
  },
})

export default styles
