import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, InputLabel, makeStyles, MenuItem, Select as Selectable } from '@material-ui/core'
import { PersonAdd } from '@mui/icons-material'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomInput from 'components/CustomInput/CustomInput'
import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import CardBody from 'components/Card/CardBody'
import PreRegisterDetails from './components/PreRegisterDetails'
import roles from 'config/roles/roles'
import { verifyEmail } from 'shared/validators/inputValidators'
import { PRE_REGISTER_REGISTER_RESET } from 'local_redux/constants/preRegisterConstants'
import { registerPreRegister } from 'local_redux/actions/preRegisterActions'
import styles from './styles/preRegisterInvestorScreenStyles'

const useStyles = makeStyles(styles)

const PreRegisterInvestorScreen = () => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const initialformState = {
		name: '',
		lastName: '',
		phone: '',
		email: '',
		profileId: '',
	}

	const [formState, setFormState] = useState(initialformState)
	const [registerEmailState, setRegisterEmailState] = useState('')
	const [profileError, setProfileError] = useState('')

	const { userInfo } = useSelector((state) => state.userLogin)
	const { loadingPreRegisterRegister, errorPreRegisterRegister, preRegisterData } = useSelector(
		(state) => state.preRegisterRegister,
	)

	const confirmSuccess = () => {
		setFormState(initialformState)
		setRegisterEmailState('')
		dispatch({ type: PRE_REGISTER_REGISTER_RESET })
	}
	const registerPreRegisterHandler = (e) => {
		e.preventDefault()

		if (userInfo.role === roles.INVESTOR_ROLE && !formState.profileId) {
			return setProfileError('Por favor seleccione un perfil')
		}
		dispatch(registerPreRegister(formState))
	}

	return (
		<GridContainer>
			<GridItem xs={12} sm={12} md={preRegisterData ? 8 : 10} className={classes.root}>
				{preRegisterData ? (
					<PreRegisterDetails preRegisterData={preRegisterData} confirmSuccess={confirmSuccess} />
				) : (
					<Card>
						<CardHeader color='primary' icon>
							<CardIcon color='primary'>
								<PersonAdd />
							</CardIcon>
							<h4 style={{ color: 'black' }}>Pre-Registro de Inversores</h4>
						</CardHeader>
						<CardBody>
							<form onSubmit={registerPreRegisterHandler}>
								<GridContainer>
									{userInfo.role === roles.INVESTOR_ROLE && (
										<GridItem xs={12}>
											<FormControl fullWidth>
												<InputLabel htmlFor='profile'>Seleccione un perfil prescriptor *</InputLabel>
												<Selectable
													MenuProps={{
														className: classes.selectMenu,
													}}
													classes={{
														select: classes.select,
													}}
													value={formState.profileId}
													onChange={(e) => {
														setFormState({ ...formState, profileId: e.target.value })
													}}
													inputProps={{
														name: 'prescriber-profile',
														id: 'prescriber-profile',
													}}
												>
													<MenuItem
														disabled
														classes={{
															root: classes.selectMenuItem,
														}}
													>
														Seleccione uno
													</MenuItem>
													{userInfo.prescribersProfiles.map((investorProfile) => (
														<MenuItem
															classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
															value={investorProfile._id}
															key={investorProfile._id}
														>
															{investorProfile.businessName}
														</MenuItem>
													))}
												</Selectable>
											</FormControl>
										</GridItem>
									)}
									<GridItem
										xs={12}
										lg={6}
										style={{ marginTop: `${userInfo.role === roles.INVESTOR_ROLE ? '5px' : '0'}` }}
									>
										<CustomInput
											labelText='Nombre *'
											id='name'
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												value: formState.name,
												onChange: (e) => {
													setFormState({ ...formState, name: e.target.value })
												},
												type: 'text',
												required: true,
											}}
										/>
									</GridItem>
									<GridItem xs={12} lg={6}>
										<CustomInput
											labelText='Apellidos *'
											id='lastname'
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												value: formState.lastName,
												onChange: (e) => {
													setFormState({ ...formState, lastName: e.target.value })
												},
												type: 'text',
												required: true,
											}}
										/>
									</GridItem>
									<GridItem xs={12} lg={6}>
										<CustomInput
											labelText='Correo Electronico'
											error={registerEmailState === 'error'}
											id='email'
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												value: formState.email,
												onChange: (e) => {
													if (!e.target.value) {
														setRegisterEmailState('')
													} else if (verifyEmail(e.target.value)) {
														setRegisterEmailState('')
													} else {
														setRegisterEmailState('error')
													}
													setFormState({ ...formState, email: e.target.value })
												},
												type: 'email',
											}}
										/>
									</GridItem>
									<GridItem xs={12} lg={6}>
										<CustomInput
											labelText='Teléfono'
											id='phone'
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												value: formState.phone,
												onChange: (e) => {
													setFormState({ ...formState, phone: e.target.value })
												},
												type: 'text',
											}}
										/>
									</GridItem>
									{errorPreRegisterRegister && (
										<GridItem xs={12}>
											<SnackbarContent message={errorPreRegisterRegister} color='danger' />
										</GridItem>
									)}
									{profileError && !formState.profileId && (
										<GridItem xs={12}>
											<SnackbarContent message={profileError} color='danger' />
										</GridItem>
									)}
								</GridContainer>
								<Button type='submit' color='primary' className={classes.registerButton} block>
									{loadingPreRegisterRegister ? 'Pre - Registrando Inversor...' : 'Pre - Registrar Inversor'}
								</Button>
							</form>
						</CardBody>
					</Card>
				)}
			</GridItem>
		</GridContainer>
	)
}

export default PreRegisterInvestorScreen
