import { format, differenceInCalendarMonths, getDaysInMonth, differenceInDays } from 'date-fns'
import { matchNumberToCurrency } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import { replaceStringToNum } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import xirr from 'xirr'

export const calculateTotalDuration = (finishDate, startDate) => {
	const initDate = new Date(format(startDate, 'yyyy-MM-dd 00:00:00'))
	const finDate = new Date(format(finishDate, 'yyyy-MM-dd 00:00:00'))

	const difference = differenceInCalendarMonths(finDate, initDate)
	if (difference >= 0 && difference < 1) {
		const daysInMonth = getDaysInMonth(startDate)
		const differenceOfDays = differenceInDays(finDate, initDate)
		const datePercentage = differenceOfDays / daysInMonth

		return datePercentage.toFixed(2)
	}

	return difference
}

export const calculateToTIR = (incomeRecivedDate, depositDate, benefit, investment) => {
	const benefitCalc = typeof benefit === 'number' ? benefit : replaceStringToNum(benefit)
	const TIR = xirr([
		{ amount: -replaceStringToNum(investment), when: new Date(incomeRecivedDate) },
		{ amount: benefitCalc, when: new Date(depositDate) },
	])

	return benefit ? Number((TIR * 100).toFixed(2)) : null
}

export const addNewValueLiquidation = (event, id, newInfoInvestment, index) => {
	let newValue = [...newInfoInvestment]

	newValue[index] = {
		...newValue[index],
		liquidation: event.target.value,
	}
	return newValue
}

export const calculateRetentionAmount = (retention, benefit) => {
	const retentionNumber = retention.replace('%', '') / 100

	const retentionFromBenefit = !isNaN(benefit)
		? benefit * retentionNumber
		: replaceStringToNum(benefit) * retentionNumber

	return matchNumberToCurrency(retentionFromBenefit)
}
