import { Checkbox } from '@material-ui/core'
import { FormControl, MenuItem, Select, TableBody, TableCell, TableRow, Tooltip } from '@mui/material/'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import { ibanValidator } from 'shared/helpers/functions-helpers'

const TablePdlToLiquidateBody = ({ classes, pdls = [], projectAddress, handleChangeLiquidation }) => {
	return (
		<TableBody>
			{pdls?.map((item, i) => {
				return (
					<TableRow
						className={classes.tableRow}
						key={i}
						sx={{
							'&:last-child td, &:last-child th': { border: 0 },
						}}
					>
						<TableCell className={classes.table} component='th' scope='row'>
							<Tooltip title='Incluir este pago en el CSV para la remesa'>
								<Checkbox
									checked={item?.liquidationFields?.csvProfileInclude}
									id={item._id}
									name='csvProfileInclude'
									onChange={(e) =>
										handleChangeLiquidation({ target: { value: e.target.checked, name: 'csvProfileInclude' } }, item)
									}
									label=''
									size='small'
									color='default'
								/>
							</Tooltip>
						</TableCell>
						<TableCell className={classes.table} component='th' scope='row'>
							{item?.name}
						</TableCell>
						<TableCell
							className={classes.table}
							component='th'
							scope='row'
							style={{
								color:
									(item?.liquidationFields?.liquidation === 'pay' ||
										item?.liquidationFields?.liquidation === 'benefits-only') &&
									ibanValidator(item?.profileInvestor?.bankAccountNumber)
										? 'red'
										: 'green',
							}}
						>
							{item?.profileInvestor?.bankAccountNumber}
						</TableCell>
						<TableCell className={classes.table} component='th' scope='row'>
							{projectAddress}
						</TableCell>
						<TableCell className={classes.table} component='th' scope='row'>
							{!!item?.amountPdl
								? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item?.amountPdl)
								: '0,00 €'}
						</TableCell>
						<TableCell className={classes.table} align='right'>
							<FormControl variant='standard' sx={{ m: 1, minWidth: 120 }}>
								<Select
									className={classes.table}
									value={item?.liquidationFields?.liquidation || 'pay'}
									name='liquidation'
									onChange={(e) => handleChangeLiquidation(e, item)}
								>
									<MenuItem value={'pay'}>Pagar</MenuItem>
									<MenuItem value={'reinvest'}>Reinvertir</MenuItem>
									<MenuItem value={'benefits-only'}>Pagar solo beneficios</MenuItem>
								</Select>
							</FormControl>
						</TableCell>
						<TableCell className={classes.table} component='th' scope='row'>
							{!!item.incomeRecivedDate
								? format(new Date(item?.incomeRecivedDate), 'dd/MM/yyyy', { locale: es })
								: 'Sin confirmar'}
						</TableCell>
						<TableCell className={classes.table} component='th' scope='row'>
							{format(new Date(item?.liquidityDate), 'dd/MM/yyyy', { locale: es })}
						</TableCell>
						<TableCell className={classes.table} component='th' scope='row'>
							{item?.typeOfInterest || 'No disponible'}
						</TableCell>
						<TableCell className={classes.table} component='th' scope='row'>
							{!!item?.fee
								? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item?.fee)
								: '0,00 €'}
						</TableCell>
						<TableCell className={classes.table} component='th' scope='row'>
							{item?.profileInvestor?.retention}
						</TableCell>
						<TableCell className={classes.table} component='th' scope='row'>
							{!!item?.retentionFee
								? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item?.retentionFee)
								: '0,00 €'}
						</TableCell>
						<TableCell className={classes.table} component='th' scope='row'>
							{!!item?.liquidationFields?.toPay
								? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
										item?.liquidationFields?.toPay,
								  )
								: '0,00 €'}
						</TableCell>
					</TableRow>
				)
			})}
		</TableBody>
	)
}

export default TablePdlToLiquidateBody
