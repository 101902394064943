import { Check, Remove } from '@material-ui/icons'
import { Tooltip } from '@mui/material'
import { Visibility, Edit } from '@mui/icons-material'
import Tippy from '@tippyjs/react'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

export const prescriberListColumns = ({ classes, handleOver, profiles, setOpenSignContractModal, navigate }) => {
	return [
		{
			header: 'Prescriptor',
			accessor: 'businessName',
			size: 'md',
			cell: ({ row }) => {
				return (
					<div onMouseOver={(e) => handleOver(e, row)} style={{ cursor: 'pointer' }}>
						<Tippy
							popperOptions={{ strategy: 'fixed' }}
							className={classes.tippy}
							placement='left'
							zIndex={10000}
							content={
								<GridContainer>
									<GridItem xs={12}>
										<p style={{ fontSize: '12px', textAlign: 'center' }}>Perfiles Prescritos</p>
									</GridItem>
									{profiles &&
										profiles.map((profile) => (
											<GridItem xs={12}>
												<p style={{ fontSize: '12px' }}>- {profile.businessName}</p>
											</GridItem>
										))}
								</GridContainer>
							}
						>
							<span>{row.businessName}</span>
						</Tippy>
					</div>
				)
			},
		},
		{
			header: 'Persona',
			accessor: 'personType',
		},
		{
			header: 'Contrato firmado',
			accessor: 'signedContract',
			cell: ({ row }) => {
				return row.signedContract ? (
					<Tooltip title='Contrato Firmado' placement='bottom'>
						<Check color='success' fontSize='small' />
					</Tooltip>
				) : (
					<Tooltip title='Contrato No Firmado' placement='bottom'>
						<Remove color='disabled' fontSize='small' />
					</Tooltip>
				)
			},
		},
		{
			header: 'Referidos',
			accessor: 'prescriberProfiles',
			size: 'sm',
			cell: ({ row }) => {
				return (
					<div
						onClick={() => window.open(`/admin/investor-prescriber-list?profileId=${row?.id}`)}
						style={{ cursor: 'pointer', color: '#4646c3' }}
					>
						{row?.prescriberProfiles}
					</div>
				)
			},
		},
		{
			header: 'Acciones',
			accessor: 'actions',
			size: 'sm',
			cellAlign: 'right',
			headerAlign: 'right',
			cell: ({ row }) => (
				<div className='actions-right'>
					{row.prescriptorContractUrl && (
						<Tooltip title='Firmar Contrato' placement='bottom'>
							<Button
								justIcon
								round
								simple
								onClick={() => setOpenSignContractModal(true)}
								color='success'
								className='edit'
							>
								<Edit />
							</Button>
						</Tooltip>
					)}
					<Tooltip title='Ver Inversiones' placement='bottom'>
						<Button
							justIcon
							round
							simple
							onClick={() => navigate(`/admin/investor-prescriber-list?referalId=${row.id}`)}
							color='success'
							className='edit'
						>
							<Visibility />
						</Button>
					</Tooltip>
				</div>
			),
		},
	]
}
