import { useSelector } from 'react-redux'
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import { Close } from '@mui/icons-material'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'
import styles from 'assets/jss/material-dashboard-pro-react/modalStyle'

const useStyles = makeStyles(styles)

const OficialProjectVideo = ({ openVideoOficial, setOpenVideoOficial, dataVideo }) => {
	const classes = useStyles()

	const { userInfo } = useSelector((state) => state.userLogin)

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={openVideoOficial}
			keepMounted
			onClose={() => setOpenVideoOficial(false)}
		>
			<DialogTitle disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={() => setOpenVideoOficial(false)}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4>
					Video Oficial del Proyecto <strong>{dataVideo.projectAddress}</strong>
				</h4>
			</DialogTitle>
			<DialogContent className={classes.modalBody}>
				<GridContainer>
					<GridItem xs={12}>
						<video width='100%' controls>
							{/* <source src={`${axios.defaults.baseURL}/${dataVideo.coverVideo}`} type='video/mp4' /> */}
							<source src={getUrlToOpenFile(dataVideo.coverVideo, userInfo)} type='video/mp4' />
							Your browser does not support the video tag.
						</video>
					</GridItem>
				</GridContainer>
			</DialogContent>
			<DialogActions className={classes.modalFooter}>
				<GridContainer>
					<GridItem xs={12} style={{ marginTop: '50px' }}>
						<Button color='primary' onClick={() => setOpenVideoOficial(false)}>
							Salir
						</Button>
					</GridItem>
				</GridContainer>
			</DialogActions>
		</Dialog>
	)
}

export default OficialProjectVideo
