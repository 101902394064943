import { useEffect, createRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
	Badge,
	FormControl,
	Select as Selectable,
	InputLabel,
	makeStyles,
	MenuItem,
	FormControlLabel,
	Switch,
} from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { AddPhotoAlternate, Close } from '@material-ui/icons'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import GridItem from 'components/Grid/GridItem'
import CustomInput from 'components/CustomInput/CustomInput'
import GridContainer from 'components/Grid/GridContainer'
import Button from 'components/CustomButtons/Button'
import { getAllUsersOwners } from 'local_redux/actions/ownerActions'
import styles from '../styles/projectInputsStyles'

const useStyles = makeStyles(styles)

const PojectInputs = ({ projectHandler }) => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const fileInput = createRef()
	const simplePdfFile = createRef()

	const { projectState, setProjectState } = projectHandler

	const { successOwnerGetUsers, ownerGetUsersData } = useSelector((state) => state.ownersGetUsers)

	useEffect(() => {
		dispatch(getAllUsersOwners())
	}, [dispatch])

	const selectImageHandler = () => {
		fileInput.current.click()
	}
	const handleImageChange = (e) => {
		e.preventDefault()

		let imageFile = e.target.files

		const imageURL = {
			name: imageFile[0].name,
			url: URL.createObjectURL(imageFile[0]),
		}

		setProjectState({ ...projectState, coverImage: imageFile[0], imageURL })
	}
	const handleRemoveImage = () => {
		setProjectState({ ...projectState, coverImage: '', imageURL: '' })
	}
	const selectFileHandler = () => {
		simplePdfFile.current.click()
	}
	const handleFileChange = (e) => {
		e.preventDefault()
		setProjectState({ ...projectState, simplePdf: e.target.files[0] })
	}
	const handleFileRemove = () => {
		setProjectState({ ...projectState, simplePdf: '' })
	}

	return (
		<GridContainer spacing={3}>
			<GridItem xs={12} sm={6}>
				<CustomInput
					labelText={'Importe Invertible *'}
					id='project-cost'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: projectState.projectCost,
						onChange: (e) => {
							setProjectState({ ...projectState, projectCost: e.target.value })
						},
						type: 'number',
						required: true,
					}}
				/>
			</GridItem>
			<GridItem xs={12} sm={6}>
				<FormControl fullWidth>
					<InputLabel
						htmlFor='project-status'
						classes={{
							root: classes.lineHeightSelect,
						}}
					>
						Estado
					</InputLabel>
					<Selectable
						MenuProps={{
							className: classes.selectMenu,
						}}
						className={classes.select}
						value={projectState.status}
						onChange={(e) => setProjectState({ ...projectState, status: e.target.value })}
						inputProps={{
							name: 'project-status',
							id: 'project-status',
						}}
					>
						<MenuItem
							disabled
							classes={{
								root: classes.selectMenuItem,
							}}
						>
							Estado
						</MenuItem>
						{['Inicia pronto', 'Obras en Curso', 'En Venta', 'Reservado', 'Finalizado'].map((status, index) => (
							<MenuItem
								classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
								value={status}
								key={index}
							>
								{status}
							</MenuItem>
						))}
					</Selectable>
				</FormControl>
			</GridItem>
			<GridItem xs={12} style={{ marginTop: '20px' }}>
				<div style={{ border: '3px solid #e1dcdc', padding: '14px 25px 35px', borderRadius: '6px', margin: '22px 0' }}>
					<GridContainer>
						<GridItem xs={12}>
							<h4>Dossier</h4>
						</GridItem>
						<GridItem xs={12} sm={6}>
							<DatePicker
								value={projectState.startDateDossier}
								onChange={(e) => {
									setProjectState({ ...projectState, startDateDossier: e })
								}}
								label='Fecha de inicio en Dossier *'
								className={classes.dateInput}
							/>
						</GridItem>
						<GridItem xs={12} sm={6}>
							<DatePicker
								minDate={projectState.startDateDossier}
								value={projectState.finishDateDossier}
								onChange={(e) => setProjectState({ ...projectState, finishDateDossier: e })}
								label='Fecha fin en Dossier *'
								className={classes.dateInput}
							/>
						</GridItem>
						<GridItem xs={12} sm={6}>
							<CustomInput
								labelText='Valor de Venta en Dossier *'
								id='project-sale-value-dossier'
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									value: projectState.projectSaleValueDossier,
									onChange: (e) => {
										setProjectState({ ...projectState, projectSaleValueDossier: e.target.value })
									},
									type: 'number',
									required: true,
								}}
							/>
						</GridItem>
					</GridContainer>
				</div>
			</GridItem>
			<GridItem xs={12} md={6}>
				<CustomInput
					labelText='Proyecto / Dirección *'
					id='project-address'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: projectState.projectAddress,
						onChange: (e) => {
							setProjectState({ ...projectState, projectAddress: e.target.value })
						},
						type: 'text',
						required: true,
					}}
				/>
			</GridItem>
			<GridItem xs={12} md={6}>
				<CustomInput
					labelText={'Descripción *'}
					id='description'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: projectState.description,
						onChange: (e) => {
							setProjectState({ ...projectState, description: e.target.value })
						},
						type: 'text',
						required: true,
					}}
				/>
			</GridItem>
			<GridItem xs={12} md={6}>
				<CustomInput
					labelText={'Ciudad '}
					id='city'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: projectState.city,
						onChange: (e) => {
							setProjectState({ ...projectState, city: e.target.value })
						},
						type: 'text',
					}}
				/>
			</GridItem>
			<GridItem xs={12} md={6}>
				<CustomInput
					labelText='Superficie del Inmueble m² *'
					id='project-surface'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: projectState.projectSurface,
						onChange: (e) => {
							setProjectState({ ...projectState, projectSurface: e.target.value })
						},
						type: 'number',
						required: true,
					}}
				/>
			</GridItem>
			{successOwnerGetUsers && (
				<GridItem xs={6} sm={6}>
					<FormControl fullWidth>
						<InputLabel
							htmlFor='owner'
							classes={{
								root: classes.lineHeightSelect,
							}}
						>
							Propietario
						</InputLabel>
						<Selectable
							MenuProps={{
								className: classes.selectMenu,
							}}
							className={classes.select}
							value={projectState.owner}
							onChange={(e) => setProjectState({ ...projectState, owner: e.target.value })}
							inputProps={{
								name: 'owner',
								id: 'owner',
							}}
						>
							<MenuItem
								disabled
								classes={{
									root: classes.selectMenuItem,
								}}
							>
								Seleccione un propietario para este inmueble
							</MenuItem>
							{ownerGetUsersData?.length > 0 &&
								ownerGetUsersData.map((ownerItem) => (
									<MenuItem
										classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
										value={ownerItem?._id}
										key={ownerItem?._id}
									>
										{`${ownerItem?.name} ${ownerItem?.lastName}`}
									</MenuItem>
								))}
						</Selectable>
					</FormControl>
				</GridItem>
			)}
			<GridItem xs={12} style={{ marginTop: 30 }}>
				<GridContainer>
					{projectState.simplePdf && (
						<GridItem xs={12}>
							<Badge
								badgeContent={
									<Button size='sm' color='danger' round justIcon onClick={() => handleFileRemove()}>
										<Close />
									</Button>
								}
								className={classes.fileBadge}
							>
								<div className={classes.fileDiv}>
									{projectState.simplePdf.name.length > 20
										? projectState.simplePdf.name.slice(1, 10) +
										  '...' +
										  projectState.simplePdf.name.slice(
												projectState.simplePdf.name.length - 6,
												projectState.simplePdf.name.length,
										  )
										: projectState.simplePdf.name}
								</div>
							</Badge>
						</GridItem>
					)}
					{projectState.imageURL?.url && (
						<GridItem xs={12}>
							<Badge
								badgeContent={
									<Button size='sm' color='danger' round justIcon onClick={() => handleRemoveImage()}>
										<Close />
									</Button>
								}
							>
								<img
									src={projectState.imageURL?.url}
									style={{
										width: '100%',
										maxHeight: '200px',
										overflow: 'hidden',
										objectFit: 'cover',
										borderRadius: '3px',
										marginBottom: '5px',
									}}
								/>
							</Badge>
						</GridItem>
					)}
				</GridContainer>
			</GridItem>
			<GridItem xs={12} md={3}>
				<FormControlLabel
					control={
						<Switch
							checked={projectState.published}
							onChange={() => {
								setProjectState({ ...projectState, published: !projectState.published })
							}}
							value={'Publicar'}
						/>
					}
					classes={{
						label: classes.label,
					}}
					label={'Publicar'}
				/>
			</GridItem>

			{!projectState.imageURL?.url && (
				<GridItem xs={12} className={classes.addPhotoRoot}>
					<div className='fileinput'>
						<input
							type='file'
							name='images'
							accept='.png, .jpg, .jpeg'
							capture='camera'
							id='project-camera'
							onChange={handleImageChange}
							ref={fileInput}
						/>
					</div>

					<Button color='primary' size='lg' justIcon round onClick={() => selectImageHandler()}>
						<AddPhotoAlternate />
					</Button>
				</GridItem>
			)}
			{!projectState.simplePdf && (
				<GridItem xs={12} className={classes.addPhotoRoot}>
					<div className='fileinput'>
						<input
							type='file'
							name='pdf'
							accept='.pdf,'
							id='simple-pdf'
							ref={simplePdfFile}
							onChange={handleFileChange}
						/>
					</div>
					<Button color='primary' size='lg' justIcon round onClick={() => selectFileHandler()}>
						<PictureAsPdfIcon />
					</Button>
				</GridItem>
			)}
		</GridContainer>
	)
}

export default PojectInputs
