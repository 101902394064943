import { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Card, CardActions, Typography } from '@material-ui/core'
import CustomInput from 'components/CustomInput/CustomInput'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { registerEraserHeadProject } from 'local_redux/actions/eraserHeadActions'
import { LiquidatedProjectContext } from 'contexts/liquidatedProjectContext'

const ProjectInformationTir = ({ tir, setTir, setTirCoinvestor, tirCoinvestor }) => {
	const dispatch = useDispatch()

	const { selectedProject } = useContext(LiquidatedProjectContext)

	useEffect(() => {
		if (selectedProject.eraserhead?.length > 0) {
			setTir(selectedProject.eraserhead[0]?.projectTIR || 0)
			setTirCoinvestor(selectedProject.eraserhead[0]?.coInvestorTIR || 0)
		}
	}, [])

	const handleOnChange = (e) => {
		const TIRCoinvestor = e.target.value !== '' ? (0.12 + (e.target.value / 100 - 0.12) / 2) * 100 : 0
		setTir(e.target.value)
		dispatch(
			registerEraserHeadProject({
				projectId: selectedProject._id,
				projectTIR: e.target.value !== '' ? e.target.value : 0,
				coInvestorTIR: TIRCoinvestor < 12 ? e.target.value : TIRCoinvestor,
			}),
		)
	}

	return (
		<GridItem xs={6}>
			<Card style={{ padding: '15px' }}>
				<CardActions disableSpacing>
					<GridContainer>
						<GridItem xs={12}>
							<Typography variant='h6'>Información del TIR</Typography>
						</GridItem>
						<GridItem xs={12} md={6}>
							<CustomInput
								labelText={'TIR Proyecto % *'}
								id='tirproject'
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									value: tir,
									onChange: (e) => handleOnChange(e),
									type: 'number',
									required: true,
								}}
							/>
						</GridItem>
						<GridItem xs={12} md={6}>
							<CustomInput
								labelText='TIR a pagar a coinversores'
								id='tircoinversores'
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									value: `${Number(tirCoinvestor).toFixed(2)}%`,
									type: 'text',
									required: true,
									disabled: true,
								}}
							/>
						</GridItem>
					</GridContainer>
				</CardActions>
			</Card>
		</GridItem>
	)
}

export default ProjectInformationTir
