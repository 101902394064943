import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch'

const styles = {
	...customCheckboxRadioSwitch,
	tableRow: {
		'& th:nth-child(2)': {
			zIndex: '10',
			position: 'sticky !important',
			width: '5em',
			left: '0',
			top: 'auto',
			borderTopWidth: '1px',
			backgroundColor: '#fff',
		},
	},
	table: {
		fontSize: '0.7rem !important',
		paddingLeft: 0,
		whiteSpace: 'nowrap',
	},
	ChangeValue: {
		display: 'block',
		position: 'relative',
		top: -3,
		height: '0px',
	},
	ChangeValueFont: {
		fontSize: '0.6rem',
		color: 'blue',
		cursor: 'pointer',
	},
}

export default styles
