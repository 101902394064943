import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import { Notifications } from '@mui/icons-material'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import CustomWarningMessage from 'components/CustomWarningMessage/CustomWarningMessage'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Loader from 'components/Loader/Loader'
import ReactTable from 'components/ReactTable/ReactTable'
import { getNotificationByUser } from 'local_redux/actions/notificationActions'
import { NOTIFICATION_BY_USER_RESET } from 'local_redux/constants/notificationConstants'

const NotificationInvestorScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [data, setData] = useState([])

  const { loadingNotificationByUser, successNotificationByUser, notificationByUserData } = useSelector(
    (state) => state.notificationByUser
  )

  const { userInfo } = useSelector((state) => state.userLogin)

  useEffect(() => {
    if (!userInfo) {
      navigate('/')
    }
  }, [userInfo])

  useEffect(() => {
    if (userInfo && !successNotificationByUser) {
      dispatch(getNotificationByUser(userInfo._id))
    }
  }, [userInfo, successNotificationByUser])

  useEffect(() => {
    if (successNotificationByUser) {
      const notifications = notificationByUserData.map((item) => {
        return {
          id: item._id,
          title: item.title,
          date: format(new Date(item.created), 'dd-MM-yyyy'),
          message: item.message,
        }
      })
      setData(notifications)
    }
  }, [successNotificationByUser])

  useEffect(() => {
    return () => dispatch({ type: NOTIFICATION_BY_USER_RESET })
  }, [dispatch])

  return (
    <GridContainer>
      <GridItem xs={12}>
        {loadingNotificationByUser ? (
          <Loader />
        ) : data.length < 1 ? (
          <CustomWarningMessage text='No se encontraron notificaciones' />
        ) : (
          <Card>
            <CardHeader color='primary' icon>
              <CardIcon color='primary'>
                <Notifications />
              </CardIcon>
              <h4>Notificaciones</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                columns={[
                  {
                    Header: 'Fecha',
                    accessor: 'date',
                    size: 'big',
                  },
                  {
                    Header: 'Inmueble',
                    accessor: 'title',
                    size: 'big',
                  },
                  {
                    Header: 'Notificacion',
                    accessor: 'message',
                    size: 'big',
                  },
                  {
                    Header: 'Actions',
                    accessor: 'actions',
                    size: 'small',
                  },
                ]}
                data={data}
              />
            </CardBody>
          </Card>
        )}
      </GridItem>
    </GridContainer>
  )
}

export default NotificationInvestorScreen
