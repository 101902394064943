const styles = {
	title: {
		fontSize: 12,
		color: '#333333',
		'& svg': {
			fontSize: 12,
		},
	},
	textContainer: {
		justifyContent: 'space-between',
		marginTop: '5px',
	},
	startDateTitle: {
		fontSize: '10px',
	},
	endDateTitle: {
		fontSize: '10px',
		textAlign: 'end',
		marginRight: '2px',
	},
	endText: {
		textAlign: 'right !important',
		marginRight: '2px',
	},
	smallText: {
		fontSize: '10px',
	},
	textNoWrap: {
		whiteSpace: 'nowrap',
	},
	investmentContainer: { paddingLeft: '15px' },
	totalContainer: { paddingRight: '15px' },
}

export default styles
