import { useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import { makeStyles } from '@material-ui/core'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import { InsertDriveFile } from '@material-ui/icons'
import { Delete, Visibility } from '@material-ui/icons'
import TransparencyRefs from './TransparencyRefs'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'
import styles from '../styles/transparencyListStyles'

const useStyles = makeStyles(styles)

const Demo = styled('div')(() => ({
	backgroundColor: '#D9E0E0',
	marginBottom: '10px',
	marginTop: '20px',
	borderRadius: '10px',
}))

const TransparencyListDocuments = ({
	data,
	selectFileHandler,
	setViewOfficialVideoModal,
	handleFileChange,
	handleFileRemove,
	officialVideoFile,
	simplePdfFile,
	imagesValidation,
	handleRemoveImage,
	selectImageHandler,
	setViewImageProjectModal,
}) => {
	const classes = useStyles()

	const { userInfo } = useSelector((state) => state.userLogin)

	return (
		<Box sx={{ flexGrow: 1, margin: '0 auto' }}>
			<Grid container justifyContent='center' spacing={2}>
				<Grid item xs={11}>
					<Demo className={classes.cardRoot}>
						<p className={classes.cardTitle}>Documentos</p>
						<List dense={true}>
							<ListItem
								className={classes.listItem}
								secondaryAction={
									<>
										{!data?.coverVideo ? (
											<IconButton aria-label='add' size='small' onClick={() => selectFileHandler(officialVideoFile)}>
												<InsertDriveFile fontSize='inherit' />
											</IconButton>
										) : (
											<>
												<IconButton
													aria-label='visibility'
													size='small'
													onClick={() => setViewOfficialVideoModal(true)}
												>
													<Visibility fontSize='inherit' />
												</IconButton>
											</>
										)}
										{data?.coverVideo && (
											<IconButton
												aria-label='delete'
												size='small'
												onClick={() => {
													handleFileRemove('coverVideo')
												}}
											>
												<Delete fontSize='inherit' color={'action'} />
											</IconButton>
										)}
									</>
								}
							>
								<ListItemText
									className={classes.listItemText}
									primary='Video oficial del proyecto'
									secondary={`(500 Mb Max.)`}
								/>
							</ListItem>
							<Divider variant='fullWidth' component='li' />

							<ListItem
								className={classes.listItem}
								secondaryAction={
									<>
										{!data?.projectPdf ? (
											<IconButton aria-label='add' size='small' onClick={() => selectFileHandler(simplePdfFile)}>
												<InsertDriveFile fontSize='inherit' />
											</IconButton>
										) : (
											<>
												<IconButton
													aria-label='visibility'
													size='small'
													onClick={() => {
														window.open(
															// `${axios.defaults.baseURL}/${data.projectPdf}?code=${generateTokenCode(userInfo.token)}`
															getUrlToOpenFile(data.projectPdf, userInfo),
														)
													}}
												>
													<Visibility fontSize='inherit' />
												</IconButton>
											</>
										)}
										{data?.projectPdf && (
											<IconButton
												aria-label='delete'
												size='small'
												onClick={() => {
													handleFileRemove('projectPdf')
												}}
											>
												<Delete fontSize='inherit' color={'action'} />
											</IconButton>
										)}
									</>
								}
							>
								<ListItemText className={classes.listItemText} primary='Dossier del proyecto' />
							</ListItem>
							<Divider variant='fullWidth' component='li' />

							<ListItem
								className={classes.listItem}
								secondaryAction={
									<>
										{!imagesValidation ? (
											<IconButton aria-label='add' size='small' onClick={() => selectImageHandler()}>
												<InsertDriveFile fontSize='inherit' />
											</IconButton>
										) : (
											<>
												<IconButton aria-label='visibility' size='small' onClick={() => setViewImageProjectModal(true)}>
													<Visibility fontSize='inherit' />
												</IconButton>
											</>
										)}
										{imagesValidation && (
											<IconButton aria-label='delete' size='small' onClick={handleRemoveImage}>
												<Delete fontSize='inherit' color={'action'} />
											</IconButton>
										)}
									</>
								}
							>
								<ListItemText className={classes.listItemText} primary='Imagen del proyecto' />
							</ListItem>
							<Divider variant='fullWidth' component='li' />
						</List>
					</Demo>
				</Grid>
			</Grid>
			<TransparencyRefs officialVideoFile={officialVideoFile} handleFileChange={handleFileChange} />
		</Box>
	)
}
export default TransparencyListDocuments
