import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { makeStyles, Tooltip, Typography } from '@material-ui/core'
import { VideoCameraBack } from '@mui/icons-material'
import Grid from '@mui/material/Grid'
import { Videocam } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import GridItem from 'components/Grid/GridItem'
import VideoEvolutionProjectModal from 'components/VideoEvolutionProject/VideoEvolutionProjectModal'
import InvestmentByAdminModal from 'components/InvestmentByAdminModal/InvestmentByAdminModal'
import InvestmentModal from 'components/InvestmentModal/InvestmentModal'
import ProjectTimeProgressBars from 'components/ProjectTimeProgressBar/ProjectTimeProgressBar'
import ProjectInvestmentsProgressBars from './ProjectInvestmentsProgressBar'
import OficialProjectVideo from './OficialProjectVideo'
import { getCHGGInvestment } from '../helpers/currency-helpers'
import role from 'config/roles/roles'
import { matchNumberToCurrency, matchCurrencyToDecimal } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import { matchProjectStatus } from 'shared/matchData/matchData'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'
// import { TEN_DAYS_IN_MILLISECONDS } from 'local_redux/constants/investmentConstants'
import { registerLog } from 'local_redux/actions/logActions'
import { LOG_SEE_INFO_PROJECT_ACTION } from 'local_redux/constants/logConstants'
import styles from '../styles/projectCardStyles'

const useStyles = makeStyles(styles)

const ProjectCard = ({ project, index }) => {
	const classes = useStyles()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const [investmentModal, setInvestmentModal] = useState(false)
	const [showInvestmentInfo, setShowInvestmentInfo] = useState({})
	const [totalInvertible, setTotalInvertible] = useState(0)
	const [totalInvestment, setTotalInvestments] = useState(0)
	const [openVideo, setOpenVideo] = useState(false)
	const [openVideoOficial, setOpenVideoOficial] = useState(false)
	const [dataVideo, setDataVideo] = useState(null)
	const [imageLoaded, setImageLoaded] = useState(false)

	const { investmentRegisterSuccess } = useSelector((state) => state.investmentRegister)
	const { userInfo } = useSelector((state) => state.userLogin)

	// const publishDate = new Date(project?.publishDate).getTime()

	useEffect(() => {
		let totalInvestmentsAmount = 0
		project.investments.forEach((item) => {
			totalInvestmentsAmount += Number(matchCurrencyToDecimal(item.amount))
		})

		const totalInvestmentPlusCHGGInvestment =
			totalInvestmentsAmount +
			getCHGGInvestment(Number(matchCurrencyToDecimal(project.totalInvestment)), project.admissibleCoinvestment)

		const amountInvertible = Number(matchCurrencyToDecimal(project.maxInvertible)) - totalInvestmentsAmount
		setTotalInvestments(matchNumberToCurrency(totalInvestmentPlusCHGGInvestment))
		setTotalInvertible(matchNumberToCurrency(amountInvertible))
	}, [investmentRegisterSuccess])

	const registerLogAndNavigate = async () => {
		await dispatch(
			registerLog({
				user: {
					id: userInfo._id,
					role: userInfo.role,
					name: userInfo.name,
					lastName: userInfo.lastName,
					email: userInfo.email,
				},
				project: {
					id: project._id,
					projectAddress: project.projectAddress,
				},
				type: {
					type: 'action',
					actionType: LOG_SEE_INFO_PROJECT_ACTION,
				},
			}),
		)

		navigate(`/admin/projects?projectId=${project._id}`)
	}
	const validateActions = (lower) => {
		if (lower) {
			return Number(matchCurrencyToDecimal(totalInvertible.toString())) < 1 || !project.invertible
		} else {
			return Number(matchCurrencyToDecimal(totalInvertible.toString())) > 1 && project.invertible
		}
	}
	const showInvestmentHandler = () => {
		setShowInvestmentInfo({ ...project, expand: false })
		setInvestmentModal(true)
	}
	const handleCloseInvestmentModal = () => {
		setInvestmentModal(false)
		setShowInvestmentInfo({})
	}
	const showVideo = (project, type) => {
		setDataVideo(project)
		if (type === 1) {
			setOpenVideoOficial(true)
		} else {
			setOpenVideo(true)
		}
	}

	return (
		<GridItem xs={12} sm={6} md={5} lg={4} xl={3} className={classes.root}>
			<Card product className={`${classes.cardHover}`}>
				<CardHeader image className={`${classes.cardHeaderHover} ${index === 0 && classes.cardAnimation}`}>
					<div className={classes.cursorPointer} onClick={() => navigate(`/admin/projects?projectId=${project._id}`)}>
						{project.videoTour ? (
							// <video controls poster={`${axios.defaults.baseURL}/${project.coverImage}`} className={classes.videoCard}>
							<video controls poster={getUrlToOpenFile(project.coverImage, userInfo)} className={classes.videoCard}>
								<source src={getUrlToOpenFile(project.videoTour)} type='video/mp4' />
							</video>
						) : (
							<img
								// src={`${axios.defaults.baseURL}/${project.coverImage}`}
								src={getUrlToOpenFile(project.coverImage, userInfo)}
								alt={project._id}
								className={classes.imageCard}
								onLoad={() => setImageLoaded(true)}
							/>
						)}
						{imageLoaded && (
							<>
								{/* {(publishDate <= publishDate + TEN_DAYS_IN_MILLISECONDS || forceNewOpportunity) && ( */}
								{/* SE DESACTIVA LA ETIQUETA NUEVA OPORTUNIDAD POR NO TENER CLARO EL FUNCIONAMIENTO ==> AUTORIZADO POR CESAR MORAN */}
								{/* {(project?.isNewOpportunity || forceNewOpportunity) && (
									<div className={classes.newOpportunity}>Nueva Oportunidad</div>
								)} */}

								{validateActions(true) ? (
									<div className={classes.imageText}>Inversión completa</div>
								) : (
									Number(matchCurrencyToDecimal(totalInvertible.toString())) < 1 && (
										<div className={classes.imageText}>Inversión completa</div>
									)
								)}
							</>
						)}
					</div>

					{project.coverVideo && (
						<Tooltip title='Video oficial del proyecto'>
							<Button color='success' justIcon round className={classes.imageBtn} onClick={() => showVideo(project, 1)}>
								<VideoCameraBack />
							</Button>
						</Tooltip>
					)}
				</CardHeader>
				<CardBody className={classes.cardBodyWithMargin}>
					<div className={classes.cardHoverUnder}>
						<Grid container columnSpacing={1} pl={1} pr={1}>
							<Grid item xs={validateActions(true) && userInfo?.role === role.INVESTOR_ROLE ? 12 : 7}>
								<Button size='sm' color='primary' block className={classes.buttonCard} onClick={registerLogAndNavigate}>
									<span className={classes.spanButtonCard}>Ver Información</span>
								</Button>
							</Grid>
							<Grid item xs={5}>
								{validateActions() && userInfo.role === role.INVESTOR_ROLE && (
									<Button
										size='sm'
										color='primary'
										block
										onClick={() => {
											showInvestmentHandler(project._id)
										}}
										disabled={!project?.invertible}
									>
										Invertir
									</Button>
								)}
								{userInfo.role !== role.INVESTOR_ROLE && (
									<Button
										size='sm'
										color='primary'
										block
										disabled={!project.invertible}
										onClick={() => {
											showInvestmentHandler(project._id)
										}}
									>
										Invertir
									</Button>
								)}
							</Grid>
						</Grid>
					</div>
					<h4 className={classes.cardProductTitle}>{project?.projectAddress}</h4>
					<p className={classes.cardProductDesciprion}>{project?.description}</p>
				</CardBody>
				<GridItem xs={12} className={classes.totalInvertibleContainer}>
					<div className={classes.projectStatusContainer}>
						<Typography variant='body2'>
							<span style={{ color: '#6f6f6f' }}>Estado · </span>
							<span className={classes.projectStatus}>{matchProjectStatus(project?.status)}</span>
						</Typography>
						{project.images?.length > 0 && project?.status === 'workInProgress' && (
							<Tooltip title='Ver video de evolución de la obra'>
								<Button justIcon size='sm' round className={classes.videoIcon} onClick={() => showVideo(project)}>
									<Videocam />
								</Button>
							</Tooltip>
						)}
					</div>
					{validateActions() ? (
						<Typography variant='body2'>
							<span style={{ color: '#6f6f6f' }}>Usted puede invertir · </span>
							{totalInvertible}
						</Typography>
					) : (
						<Typography variant='body2'>Inversión completa</Typography>
					)}
				</GridItem>
				<GridItem xs={12}>
					<ProjectTimeProgressBars startDate={project.startDateUpdated} endDate={project.finishDateUpdated} />
				</GridItem>
				<GridItem xs={12} className={classes.investmentsProgressBarContainer}>
					<ProjectInvestmentsProgressBars
						invertible={project.invertible}
						totalInvestment={totalInvestment}
						projectCost={project.totalInvestment}
						totalInvertible={totalInvertible}
						userInfo={userInfo}
					/>
				</GridItem>
			</Card>
			{investmentModal &&
				(userInfo.role === role.INVESTOR_ROLE ? (
					<InvestmentModal
						handleCloseInvestmentModal={handleCloseInvestmentModal}
						investmentModal={investmentModal}
						showInvestmentInfo={showInvestmentInfo}
					/>
				) : (
					<InvestmentByAdminModal
						handleCloseInvestmentByAdminModal={handleCloseInvestmentModal}
						investmentByAdminModal={investmentModal}
						showInvestmentByAdminId={showInvestmentInfo._id}
					/>
				))}
			{openVideo && (
				<VideoEvolutionProjectModal openVideo={openVideo} setOpenVideo={setOpenVideo} dataVideo={dataVideo} />
			)}
			{openVideoOficial && (
				<OficialProjectVideo
					openVideoOficial={openVideoOficial}
					setOpenVideoOficial={setOpenVideoOficial}
					dataVideo={dataVideo}
				/>
			)}
		</GridItem>
	)
}

export default ProjectCard
