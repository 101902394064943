import { useContext, useState } from 'react'
import { Close } from '@material-ui/icons'
import { Dialog, DialogTitle, DialogContent, makeStyles, DialogActions } from '@material-ui/core'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Typography from '@mui/material/Typography'
import CustomInput from 'components/CustomInput/CustomInput'
import { LiquidatedProjectContext } from 'contexts/liquidatedProjectContext'
import styles from '../styles/liquidateValidationStyles'

const useStyles = makeStyles(styles)

const LiquidateValidation = () => {
  const classes = useStyles()

  const { selectedProject, liquidateValidationModal, setLiquidateValidationModal } =
    useContext(LiquidatedProjectContext)

  const [phrase, setPhrase] = useState('')

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal,
      }}
      open={liquidateValidationModal}
      keepMounted
      onClose={() => setLiquidateValidationModal(false)}
      aria-labelledby='liquidated-project-title'
      aria-describedby='liquidated-project-description'
    >
      <DialogTitle id='liquidated-project-title' disableTypography className={classes.modalHeader}>
        <Button
          justIcon
          className={classes.modalCloseButton}
          key='close'
          aria-label='Close'
          color='transparent'
          onClick={() => setLiquidateValidationModal(false)}
        >
          <Close className={classes.modalClose} />
        </Button>
        <span style={{ fontSize: '18px' }}>
          <b>Liquidar Proyecto</b>
        </span>
      </DialogTitle>
      <DialogContent id='user-modal-liquidated-description' className={classes.modalBody}>
        <GridContainer style={{ marginBottom: '2rem' }}>
          <GridItem xs={12}>
            <Typography>
              ¿Está seguro de que desea liquidar el proyecto <strong>{selectedProject.projectAddress}</strong>?
            </Typography>
            <br />
            <Typography>Una vez que se liquide el proyecto se ejecutaran las siguientes acciones.</Typography>
            <ul>
              <li>
                Actualizar el proyecto a estado <b>liquidado.</b>
              </li>
              <li>
                Actualizar <b>inversiones</b> relacionadas.
              </li>
              <li>
                Notificar a los <b>inversores</b> mediante el sistema.
              </li>
              <li>
                Notificar a los <b>inversores</b> via email.
              </li>
              <li>
                Enviar <b>datos</b> via zapier.
              </li>
              <li>
                Generar <b>csv para remesa</b> de pagos.
              </li>
            </ul>
            <Typography>
              Escriba la siguiente frase para <b>Liquidar el proyecto</b> .
            </Typography>
            <p className={classes.deletePhrase}>absolutamente seguro</p>
          </GridItem>
          <GridItem xs={12}>
            <CustomInput
              labelText={'Habilitar boton de liquidar'}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: phrase,
                onChange: (e) => setPhrase(e.target.value),
                type: 'text',
              }}
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <GridContainer>
          <GridItem xs={12}>
            <Button block type='submit' form='projectInputForm' disabled={phrase !== 'absolutamente seguro'}>
              Liquidar
            </Button>
          </GridItem>
        </GridContainer>
      </DialogActions>
    </Dialog>
  )
}

export default LiquidateValidation
