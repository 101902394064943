export const notificationsDelayedListColumns = () => {
	return [
		{
			header: 'Titulo',
			accessor: 'title',
		},
		{
			header: 'Mensaje',
			accessor: 'message',
		},
	]
}
