import { format } from 'date-fns'
import { es } from 'date-fns/locale'

export const pdlListMapper = (projects) => {
	return projects?.map((project) => {
		return {
			id: project?._id,
			data: project,
			deleted: project?.deleted,
			liquidated: project?.liquidated,
			liquitationDate: project?.liquitationDate,
			inversor: project?.profileInvestor?.businessName,
			projectAddress: project?.projectAddress,
			registerDate: project?.date,
			import: project?.amountPdl,
			contractUrl: project?.contractUrl,
			incomeRecivedDate: project?.incomeRecivedDate,
			user: project?.user?.name,
			typeOfInterest: project?.typeOfInterest,
			status: project?.status,
			hasComments: project?.hasComments ? 'Tiene' : 'No tiene',
			investorProfileId: project?.profileInvestor?._id,
		}
	})
}

export const excelPdlListMapper = ({ filteredRows, userInfo }) => {
	const isAuthorizedUser = userInfo?.isSuper || userInfo?.isContabilidad || userInfo?.isAdmin
	return filteredRows?.map((item) => {
		return {
			deleted: item?.deleted,
			liquidated: item?.liquidated ? 'Activo' : 'Liquidado',
			inversor: item?.inversor,
			projectAddress: item?.projectAddress || 'sin dirección',
			registerDate: item.registerDate
				? format(new Date(item.registerDate), 'dd/MM/yyyy', { locale: es })
				: item.registerDate,
			import: item?.import,
			contractUrl: item.contractUrl ? 'Firmado' : 'Pendiente',
			incomeRecivedDate: item.incomeRecivedDate
				? format(new Date(item.incomeRecivedDate), 'dd/MM/yyyy', { locale: es })
				: !isAuthorizedUser
				? 'Pendiente de confirmar'
				: 'Confirmar ingreso',
			user: item?.user,
			typeOfInterest: item?.typeOfInterest,
			status: item?.status?.status === 'deleted' ? 'Anulado' : !item?.status?.liquidated ? 'Activo' : 'Liquidado',
		}
	})
}
