import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { Assignment } from '@mui/icons-material'
import Button from 'components/CustomButtons/Button'
import Loader from 'components/Loader/Loader'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CardHeader from 'components/Card/CardHeader'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import Clearfix from 'components/Clearfix/Clearfix'
import Card from 'components/Card/Card'
import CardIcon from 'components/Card/CardIcon'
import CardBody from 'components/Card/CardBody'
import InputsForms from './InputsForms'
import { matchProjectStatus } from 'shared/matchData/matchData'
import { PROJECT_UPDATE_RESET, PROJECT_BY_ID_RESET } from 'local_redux/constants/projectConstant'
import { updateProject, getProjectById } from 'local_redux/actions/projectAction'
import styles from '../styles/projectModalStyles'

const useStyles = makeStyles(styles)

const ProjectUpdateScreen = ({ project }) => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const navigate = useNavigate()

	const [data, setData] = useState({})
	const [errorImage, setErrorImage] = useState('')
	const [imagesUrl, setImagesUrl] = useState()
	const [videosUrl, setVideosUrl] = useState()
	const [startDateUpdated, setStartDateUpdated] = useState()
	const [finishDateUpdated, setFinishDateUpdated] = useState()

	const { loadingProjectUpdate, errorProjectUpdate, successProjectUpdate } = useSelector((state) => state.projectUpdate)
	const { loadingProjectById, projectByIdData, successProjectById } = useSelector((state) => state.projectById)
	const { userInfo } = useSelector((state) => state.userLogin)

	useEffect(() => {
		if (!successProjectById) {
			dispatch(getProjectById(project))
		}
	}, [successProjectById])
	useEffect(() => {
		if (projectByIdData) {
			setData(projectByIdData)
			setStartDateUpdated(projectByIdData.startDateUpdated)
			setFinishDateUpdated(projectByIdData.finishDateUpdated)
		}
	}, [projectByIdData])
	useEffect(() => {
		if (projectByIdData) {
			setImagesUrl(data.coverImage)
		}
	}, [projectByIdData])
	useEffect(() => {
		let timeOut = ''
		if (successProjectUpdate) {
			timeOut = setTimeout(() => {
				dispatch({ type: PROJECT_BY_ID_RESET })
				dispatch({ type: PROJECT_UPDATE_RESET })
			}, 500)
		}
		return () => clearTimeout(timeOut)
	}, [successProjectUpdate])
	useEffect(() => {
		return () => dispatch({ type: PROJECT_BY_ID_RESET })
	}, [dispatch])

	const updateProjectHandler = (e) => {
		e.preventDefault()

		if (!data.coverImage) {
			return setErrorImage('La imagen es requerida')
		}
		const projectData = {
			_id: data._id,
			projectCost: data.projectCost,
			startDateDossier: data.startDateDossier,
			finishDateDossier: data.finishDateDossier,
			startDateUpdated: startDateUpdated,
			finishDateUpdated: finishDateUpdated,
			owner: data.owner,
			status: data.status,
			invertible: data.invertible,
			published: data.published,
			publishDate: data.published ? new Date().getTime() : undefined,
			projectAddress: data.projectAddress,
			actualValue: data.actualValue,
			description: data.description,
			projectSaleValueUpdated: data.projectSaleValueUpdated,
			projectSaleValueDossier: data.projectSaleValueDossier,
			projectSurface: data.projectSurface,
			projectStatus: matchProjectStatus(data.status, true),
			simpleNoteFile: data.projectPdf ? data.projectPdf : '',
			images: data.coverImage,
			videos: data.coverVideo,
			deedOfPurchase: data.deedOfPurchase,
			simpleNotePriorToPurchase: data.simpleNotePriorToPurchase,
			simpleNotePurchasedProperty: data.simpleNotePurchasedProperty,
			acquisitionExpenses: data.acquisitionExpenses,
			billsOfExpenses: data.billsOfExpenses,
			totalInvestment: data.totalInvestment,
			admissibleCoinvestment: data.admissibleCoinvestment,
			deedOfSale: data.deedOfSale,
			reason: data.reason,
			blueprintEA: data.blueprintEA,
			updaterUserId: userInfo._id,
		}

		dispatch(updateProject(projectData))
	}
	const imagesValidation = imagesUrl || data.coverImage

	return (
		<Card>
			<CardHeader color='primary' icon>
				<CardIcon color='primary'>
					<Assignment />
				</CardIcon>
			</CardHeader>
			<CardBody>
				<GridContainer>
					{loadingProjectById ? (
						<GridItem xs={12} className={classes.LoaderContainer}>
							<Loader
								message={'Cargando información del proyecto'}
								config={{ marginTop: '30px', width: '100%', alignItems: 'center' }}
							/>
						</GridItem>
					) : (
						data?._id && (
							<GridItem xs={12}>
								<form onSubmit={updateProjectHandler}>
									<InputsForms
										data={data}
										setData={setData}
										startDateUpdated={startDateUpdated}
										setStartDateUpdated={setStartDateUpdated}
										finishDateUpdated={finishDateUpdated}
										setFinishDateUpdated={setFinishDateUpdated}
										imagesValidation={imagesValidation}
										imagesUrl={imagesUrl}
										setImagesUrl={setImagesUrl}
										videosUrl={videosUrl}
										setVideosUrl={setVideosUrl}
									/>
									<GridContainer>
										{errorImage && !imagesValidation && (
											<GridItem xs={12} sm={12}>
												<SnackbarContent message={errorImage} color='danger' />
											</GridItem>
										)}
										{errorProjectUpdate && (
											<GridItem xs={12} sm={12}>
												<SnackbarContent message={errorProjectUpdate} color='danger' />
											</GridItem>
										)}
									</GridContainer>

									<GridContainer>
										<GridItem xs={6}>
											<Button onClick={() => navigate('/admin/list-projects')} color='primary' block>
												Volver
											</Button>
										</GridItem>
										<GridItem xs={6}>
											<Button
												type='submit'
												color={successProjectUpdate ? 'success' : 'primary'}
												block
												disabled={loadingProjectUpdate}
											>
												{loadingProjectUpdate
													? 'Actualizando...'
													: successProjectUpdate
													? 'Proyecto Actualizado'
													: 'Actualizar Proyecto'}
											</Button>
											<Clearfix />
										</GridItem>
									</GridContainer>
								</form>
							</GridItem>
						)
					)}
				</GridContainer>
			</CardBody>
		</Card>
	)
}

export default ProjectUpdateScreen
