import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PeopleAlt } from '@mui/icons-material'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridItem from 'components/Grid/GridItem'
import ReactVirtualizedTable from 'components/ReactVirtualizedTable/ReactVirtualizedTable'
import GridContainer from 'components/Grid/GridContainer'
import DeletePreInvestorModal from './components/DeletePreInvestorsModal'
import UpdatePreRegisterLinkModal from './components/UpdatePreRegisterLinkModal'
import { preInvestorListMapper } from './mappers/preInvestorListMapper'
import { preInvestorListColumn } from './helpers/preInvestorListColumn'
import { getPreRegisterList } from 'local_redux/actions/preRegisterActions'
import { PRE_REGISTER_LIST_RESET } from 'local_redux/constants/preRegisterConstants'

const PreInvestorListScreen = () => {
	const dispatch = useDispatch()

	const [data, setData] = useState([])
	const [confirmTextInClipBoard, setConfirmTextInClipBoard] = useState('')
	const [updatePreRegisterLinkModal, setUpdatePreRegisterLinkModal] = useState(false)
	const [showUpdatePreRegisterLinkInfo, setShowUpdatePreRegisterLinkInfo] = useState({})
	const [deleteInvestorModal, setDeleteInvestorModal] = useState(false)
	const [showDeleteInvestorInfo, setShowDeleteInvestorInfo] = useState({})

	const { loadingPreRegisterList, successPreRegisterList, preRegisterListData, errorPreRegisterList } = useSelector(
		(state) => state.preRegisterList,
	)
	const { successPreRegisterDelete, preRegisterDeleteData } = useSelector((state) => state.preRegisterDelete)
	const { userInfo } = useSelector((state) => state.userLogin)
	const { successPreRegisterUpdate } = useSelector((state) => state.preRegisterUpdate)

	useEffect(() => {
		dispatch(getPreRegisterList())
	}, [])
	useEffect(() => {
		if (preRegisterListData) {
			const data = preInvestorListMapper(preRegisterListData)
			setData(data)
		}
	}, [successPreRegisterList])
	useEffect(() => {
		if (errorPreRegisterList === 'No se encontraron pre-registros.') {
			setData([])
		}
	}, [errorPreRegisterList])
	useEffect(() => {
		if (successPreRegisterUpdate) {
			dispatch({ type: PRE_REGISTER_LIST_RESET })
		}
		if (successPreRegisterDelete) {
			let newData = data.filter((item) => item.id !== preRegisterDeleteData._id)
			setData(newData)
		}
	}, [successPreRegisterUpdate, successPreRegisterDelete])
	useEffect(() => {
		return () => dispatch({ type: PRE_REGISTER_LIST_RESET })
	}, [dispatch])
	useEffect(() => {
		let timeOut
		if (confirmTextInClipBoard) {
			timeOut = setTimeout(() => {
				setConfirmTextInClipBoard('')
			}, 1000)
		}
		return () => clearTimeout(timeOut)
	}, [confirmTextInClipBoard])

	const copyOnClipBoard = (url) => {
		navigator.clipboard.writeText(url)
		setConfirmTextInClipBoard('Copiado')
	}
	const handleOpenUpdatePreRegisterLinkModal = (item) => {
		setShowUpdatePreRegisterLinkInfo(item)
		setUpdatePreRegisterLinkModal(true)
	}
	const handleCloseUpdatePreRegisterLinkModal = () => {
		setUpdatePreRegisterLinkModal(false)
		setShowUpdatePreRegisterLinkInfo({})
	}
	const handleOpenDeletePreInvestorModal = (item) => {
		setShowDeleteInvestorInfo(item)
		setDeleteInvestorModal(true)
	}
	const handleCloseUpdatePreInvestorModal = () => {
		setDeleteInvestorModal(false)
		setShowDeleteInvestorInfo({})
	}

	return (
		<GridContainer>
			<GridItem xs={12}>
				<Card>
					<CardHeader color='primary' icon>
						<CardIcon color='primary'>
							<PeopleAlt />
						</CardIcon>
						<h4>
							<b>Pre Inversores</b>
						</h4>
					</CardHeader>
					<CardBody>
						<ReactVirtualizedTable
							columns={preInvestorListColumn({
								userInfo,
								copyOnClipBoard,
								handleOpenUpdatePreRegisterLinkModal,
								handleOpenDeletePreInvestorModal,
							})}
							data={data}
							loading={Boolean(loadingPreRegisterList)}
							success={Boolean(successPreRegisterList)}
							error={errorPreRegisterList}
						/>
					</CardBody>
				</Card>
				{updatePreRegisterLinkModal && (
					<UpdatePreRegisterLinkModal
						handleCloseUpdatePreRegisterLinkModal={handleCloseUpdatePreRegisterLinkModal}
						updatePreRegisterLinkModal={updatePreRegisterLinkModal}
						showUpdatePreRegisterLinkInfo={showUpdatePreRegisterLinkInfo}
					/>
				)}
				{deleteInvestorModal && (
					<DeletePreInvestorModal
						handleDeletePreinvestor={handleCloseUpdatePreInvestorModal}
						deletePreInvestorModal={deleteInvestorModal}
						deletePreInvestorInfo={showDeleteInvestorInfo}
					/>
				)}
			</GridItem>
		</GridContainer>
	)
}

export default PreInvestorListScreen
