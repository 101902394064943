import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
import styles from '../styles/filterInvestmentStyles'

const useStyles = makeStyles(styles)

const FilterInvesment = ({ filter, setFilter }) => {
  const classes = useStyles()

  return (
    <FormControl fullWidth>
      <InputLabel id='log-type-select'>{!filter ? 'Buscar mis Inversiones' : `Inversiones`}</InputLabel>
      <Select
        MenuProps={{
          className: classes.selectMenu,
        }}
        labelId='log-type-select'
        id='log-type'
        label='Tipo *'
        value={filter}
        onChange={(e) => {
          setFilter(e.target.value)
        }}
      >
        {['Activas', 'Finalizadas'].map((entityType, index) => (
          <MenuItem
            value={entityType}
            key={index}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
          >
            {entityType}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default FilterInvesment
