const styles = {
  gridSearch: {
    marginTop: '3rem',
    textAlign: 'center',
  },
  iconSearch: {
    '& svg': {
      fontSize: '130px',
      color: '#999',
    },
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  filterInputsRoot: {
    marginBottom: '10px',
  },
}

export default styles
