import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import { CoPresent, EuroSymbol } from '@mui/icons-material'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Button from 'components/CustomButtons/Button'
import CardBody from 'components/Card/CardBody'
import CardIcon from 'components/Card/CardIcon'
import CustomWarningMessage from 'components/CustomWarningMessage/CustomWarningMessage'
import Loader from 'components/Loader/Loader'
import ReactTable from 'components/ReactTable/ReactTable'
import ExcelDefaultExport from 'components/ReactTable/components/ExcelDefaultExport'
import { FilterReactTableContext } from 'contexts/filterReactTableContext'
import PrescriptionLiquidateModal from './PrescriptionLiquidateModal'
import LiquidatePeriodFilter from './LiquidatePeriodFilter'
import { replaceStringToNum, matchNumberToCurrency } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import { referalListByPrescriberMapper } from 'shared/mappers/prescriber-mappers'
import { REFERAL_LIST_BY_PRESCRIBER } from 'shared/helpers/table-column-headers'
import { getPrescriberRefererListById } from 'local_redux/actions/prescriberActions'
import { PRESCRIBER_REFERER_LIST_BY_ID_RESET } from 'local_redux/constants/prescriberConstants'
import styles from '../styles/prescriberListScreenstyles'
import { getTotalImport } from '../helpers/getTotalImport'

const useStyle = makeStyles(styles)

const ReferalListByPrescriber = () => {
	const classes = useStyle()
	const dispatch = useDispatch()
	const search = useLocation().search
	const referalQuery = new URLSearchParams(search).get('referalId')

	const initialTimeState = {
		startDate: null,
		endDate: null,
	}

	const [prescriptionLiquidateModal, setPrescriptionLiquidateModal] = useState(false)
	const [filterDate] = useState(initialTimeState)
	const [periodFilterDate, setPeriodFilterDate] = useState(initialTimeState)
	const [dataDB, setDataDB] = useState([])
	const [data, setData] = useState([])
	const [exportRows, setExportRows] = useState([])

	const { setfilteredRows } = useContext(FilterReactTableContext)

	const { loadingPrescriberRefererListById, successPrescriberRefererListById, prescriberRefererListByIdData } =
		useSelector((state) => state.prescriberRefererListById)
	const { successInvestmentsUpdatePrescriptionLiquidated, investmentsUpdatePrescriptionLiquidatedData } = useSelector(
		(state) => state.investmentsUpdatePrescriptionLiquidated,
	)

	useEffect(() => {
		let filteredInvestments = dataDB
		if (periodFilterDate.startDate && periodFilterDate.endDate) {
			filteredInvestments = dataDB.filter((investment) => {
				const liquidatedDate = investment?.finished[0]?.createdAt ? new Date(investment?.finished[0]?.createdAt) : null
				const investmentDate = investment.incomeRecivedDate ? new Date(investment.incomeRecivedDate) : null
				const startDate = new Date(periodFilterDate.startDate)
				const endDate = new Date(periodFilterDate.endDate)
				return (
					(liquidatedDate && liquidatedDate > startDate && liquidatedDate < endDate) ||
					(!liquidatedDate && investmentDate && investmentDate < endDate)
				)
			})
		}
		const prescribers = referalListByPrescriberMapper(filteredInvestments, periodFilterDate)
		setData(prescribers)
	}, [dataDB, periodFilterDate])

	useEffect(() => {
		if (successInvestmentsUpdatePrescriptionLiquidated) {
			const prescriptiolLiquidated = data.map((item) => {
				item.prescriptionLiquidatedDate = investmentsUpdatePrescriptionLiquidatedData.date
				return item
			})
			setData(prescriptiolLiquidated)
			setTimeout(() => {
				// eslint-disable-next-line no-use-before-define
				handleClosePrescriptionLiquidateModal()
			}, 1500)
		}
	}, [successInvestmentsUpdatePrescriptionLiquidated])

	useEffect(() => {
		dispatch(getPrescriberRefererListById(referalQuery))
		return () => dispatch({ type: PRESCRIBER_REFERER_LIST_BY_ID_RESET })
	}, [])
	useEffect(() => {
		if (successPrescriberRefererListById) {
			setDataDB(prescriberRefererListByIdData?.investments || [])
		}
	}, [successPrescriberRefererListById])
	useEffect(() => {
		const newRows = data.map((row) => ({
			...row,
			incomeRecivedDate: row?.incomeRecivedDate?.replaceAll('-', '/'),
			liquidatedDate: row?.liquidatedDate?.replaceAll('-', '/'),
			amount: replaceStringToNum(row.amount ? row.amount : ''),
			startDate: row?.startDate?.replaceAll('-', '/'),
			endDate: row?.endDate?.replaceAll('-', '/'),
			days: row?.days,
			bonus: replaceStringToNum(row?.bonus ? row.bonus : ''),
		}))

		newRows[0] = {
			...newRows[0],
			totalBonus: matchNumberToCurrency(getTotalImport(data?.map((item) => item?.bonus || 0))),
			totalImport: matchNumberToCurrency(getTotalImport(data?.map((item) => item?.amount || 0))),
		}

		setExportRows(newRows)
		setfilteredRows(newRows)
	}, [data])
	//TEST CODE 24/10
	// const handleFilter = ({ endDate }) => {
	// 	dispatch(
	// 		getPrescriberRefererListById({
	// 			_id: referalQuery,
	// 			startDate: format(new Date(filterDate.startDate), 'yyyy-MM-dd'),
	// 			endDate: format(new Date(endDate), 'yyyy-MM-dd'),
	// 		}),
	// 	)
	// }
	const handlePeriodFilter = ({ endDate }) => {
		setPeriodFilterDate({
			startDate: periodFilterDate.startDate,
			endDate: endDate,
		})
	}
	const handleClosePrescriptionLiquidateModal = () => {
		setPrescriptionLiquidateModal(false)
	}

	return (
		<GridContainer>
			<GridItem xs={12} md={10} lg={5}>
				<div className={classes.filters}>
					{/* <TimeFilterCard filterDate={filterDate} setFilterDate={setFilterDate} handleFilter={handleFilter} /> TEST FUNCTION */}
					<LiquidatePeriodFilter
						filterDate={periodFilterDate}
						setFilterDate={setPeriodFilterDate}
						handleFilter={handlePeriodFilter}
					/>
				</div>
			</GridItem>
			<GridItem xs={12}>
				{loadingPrescriberRefererListById ? (
					<Loader message={'cargando inversiones'} config={{ marginTop: '30px', alignItems: 'center' }} />
				) : (
					successPrescriberRefererListById && (
						<>
							<h4>
								{`Inversiones de referidos por `}
								<strong>{prescriberRefererListByIdData.businessName}</strong>
							</h4>
							{prescriberRefererListByIdData?.investments.length > 0 ? (
								<GridContainer>
									<GridItem xs={12}>
										<Card>
											<CardHeader color='primary' icon>
												<CardIcon color='primary'>{<CoPresent />}</CardIcon>
												<GridItem xs={12} style={{ display: 'flex', justifyContent: 'end', marginTop: '15px' }}>
													{data.length > 0 && (
														<div style={{ display: 'flex' }}>
															<ExcelDefaultExport
																excelName={`Prescripción ${prescriberRefererListByIdData.businessName}`}
																data={exportRows}
															/>
															<Button
																color='primary'
																style={{ height: '41px' }}
																onClick={() => setPrescriptionLiquidateModal(true)}
															>
																Liquidar Periodo
															</Button>
														</div>
													)}
												</GridItem>
											</CardHeader>
											<CardBody>
												<ReactTable columns={REFERAL_LIST_BY_PRESCRIBER} data={data} />
											</CardBody>
										</Card>
									</GridItem>
									<GridItem xs={6}>
										<Card>
											<CardHeader color='primary' icon>
												<CardIcon color='primary'>{<EuroSymbol />}</CardIcon>
												<h4 style={{ color: 'black' }} className={classes.cardIconTitle}>
													Resumen
												</h4>
											</CardHeader>
											<CardBody>
												<GridContainer>
													<GridItem xs={12}>
														<span>Total importe de Inversión: </span>
														<span style={{ fontWeight: 'bold' }}>
															{matchNumberToCurrency(getTotalImport(data.map((item) => item.amount)))}
														</span>
													</GridItem>
													<GridItem xs={12}>
														<span>Total Honorarios: </span>
														<span style={{ fontWeight: 'bold' }}>
															{matchNumberToCurrency(getTotalImport(data.map((item) => item.bonus)))}
														</span>
													</GridItem>
												</GridContainer>
											</CardBody>
										</Card>
									</GridItem>
								</GridContainer>
							) : (
								<CustomWarningMessage text='No se encontraron inversiones en el rango de fechas' />
							)}
						</>
					)
				)}
				{prescriptionLiquidateModal && (
					<PrescriptionLiquidateModal
						handleClosePrescriptionLiquidateModal={handleClosePrescriptionLiquidateModal}
						prescriptionLiquidateModal={prescriptionLiquidateModal}
						liquidatedPrescriberData={{ prescriberName: prescriberRefererListByIdData.businessName, date: filterDate }}
					/>
				)}
			</GridItem>
		</GridContainer>
	)
}

export default ReferalListByPrescriber
