import axios from 'axios'
import { useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import { makeStyles } from '@material-ui/core'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import { InsertDriveFile } from '@material-ui/icons'
import { Delete, Visibility } from '@material-ui/icons'
import TransparencyRefs from './TransparencyRefs'
import isValidUrl from 'shared/helpers/valid-url'
import { generateTokenCode } from 'shared/helpers/generateTokenCode'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'
import styles from '../styles/transparencyListStyles'

const useStyles = makeStyles(styles)

const Demo = styled('div')(() => ({
	backgroundColor: '#D9E0E0',
	marginBottom: '10px',
	marginTop: '20px',
	borderRadius: '10px',
}))

const TransparencyList = ({
	data,
	selectFileHandler,
	handleFileChange,
	handleFileRemove,
	simplePdfFile,
	deedOfPurchaseFile,
	simpleNotePriorToPurchaseFile,
	simpleNotePurchasedPropertyFile,
	acquisitionExpensesFile,
	billsOfExpensesFile,
	deedOfSaleFile,
	blueprintEA,
}) => {
	const classes = useStyles()
	const { userInfo } = useSelector((state) => state.userLogin)

	const isStringImage = (text) => {
		return typeof text === 'string' && text.length > 0
	}
	return (
		<Box sx={{ flexGrow: 1, margin: '0 auto' }}>
			<Grid container justifyContent='center' spacing={2}>
				<Grid item xs={11}>
					<Demo className={classes.cardRoot}>
						<p className={classes.cardTitle}>Transparencias</p>
						<List dense={true}>
							<ListItem
								className={classes.listItem}
								secondaryAction={
									<>
										{!data.deedOfPurchase ? (
											<IconButton aria-label='add' size='small' onClick={() => selectFileHandler(deedOfPurchaseFile)}>
												<InsertDriveFile fontSize='inherit' />
											</IconButton>
										) : (
											<>
												<IconButton
													aria-label='visibility'
													size='small'
													onClick={() =>
														window.open(
															typeof data.deedOfPurchase === 'object'
																? URL.createObjectURL(data.deedOfPurchase)
																: isValidUrl(data.deedOfPurchase)
																? data.deedOfPurchase
																: getUrlToOpenFile(data.deedOfPurchase, userInfo),
														)
													}
												>
													<Visibility fontSize='inherit' />
												</IconButton>
											</>
										)}
										{data.deedOfPurchase && (
											<IconButton
												aria-label='delete'
												size='small'
												onClick={() => {
													handleFileRemove('deedOfPurchase')
												}}
											>
												<Delete fontSize='inherit' color={!isStringImage(data.deedOfPurchase) ? 'action' : 'error'} />
											</IconButton>
										)}
									</>
								}
							>
								<ListItemText className={classes.listItemText} primary='Escritura de compra' />
							</ListItem>
							<Divider variant='fullWidth' component='li' />
							<ListItem
								className={classes.listItem}
								secondaryAction={
									<>
										{!data.simpleNotePriorToPurchase ? (
											<IconButton
												aria-label='add'
												size='small'
												onClick={() => selectFileHandler(simpleNotePriorToPurchaseFile)}
											>
												<InsertDriveFile fontSize='inherit' />
											</IconButton>
										) : (
											<>
												<IconButton
													aria-label='visibility'
													size='small'
													onClick={() =>
														window.open(
															typeof data.simpleNotePriorToPurchase === 'object'
																? URL.createObjectURL(data.simpleNotePriorToPurchase)
																: isValidUrl(data.simpleNotePriorToPurchase)
																? data.simpleNotePriorToPurchase
																: getUrlToOpenFile(data.simpleNotePriorToPurchase, userInfo),
														)
													}
												>
													<Visibility fontSize='inherit' />
												</IconButton>
											</>
										)}
										{data.simpleNotePriorToPurchase && (
											<IconButton
												aria-label='delete'
												size='small'
												onClick={() => {
													handleFileRemove('simpleNotePriorToPurchase')
												}}
											>
												<Delete
													fontSize='inherit'
													color={!isStringImage(data.simpleNotePriorToPurchase) ? 'action' : 'error'}
												/>
											</IconButton>
										)}
									</>
								}
							>
								<ListItemText className={classes.listItemText} primary='Nota simple previa a compra' />
							</ListItem>
							<Divider variant='fullWidth' component='li' />
							<ListItem
								className={classes.listItem}
								secondaryAction={
									<>
										{!data.simpleNotePurchasedProperty ? (
											<IconButton
												aria-label='add'
												size='small'
												onClick={() => selectFileHandler(simpleNotePurchasedPropertyFile)}
											>
												<InsertDriveFile fontSize='inherit' />
											</IconButton>
										) : (
											<>
												<IconButton
													aria-label='visibility'
													size='small'
													onClick={() =>
														window.open(
															typeof data.simpleNotePurchasedProperty === 'object'
																? URL.createObjectURL(data.simpleNotePurchasedProperty)
																: isValidUrl(data.simpleNotePurchasedProperty)
																? data.simpleNotePurchasedProperty
																: getUrlToOpenFile(data.simpleNotePurchasedProperty, userInfo),
														)
													}
												>
													<Visibility fontSize='inherit' />
												</IconButton>
											</>
										)}
										{data.simpleNotePurchasedProperty && (
											<IconButton
												aria-label='delete'
												size='small'
												onClick={() => {
													handleFileRemove('simpleNotePurchasedProperty')
												}}
											>
												<Delete
													fontSize='inherit'
													color={!isStringImage(data.simpleNotePurchasedProperty) ? 'action' : 'error'}
												/>
											</IconButton>
										)}
									</>
								}
							>
								<ListItemText className={classes.listItemText} primary='Nota simple inmueble comprado' />
							</ListItem>
							<Divider variant='fullWidth' component='li' />

							<ListItem
								className={classes.listItem}
								secondaryAction={
									<>
										{!data.acquisitionExpenses ? (
											<IconButton
												aria-label='add'
												size='small'
												onClick={() => selectFileHandler(acquisitionExpensesFile)}
											>
												<InsertDriveFile fontSize='inherit' />
											</IconButton>
										) : (
											<>
												<IconButton
													aria-label='visibility'
													size='small'
													//   onClick={() => handleOpenViewDocument()}
													onClick={() =>
														window.open(
															typeof data.acquisitionExpenses === 'object'
																? URL.createObjectURL(data.acquisitionExpenses)
																: isValidUrl(data.acquisitionExpenses)
																? data.acquisitionExpenses
																: getUrlToOpenFile(data.acquisitionExpenses, userInfo),
														)
													}
												>
													<Visibility fontSize='inherit' />
												</IconButton>
											</>
										)}
										{data.acquisitionExpenses && (
											<IconButton
												aria-label='delete'
												size='small'
												onClick={() => {
													handleFileRemove('acquisitionExpenses')
												}}
											>
												<Delete
													fontSize='inherit'
													color={!isStringImage(data.acquisitionExpenses) ? 'action' : 'error'}
												/>
											</IconButton>
										)}
									</>
								}
							>
								<ListItemText className={classes.listItemText} primary='Gastos de adquisición' />
							</ListItem>
							<Divider variant='fullWidth' component='li' />
							<ListItem
								className={classes.listItem}
								secondaryAction={
									<>
										{!data.billsOfExpenses ? (
											<IconButton aria-label='add' size='small' onClick={() => selectFileHandler(billsOfExpensesFile)}>
												<InsertDriveFile fontSize='inherit' />
											</IconButton>
										) : (
											<>
												<IconButton
													aria-label='visibility'
													size='small'
													onClick={() =>
														window.open(
															typeof data.billsOfExpenses === 'object'
																? URL.createObjectURL(data.billsOfExpenses)
																: isValidUrl(data.billsOfExpenses)
																? data.billsOfExpenses
																: getUrlToOpenFile(data.billsOfExpenses, userInfo),
														)
													}
												>
													<Visibility fontSize='inherit' />
												</IconButton>
											</>
										)}
										{data.billsOfExpenses && (
											<IconButton
												aria-label='delete'
												size='small'
												onClick={() => {
													handleFileRemove('billsOfExpenses')
												}}
											>
												<Delete fontSize='inherit' color={!isStringImage(data.billsOfExpenses) ? 'action' : 'error'} />
											</IconButton>
										)}
									</>
								}
							>
								<ListItemText className={classes.listItemText} primary='Facturas de gastos' />
							</ListItem>
							<Divider variant='fullWidth' component='li' />
							<ListItem
								className={classes.listItem}
								secondaryAction={
									<>
										{!data.deedOfSale ? (
											<IconButton aria-label='add' size='small' onClick={() => selectFileHandler(deedOfSaleFile)}>
												<InsertDriveFile fontSize='inherit' />
											</IconButton>
										) : (
											<>
												<IconButton
													aria-label='visibility'
													size='small'
													onClick={() =>
														window.open(
															typeof data.deedOfSale === 'object'
																? URL.createObjectURL(data.deedOfSale)
																: isValidUrl(data.deedOfSale)
																? data.deedOfSale
																: getUrlToOpenFile(data.deedOfSale, userInfo),
														)
													}
												>
													<Visibility fontSize='inherit' />
												</IconButton>
											</>
										)}
										{data.deedOfSale && (
											<IconButton
												aria-label='delete'
												size='small'
												onClick={() => {
													handleFileRemove('deedOfSale')
												}}
											>
												<Delete fontSize='inherit' color={!isStringImage(data.deedOfSale) ? 'action' : 'error'} />
											</IconButton>
										)}
									</>
								}
							>
								<ListItemText className={classes.listItemText} primary='Escritura de venta' />
							</ListItem>
							<Divider variant='fullWidth' component='li' />
							<ListItem
								className={classes.listItem}
								secondaryAction={
									<>
										{!data.blueprintEA ? (
											<IconButton aria-label='add' size='small' onClick={() => selectFileHandler(blueprintEA)}>
												<InsertDriveFile fontSize='inherit' />
											</IconButton>
										) : (
											<>
												<IconButton
													aria-label='visibility'
													size='small'
													onClick={() =>
														window.open(
															typeof data.blueprintEA === 'object'
																? URL.createObjectURL(data.blueprintEA)
																: isValidUrl(data.blueprintEA)
																? data.blueprintEA
																: data.blueprintEA.startsWith('/')
																? getUrlToOpenFile(data.blueprintEA, userInfo)
																: `${axios.defaults.baseURL}/${data.blueprintEA}?code=${generateTokenCode(
																		userInfo.token,
																  )}`,
														)
													}
												>
													<Visibility fontSize='inherit' />
												</IconButton>
											</>
										)}
										{data.blueprintEA && (
											<IconButton
												aria-label='delete'
												w
												size='small'
												onClick={() => {
													handleFileRemove('blueprintEA')
												}}
											>
												<Delete fontSize='inherit' color={!isStringImage(data.blueprintEA) ? 'action' : 'error'} />
											</IconButton>
										)}
									</>
								}
							>
								<ListItemText className={classes.listItemText} primary='Plano de estado reformado' />
							</ListItem>
						</List>
					</Demo>
				</Grid>
			</Grid>
			<TransparencyRefs
				handleFileChange={handleFileChange}
				deedOfPurchaseFile={deedOfPurchaseFile}
				simpleNotePriorToPurchaseFile={simpleNotePriorToPurchaseFile}
				simpleNotePurchasedPropertyFile={simpleNotePurchasedPropertyFile}
				acquisitionExpensesFile={acquisitionExpensesFile}
				billsOfExpensesFile={billsOfExpensesFile}
				deedOfSaleFile={deedOfSaleFile}
				simplePdfFile={simplePdfFile}
				blueprintEA={blueprintEA}
			/>
		</Box>
	)
}

export default TransparencyList
