import { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GridItem from 'components/Grid/GridItem'
import ReactVirtualizedTable from 'components/ReactVirtualizedTable/ReactVirtualizedTable'
import { openModal, closeModal } from 'shared/helpers/modal-helper'
import { matchCurrencyToDecimal } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import { INVESTMENTS_BY_PROJECT_RESET } from 'local_redux/constants/investmentConstants'
import UpdateProjectModal from './UpdateProjectModal'
import FinishedDocumentValidationModal from './FinishedDocumentValidationModal'
import { LiquidatedProjectContext } from 'contexts/liquidatedProjectContext'
import { calculateSum, returValue } from 'shared/helpers/functions-helpers'
import { liquidatedProjectColumns } from '../helpers/liquidatedProjectColumns'
import { liquidatedProjectMapper } from '../mappers/liquidatedProjectMapper'

const ProjectListTable = ({ setExpanded, openAlert }) => {
	const dispatch = useDispatch()

	const {
		loadingProjectFinishedList,
		errorProjectFinishedList,
		successProjectFinishedList,
		projectFinishedListData,
		setSelectedProject,
		liquidatedProjectInitialState,
		projectList,
		setProjectList,
		updateProjectModal,
		setUpdateProjectModal,
		setUpdateProjectInfo,
	} = useContext(LiquidatedProjectContext)

	const [finishedDocumentValidationModal, setFinishedDocumentValidationModal] = useState(false)
	const [finishedDocumentValidationModalInfo, setFinishedDocumentValidationModalInfo] = useState({})
	const { successInvestmentsByProject } = useSelector((state) => state.investmentsByProject)

	useEffect(() => {
		if (projectFinishedListData) {
			setProjectList(liquidatedProjectMapper(projectFinishedListData))
		}
	}, [projectFinishedListData])

	const handleCloseFinishedDocumentValidationModal = () => {
		closeModal(setFinishedDocumentValidationModalInfo, setFinishedDocumentValidationModal)
	}
	const openUpdateProjectModal = (item) => {
		openModal(item, setUpdateProjectInfo, setUpdateProjectModal)
	}
	const handleSelectProject = (item) => {
		if (successInvestmentsByProject) {
			dispatch({ type: INVESTMENTS_BY_PROJECT_RESET })
		}

		if (!item.endofprojectdocumentsList.length > 0) {
			openModal(item, setFinishedDocumentValidationModalInfo, setFinishedDocumentValidationModal)
		}

		let data = { ...item, ...liquidatedProjectInitialState }
		const eraserHeadProject = item.eraserhead?.length > 0 && { ...item.eraserhead[0] }
		delete eraserHeadProject._id
		const {
			liquidityProvider,
			marketingExpenses,
			plusvalia,
			interiorDesignExpenses,
			findersManagementFee,
			purchasePrice,
			maintenanceExpenses,
			constructionExpenses,
			acquisitionExpenses,
			investments,
		} = eraserHeadProject

		data.liquidityProvider = returValue(liquidityProvider)
		data.marketingExpenses = returValue(marketingExpenses)
		data.plusvalia = returValue(plusvalia)
		data.interiorDesignExpenses = returValue(interiorDesignExpenses)
		data.findersManagementFee = returValue(findersManagementFee)
		data.purchasePrice = returValue(purchasePrice)
		data.maintenanceExpenses = returValue(maintenanceExpenses)
		data.constructionExpenses = returValue(constructionExpenses)
		data.acquisitionExpenses = returValue(acquisitionExpenses)
		data.investments = investments

		data.totalInvestmentBeforeExpenses = calculateSum([
			data.interiorDesignExpenses,
			data.purchasePrice,
			data.maintenanceExpenses,
			data.acquisitionExpenses,
			data.constructionExpenses,
		])

		data.totalInvestment = calculateSum([data.totalInvestmentBeforeExpenses, data.findersManagementFee])

		data.netSellingPrice =
			data.liquidityProvider &&
			calculateSum([
				-data.liquidityProvider,
				matchCurrencyToDecimal(item.projectSaleValueUpdated),
				-data.marketingExpenses,
				-data.plusvalia,
			])

		data.benefit = data.netSellingPrice && calculateSum([data.netSellingPrice, -data.totalInvestment])

		data.profitMargin = data.netSellingPrice
			? Number(
					(calculateSum([data.netSellingPrice, -data.totalInvestment]) /
						calculateSum([data.totalInvestmentBeforeExpenses, data.findersManagementFee])) *
						100,
			  ).toFixed(2)
			: 0

		setSelectedProject({
			...data,
			salePriceFinished: matchCurrencyToDecimal(item.projectSaleValueUpdated),
		})

		setExpanded(false)
	}

	return (
		<GridItem xs={12} style={{ marginBottom: '10px', marginTop: '10px' }}>
			<ReactVirtualizedTable
				columns={liquidatedProjectColumns({ handleSelectProject, openUpdateProjectModal })}
				data={projectList}
				loading={Boolean(loadingProjectFinishedList)}
				success={Boolean(successProjectFinishedList)}
				error={errorProjectFinishedList}
				emptyText={'No se encontraron proyectos'}
			/>
			{finishedDocumentValidationModal && !openAlert && (
				<FinishedDocumentValidationModal
					finishedDocumentValidationModal={finishedDocumentValidationModal}
					handleCloseFinishedDocumentValidationModal={handleCloseFinishedDocumentValidationModal}
					finishedDocumentValidationModalInfo={finishedDocumentValidationModalInfo}
				/>
			)}
			{updateProjectModal && <UpdateProjectModal />}
		</GridItem>
	)
}

export default ProjectListTable
