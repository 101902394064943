import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { Assignment } from '@mui/icons-material'
import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import ReactVirtualizedTable from 'components/ReactVirtualizedTable/ReactVirtualizedTable'
import OwnerUserDeleteModal from './components/ownerUserDeleteModal'
import OwnerUserAndProfilesDetail from './components/ownerUserAndProfilesDetail'
import { ownersUsersListColumns } from './helpers/ownersUsersListColumns'
import { ownersUsersListMapper } from './mappers/ownersUsersListMapper'
import { OWNER_LIST_RESET } from 'local_redux/constants/ownersProfileConstants'
import { getAllUsersOwners } from 'local_redux/actions/ownerActions'
import styles from './styles/ownerUsersListScreenStyles'

const useStyles = makeStyles(styles)

const OwnersUsersListScreen = () => {
	const search = useLocation().search
	const ownerIdQuery = new URLSearchParams(search).get('ownerId')
	const dispatch = useDispatch()
	const classes = useStyles()
	const navigate = useNavigate()

	const [data, setData] = useState([])
	const [showDeleteUserInfo, setShowDeleteUserInfo] = useState({})
	const [deleteUserModal, setDeleteUserModal] = useState(false)
	const [idOwnerUser, setIdOwnerUser] = useState('')

	const { loadingOwnerGetUsers, successOwnerGetUsers, ownerGetUsersData, errorOwnerGetUsersData } = useSelector(
		({ ownersGetUsers }) => ownersGetUsers,
	)
	const { successUserUpdate, userUpdated } = useSelector(({ userUpdate }) => userUpdate)
	const { successUserDelete } = useSelector(({ userDelete }) => userDelete)
	useEffect(() => {
    dispatch(getAllUsersOwners())
	}, [])
	useEffect(() => {
    if (successOwnerGetUsers) {
      setData(ownersUsersListMapper(ownerGetUsersData))
		}
	}, [successOwnerGetUsers])
	useEffect(() => {
		if (successUserUpdate) {
			const userUpdate = data.map((user) => {
				if (user.id === userUpdated._id) {
					user.email = userUpdated.email
					user.name = userUpdated.name
					user.lastName = userUpdated.lastName
				}
				return user
			})
			setData(userUpdate)
		}
		if (successUserDelete) {
			if (idOwnerUser) {
				const investor = data.filter((data) => data.id !== idOwnerUser)
				setData(investor)
			}
		}
	}, [successUserDelete, successUserUpdate])
	useEffect(() => {
		return () => dispatch({ type: OWNER_LIST_RESET })
	}, [dispatch])

	const showDeleteInfoHandler = (id) => {
		const user = ownerGetUsersData.find((user) => user._id === id)
		setShowDeleteUserInfo(user)
		setDeleteUserModal(true)
	}
	const handleCloseDeleteUserModal = () => {
		setShowDeleteUserInfo({})
		setDeleteUserModal(false)
	}

	return (
		<>
			{ownerIdQuery ? (
				<OwnerUserAndProfilesDetail />
			) : (
				<GridContainer>
					<GridItem xs={12} className={classes.addButtonContainer}>
						<Button color='primary' onClick={() => navigate('/admin/owners-register-user')}>
							Nuevo Propietario
						</Button>
					</GridItem>
					<GridItem xs={12} className={classes.rootItem}>
						<Card>
							<>
								<CardHeader color='primary' icon>
									<CardIcon color='primary'>
										<Assignment />
									</CardIcon>
									<h4 className={classes.cardIconTitle}>Propietarios</h4>
								</CardHeader>
								<CardBody>
									<ReactVirtualizedTable
										data={data}
										columns={ownersUsersListColumns({ navigate, showDeleteInfoHandler, setIdOwnerUser })}
										loading={Boolean(loadingOwnerGetUsers)}
										success={Boolean(successOwnerGetUsers)}
										error={errorOwnerGetUsersData}
									/>
								</CardBody>
							</>
						</Card>
					</GridItem>
				</GridContainer>
			)}
			{deleteUserModal && (
				<OwnerUserDeleteModal
					handleCloseDeleteUserModal={handleCloseDeleteUserModal}
					deleteUserModal={deleteUserModal}
					showDeleteUserInfo={showDeleteUserInfo}
				/>
			)}
		</>
	)
}

export default OwnersUsersListScreen
