import { matchPersonType } from 'shared/matchData/matchData'

export const excelMapper = ({ filteredRows }) => {
	return filteredRows?.map((item) => {
		return {
			businessName: item?.businessName || '',
			personType: item?.personType || '',
			signedContract: item?.prescriptorContractUrl ? 'Firmado' : 'No firmado',
			prescriberProfiles: item?.prescriberProfiles || '',
		}
	})
}

export const prescriberListMappers = (prescribers) => {
	return prescribers?.map((item) => {
		return {
			id: item._id,
			businessName: item.businessName,
			personType: matchPersonType(item.personType),
			signedContract: item.prescriptorContractUrl,
			prescriberProfiles: item.prescriberProfiles.length,
			prescriptorContractUrl: item.prescriptorContractUrl,
		}
	})
}
