const TransparencyRefs = ({
  simplePdfFile,
  deedOfPurchaseFile,
  simpleNotePriorToPurchaseFile,
  simpleNotePurchasedPropertyFile,
  acquisitionExpensesFile,
  billsOfExpensesFile,
  deedOfSaleFile,
  handleFileChange,
  officialVideoFile,
  blueprintEA,
}) => {
  return (
    <>
      <div className='fileinput'>
        <input
          type='file'
          name='projectPdf'
          accept='.pdf,'
          id='simple-pdf'
          ref={simplePdfFile}
          onChange={handleFileChange}
        />
      </div>
      <div className='fileinput'>
        <input
          type='file'
          name='deedOfPurchase'
          accept='.pdf,'
          id='deedOfPurchase'
          ref={deedOfPurchaseFile}
          onChange={handleFileChange}
        />
      </div>
      <div className='fileinput'>
        <input
          type='file'
          name='simpleNotePriorToPurchase'
          accept='.pdf,'
          id='simpleNotePriorToPurchase'
          ref={simpleNotePriorToPurchaseFile}
          onChange={handleFileChange}
        />
      </div>
      <div className='fileinput'>
        <input
          type='file'
          name='simpleNotePurchasedProperty'
          accept='.pdf,'
          id='simpleNotePurchasedProperty'
          ref={simpleNotePurchasedPropertyFile}
          onChange={handleFileChange}
        />
      </div>
      <div className='fileinput'>
        <input
          type='file'
          name='acquisitionExpenses'
          accept='.pdf,'
          id='acquisitionExpenses'
          ref={acquisitionExpensesFile}
          onChange={handleFileChange}
        />
      </div>

      <div className='fileinput'>
        <input
          type='file'
          name='billsOfExpenses'
          accept='.pdf,'
          id='billsOfExpenses'
          ref={billsOfExpensesFile}
          onChange={handleFileChange}
        />
      </div>
      <div className='fileinput'>
        <input
          type='file'
          name='deedOfSale'
          accept='.pdf,'
          id='deedOfSale'
          ref={deedOfSaleFile}
          onChange={handleFileChange}
        />
      </div>
      <div className='fileinput'>
        <input
          type='file'
          name='officialVideo'
          accept='.mp4,.mkv'
          id='officialVideo'
          ref={officialVideoFile}
          onChange={handleFileChange}
        />
      </div>
      <div className='fileinput'>
        <input
          type='file'
          name='blueprintEA'
          accept='.pdf,'
          id='blueprintEA'
          ref={blueprintEA}
          onChange={handleFileChange}
        />
      </div>
    </>
  )
}

export default TransparencyRefs
