import { useSelector } from 'react-redux'
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import GridContainer from 'components/Grid/GridContainer'
import Button from 'components/CustomButtons/Button'
import GridItem from 'components/Grid/GridItem'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'
import styles from '../styles/videoProjectStyles'

const useStyles = makeStyles(styles)

const VideoProjectModal = ({ setOpenVideo, openVideo, dataVideo }) => {
	const classes = useStyles()

	const { userInfo } = useSelector((state) => state.userLogin)

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={openVideo}
			keepMounted
			onClose={() => setOpenVideo(false)}
			aria-labelledby='investment-modal-download-title'
			aria-describedby='investment-modal-download-description'
		>
			<DialogTitle id='investment-modal-download-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={() => setOpenVideo(false)}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4>
					Video del Proyecto <strong>{dataVideo.projectAddress}</strong>
				</h4>
			</DialogTitle>
			<DialogContent id='investment-modal-download-description' className={classes.modalBody}>
				<GridContainer>
					<GridItem xs={12} justifyContent='center'>
						<video
							controls
							style={{
								width: '100%',
								overflow: 'hidden',
								objectFit: 'cover',
								borderRadius: '3px',
								marginBottom: '5px',
							}}
						>
							{/* <source src={`${axios.defaults.baseURL}/${dataVideo.coverVideo}`} type='video/mp4' />
              <source src={`${axios.defaults.baseURL}/${dataVideo.coverVideo}`} type='video/mkv' /> */}
							<source src={getUrlToOpenFile(dataVideo.coverVideo, userInfo)} type='video/mp4' />
							<source src={getUrlToOpenFile(dataVideo.coverVideo, userInfo)} type='video/mkv' />
						</video>
					</GridItem>
				</GridContainer>
			</DialogContent>
			<DialogActions className={classes.modalFooter}>
				<GridContainer>
					<GridItem xs={12} style={{ marginTop: '50px' }}>
						<Button color='primary' onClick={() => setOpenVideo(false)}>
							Salir
						</Button>
					</GridItem>
				</GridContainer>
			</DialogActions>
		</Dialog>
	)
}

export default VideoProjectModal
