import { differenceInDays } from 'date-fns'
import { matchProjectStatus } from 'shared/matchData/matchData'

export const projectListMapper=({project})=>{
  return project.map((item) => {
    const finishDateUpdated = differenceInDays(new Date(item.finishDateUpdated), new Date()) + 1
    return {
      id: item._id,
      projectAddress: item.projectAddress.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
      projectCost: `${item.projectCost}`,
      completionWarningText: 
        item.status !== 'finished' &&
        item.status !== 'liquidated' &&
        finishDateUpdated < 15 &&
        finishDateUpdated > 0
          ? 'Faltan menos de 15 días para el fin.'
          : item.status !== 'finished' && item.status !== 'liquidated' && finishDateUpdated < 0
          ? 'Ya ha pasado la fecha establecida y el proyecto aún no ha finalizado.'
          : false,
      status: matchProjectStatus(item.status),
      item: item,
    }
  })
}