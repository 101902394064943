import { useState, useEffect } from 'react'
import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { investorsProfileByUser } from 'local_redux/actions/investorProfileActions'
import styles from '../styles/filterInvestmentStyles'

const useStyles = makeStyles(styles)

const FilterProfile = ({ filter, filterP, setFilterP }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [profiles, setProfiles] = useState([])

  const { successInvestorsProfileByUser, investorsProfileByUserData } = useSelector(
    (state) => state.investorsProfileByUser
  )
  const { userInfo } = useSelector((state) => state.userLogin)

  useEffect(() => {
    if (successInvestorsProfileByUser) {
      setProfiles(investorsProfileByUserData)
    }
  }, [successInvestorsProfileByUser])
  useEffect(() => {
    dispatch(investorsProfileByUser(userInfo?._id))
  }, [])
  
  return (   
    <FormControl fullWidth>
      <InputLabel id='log-type-select'>{!filterP ? 'Buscar por perfil' : `Perfil`}</InputLabel>
      <Select
        MenuProps={{
          className: classes.selectMenu,
        }}
        labelId='log-type-select'
        id='log-type'
        label='Tipo *'
        value={filterP}
        onChange={(e) => {
          setFilterP(e.target.value)
        }}
        disabled={filter === ''}
      >
        {[{ businessName: 'Todos', _id: '0' }, ...profiles].map((profile, index) => (
          <MenuItem
            value={profile._id}
            key={index}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
          >
            {profile.businessName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>    
  )
}

export default FilterProfile
