import { useState, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { differenceInCalendarDays, format } from 'date-fns'
import { Table, TableBody, TableContainer, Paper } from '@mui/material/'
import { makeStyles } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import GridItem from 'components/Grid/GridItem'
import Loader from 'components/Loader/Loader'
import GridContainer from 'components/Grid/GridContainer'
import InvestmentsTableHeaders from './InvestmentsTableHeaders'
import InvestmentTableBody from './InvestmentTableBody'
import DownloadInvestmensExcel from './DownloadInvesnmentsExcel'
import { replaceStringToNum, matchNumberToCurrency } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import { changeIncomeReciveDate, handleBenefit12, calculateToTIR } from 'shared/helpers/functions-helpers'
import { calculateRetentionAmount } from '../functions'
import { calculateToPay } from 'shared/helpers/functions-helpers'
import { LiquidatedProjectContext } from 'contexts/liquidatedProjectContext'
import { INVESTMENTS_BY_PROJECT_RESET } from 'local_redux/constants/investmentConstants'
import { ERASER_HEAD_PROJECT_REGISTER_RESET } from 'local_redux/constants/eraserHeadProject'
import { getInvestmentsByProject } from 'local_redux/actions/investmentActions'
import styles from '../styles/investmentsTableStyles'

const InvestmentsTable = ({ tirCoinvestor }) => {
	const dispatch = useDispatch()
	const useStyles = makeStyles(styles)
	const classes = useStyles()

	const {
		resetState,
		setSelectedProject,
		selectedProject,
		investmentsBySelectedProject,
		setInvestmentsBySelectedProject,
	} = useContext(LiquidatedProjectContext)

	const [openDatePicker, setOpenDatePicker] = useState(false)

	const { investmentsByProject, successInvestmentsByProject, loadingInvestmentsByProject } = useSelector(
		(state) => state.investmentsByProject,
	)

	useEffect(() => {
		if (selectedProject._id) {
			dispatch(getInvestmentsByProject(selectedProject._id))
			setSelectedProject({ ...selectedProject, depositDate: new Date(selectedProject.finishDateUpdated) })
		}
	}, [selectedProject._id])

	useEffect(() => {
		if (successInvestmentsByProject) {
			const investmentData = investmentsByProject.map((item) => {
				const eraser = selectedProject.investments
					? selectedProject.investments.find((investment) => investment.investmentId === item._id)
					: undefined

				const benefit12 = handleBenefit12(
					differenceInCalendarDays(new Date(selectedProject.depositDate), new Date(item.incomeRecivedDate)),
					replaceStringToNum(item.amount),
				)

				const beforeFee = benefit12 * 0.0287248560013554 + benefit12
				const fee = (beforeFee - benefit12) * 0.5
				const retentionFromProfile = item.investorProfile.retention ? item.investorProfile.retention : '19%'

				const obj = {
					...item,
					coInvestorTIR: eraser ? eraser.coInvestorTIR : '',
					projectTIR: eraser ? eraser.projectTIR : '',
					csvProfileInclude: eraser ? eraser.csvProfileInclude : true,
					benefit: eraser ? Number(eraser.benefit) : '',
					reversion: eraser ? Number(eraser.reversion) : '',
					retention: retentionFromProfile,
					liquidation:
						eraser && eraser.liquidation
							? eraser.liquidation
							: item.investorProfile.liquidation
							? item.investorProfile.liquidation
							: 'pay',
					toPay: eraser
						? calculateToPay(
								item.investorProfile?.retention || '19%',
								eraser?.benefit || 0,
								item.amount,
								eraser?.liquidation,
						  )
						: calculateToPay(
								item.investorProfile?.retention || '19%',
								eraser?.benefit || 0,
								item.amount,
								item?.investorProfile?.liquidation,
						  ),
					liquidationDeleted: eraser ? eraser.isDeleted : false,
					retentionAmount:
						eraser && eraser.benefit
							? calculateRetentionAmount(retentionFromProfile, Number(eraser.benefit))
							: matchNumberToCurrency(0),
					investorTIR:
						eraser && item.incomeRecivedDate
							? calculateToTIR(
									changeIncomeReciveDate(item.incomeRecivedDate, selectedProject.startDateUpdated),
									selectedProject.depositDate,
									replaceStringToNum(item.amount) + Number(eraser.benefit) || 0,
									item.amount,
							  )
							: '',
					benefitForIRR: matchNumberToCurrency(benefit12),
					taxBase:
						eraser && eraser.taxBase
							? parseFloat(eraser.taxBase)
							: eraser && eraser.benefit
							? parseFloat((benefit12 - Number(eraser.benefit)).toFixed(2))
							: '',
					feeRate: eraser && eraser.feeRate ? eraser.feeRate : 0.5,
					fee: eraser && eraser.fee ? parseFloat(eraser.fee) : parseFloat(fee.toFixed(2)),
				}

				return obj
			})

			setInvestmentsBySelectedProject(investmentData)
		}
	}, [successInvestmentsByProject])

	useEffect(() => {
		return () => {
			dispatch({ type: INVESTMENTS_BY_PROJECT_RESET })
			dispatch({ type: ERASER_HEAD_PROJECT_REGISTER_RESET })
			resetState()
		}
	}, [dispatch])

	const handleDepositDate = (date) => {
		const data = investmentsBySelectedProject.map((item) => {
			const benefit12 = handleBenefit12(
				differenceInCalendarDays(new Date(date), new Date(item.incomeRecivedDate)),
				replaceStringToNum(item.amount),
			)

			const beforeFee = benefit12 * 0.0287248560013554 + benefit12
			const fee = (beforeFee - benefit12) * 0.5

			item.investorTIR =
				item.investorTIR > 0 &&
				calculateToTIR(
					changeIncomeReciveDate(item.incomeRecivedDate, selectedProject.startDateUpdated),
					date,
					replaceStringToNum(item.amount) + Number(item.benefit),
					item.amount,
				)
			item.benefitForIRR = matchNumberToCurrency(benefit12)
			item.fee = item.incomeRecivedDate ? parseFloat(fee.toFixed(2)) : 0
			return item
		})

		setSelectedProject({ ...selectedProject, depositDate: date })
		setInvestmentsBySelectedProject(data)
	}
	return (
		<GridItem xs={12} className={classes.ProjectTableGridItem}>
			{investmentsBySelectedProject.length > 0 && (
				<>
					<GridContainer style={{ display: 'flex', justifyContent: 'end', padding: '16px', marginLeft: '4px' }}>
						<GridItem xs={5} style={{ display: 'flex', justifyContent: 'end' }}>
							<DownloadInvestmensExcel
								data={investmentsBySelectedProject}
								excelName={`Inversiones a liquidar ${selectedProject.projectAddress} ${format(
									new Date(),
									'dd-MM-yyyy',
								)}`}
							/>
						</GridItem>
					</GridContainer>

					<DatePicker
						open={openDatePicker}
						onOpen={() => setOpenDatePicker(true)}
						onClose={() => setOpenDatePicker(false)}
						label='Fecha de Devolución'
						value={selectedProject.depositDate}
						onChange={handleDepositDate}
						TextFieldComponent={() => null}
					/>
					{loadingInvestmentsByProject ? (
						<GridItem xs={12} className={classes.LoaderContainer}>
							<Loader
								message={'Cargando inversiones del proyecto'}
								config={{ marginTop: '30px', alignItems: 'center' }}
							/>
						</GridItem>
					) : (
						<TableContainer component={Paper}>
							<Table sx={{ minWidth: 650 }} aria-label='simple table'>
								<InvestmentsTableHeaders setOpenDatePicker={setOpenDatePicker} classes={classes} />
								<TableBody>
									<InvestmentTableBody tirCoinvestor={tirCoinvestor} />
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</>
			)}
		</GridItem>
	)
}

export default InvestmentsTable
