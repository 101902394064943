import { useNavigate } from 'react-router-dom'
import InfoIcon from '@mui/icons-material/Info'
import { Card, CardContent } from '@material-ui/core'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

const AlertDni = ({ text }) => {
  const navigate = useNavigate()

  return (
    <Stack
      sx={{
        position: 'fixed',
        bottom: 10,
        left: 8,
        right: { xs: 8 },
        maxWidth: '430px',
        zIndex: 9999,
        cursor: 'pointer',
      }}
      onClick={() => navigate('/admin/user-page')}
    >
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardContent>
              <Alert
                variant='standard'
                icon={<InfoIcon />}
                sx={{ border: '1px solid #cbc6ba', color: 'black', backgroundColor: 'white' }}
              >
                <b>{text}</b>
              </Alert>
            </CardContent>
          </Card>
        </GridItem>
      </GridContainer>
    </Stack>
  )
}

export default AlertDni
