export const pdlLiquidateScreenTableMapper = (projects) =>
	projects.map((item) => ({
		id: item._id,
		item: item,
		projectAddress: item.projectAddress,
		pdlTotalInversion: Number(item?.pdlTotalInversion)
			? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item.pdlTotalInversion)
			: '0,00 €',
		actions: '',
	}))
