export const ownersUsersListMapper = (ownerUsers) => {
	return ownerUsers?.map((item) => {
		return {
			id: item?._id,
			businessName: `${item?.name} ${item?.lastName}`.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
			email: item?.email,
			totalPages: item?.totalPages,
			model: 'profile',
		}
	})
}
