const styles = {
  cardRoot: {
    backgroundColor: '#ededed !important',
    borderRadius: '0px !important',
    boxShadow: '0 1px 4px 0 rgb(0 0 0 / 14%)',
    fontSize: '.875rem',
    padding: '0.9375rem 20px !important',
  },
  cardTitle: {
    padding: '0px 8px 8px',
  },
  listItem: {
    padding: '8px 16px 8px 16px !important',
  },
  listItemText: {
    '& span': {
      fontSize: '1rem',
    },
  },
  listItemTextError: {
    '& span': {
      fontSize: '1rem',
      color: '#d44950',
    },
  },
}

export default styles
