import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NotificationImportant } from '@mui/icons-material'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import CardBody from 'components/Card/CardBody'
import ReactVirtualizedTable from 'components/ReactVirtualizedTable/ReactVirtualizedTable'
import { notificationsDelayedListColumns } from './helpers/notificationsDelayedListColumns'
import { notificationsDelayedListMapper } from './mappers/notificationsDelayedListMapper'
import { getNotificationsList } from 'local_redux/actions/notificationActions'

const NotificationsDelayedListScreen = () => {
	const dispatch = useDispatch()

	const [data, setData] = useState()

	const {
		loadingNotificationsDelayed,
		successNotificationsDelayed,
		errorNotificationsDelayed,
		notificationsDelayedList,
	} = useSelector(({ notificationsDelayed }) => notificationsDelayed)

	useEffect(() => {
		dispatch(getNotificationsList())
	}, [])
	useEffect(() => {
		if (successNotificationsDelayed) {
			setData(notificationsDelayedListMapper(notificationsDelayedList))
		}
	}, [successNotificationsDelayed])

	return (
		<GridContainer>
			<GridItem xs={12}>
				<Card>
					<CardHeader color='primary' icon>
						<CardIcon color='primary'>
							<NotificationImportant />
						</CardIcon>
					</CardHeader>
					<CardBody>
						<ReactVirtualizedTable
							data={data}
							columns={notificationsDelayedListColumns()}
							loading={Boolean(loadingNotificationsDelayed)}
							success={Boolean(successNotificationsDelayed)}
							error={errorNotificationsDelayed}
						/>
					</CardBody>
				</Card>
			</GridItem>
		</GridContainer>
	)
}

export default NotificationsDelayedListScreen
