import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, makeStyles, DialogActions } from '@material-ui/core'
import Typography from '@mui/material/Typography'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { LiquidatedProjectContext } from 'contexts/liquidatedProjectContext'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'
import styles from '../styles/liquidatedProjectModalStyles'

const useStyles = makeStyles(styles)

const LiquidatedProjectModal = () => {
	const classes = useStyles()

	const { resetState, liquidatedProjectModal, liquidatedProjectModalInfo, handleCloseLiquidatedProjectModal } =
		useContext(LiquidatedProjectContext)

	const { userInfo } = useSelector((state) => state.userLogin)

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={liquidatedProjectModal}
			keepMounted
			onClose={handleCloseLiquidatedProjectModal}
			aria-labelledby='liquidated-project-title'
			aria-describedby='liquidated-project-description'
		>
			<DialogTitle id='liquidated-project-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={handleCloseLiquidatedProjectModal}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4>Proyecto liquidado correctamente</h4>
			</DialogTitle>

			<DialogContent id='user-modal-liquidated-description' className={classes.modalBody}>
				<GridContainer style={{ marginBottom: '2rem' }}>
					<GridItem xs={12}>
						{!liquidatedProjectModalInfo.csvFilePath ? (
							<Typography>
								Proyecto liquidado correctamente, como no hay inversiones a pagar no se han generado csv.
							</Typography>
						) : (
							<Typography>
								Proyecto liquidado correctamente, para descargar el csv del mismo haga click en el botón de descargar o
								puede descargarlo mas tarde desde la lista de proyectos.
							</Typography>
						)}
					</GridItem>
				</GridContainer>
				<DialogActions>
					<GridContainer>
						<GridItem xs={!liquidatedProjectModalInfo.csvFilePath ? 12 : 6}>
							<Button
								block
								onClick={() => {
									handleCloseLiquidatedProjectModal()
									resetState()
								}}
							>
								{!liquidatedProjectModalInfo.csvFilePath ? 'Cerrar' : 'Cancelar'}
							</Button>
						</GridItem>
						{liquidatedProjectModalInfo.csvFilePath && (
							<GridItem xs={6}>
								<a
									href={getUrlToOpenFile(liquidatedProjectModalInfo.csvFilePath, userInfo)}
									target='_blank'
									rel='noopener noreferrer'
								>
									<Button
										block
										onClick={() => {
											handleCloseLiquidatedProjectModal()
											resetState()
										}}
									>
										Descargar
									</Button>
								</a>
							</GridItem>
						)}
					</GridContainer>
				</DialogActions>
			</DialogContent>
		</Dialog>
	)
}

export default LiquidatedProjectModal
