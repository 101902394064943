import CustomInput from 'components/CustomInput/CustomInput'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

const InputsLegalProfile = ({ userInfo, setUserInfo }) => {
  return (
    <GridItem xs={12}>
      <GridContainer>
        <GridItem xs={12}>
          <CustomInput
            labelText='Razón Social *'
            id='investor-businessName'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: userInfo.businessName,
              onChange: (e) => {
                setUserInfo({ ...userInfo, businessName: e.target.value })
              },
              type: 'text',
              required: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <CustomInput
            labelText='CIF *'
            id='investor-cif'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: userInfo.cif,
              onChange: (e) => {
                setUserInfo({ ...userInfo, cif: e.target.value, nif: e.target.value })
              },
              type: 'text',
              required: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <CustomInput
            labelText={'Email  *'}
            id='investor-email'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: userInfo.businessEmail,
              onChange: (e) => {
                setUserInfo({ ...userInfo, businessEmail: e.target.value })
              },
              type: 'email',
              required: true,
            }}
          />
        </GridItem>

        <GridItem xs={12}>
          <CustomInput
            labelText={'Dirección *'}
            id='investor-address'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: userInfo.businessAddress,
              onChange: (e) => {
                setUserInfo({ ...userInfo, businessAddress: e.target.value })
              },
              type: 'text',
              required: true,
            }}
          />
        </GridItem>
      </GridContainer>
    </GridItem>
  )
}

export default InputsLegalProfile
