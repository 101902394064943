import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle'
import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle'
const styles = (theme) => ({
	dialogContent: {
		overflow: 'auto',
		maxWidth: '100vw',
		maxHeight: '70vh',
	},
	subTitle: {
		marginTop: '20px',
	},
	spanTitle: {
		margin: 10,
	},
	spanError: {
		color: 'red',
	},
	hoverCell: {
		'&:hover': {
			color: 'black',
			fontWeight: '400',
		},
	},
	...customSelectStyle,
	pagPosition: {
		position: 'sticky',
		top: '0',
		left: '0',
	},
	inputFormat: {
		background: 'transparent',
		border: 'none',
		borderBottom: '2px solid #c0bbac',
	},
	gridContainer: {
		justifyContent: 'center',
	},
	trWidthBig: {
		minWidth: '100% !important',
	},
	widthBig: {
		minWidth: '170px !important',
	},
	confirmBtnCssClass: {
		backgroundColor: '#ebe6e2',
		color: '#333333',
		padding: '10px',
		width: '5rem',
		borderRadius: '3px',
		'&:hover': {
			color: '#333333',
		},
	},
	...modalStyle(theme),
})

export default styles
