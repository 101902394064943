import { TableCell, TableHead, TableRow } from '@mui/material/'

const InvestmentsTableHeaders = ({ classes, setOpenDatePicker }) => {
  return (
    <TableHead>
      <TableRow className={classes.tableRow}>
        <TableCell className={classes.table}>Incluir</TableCell>
        <TableCell className={classes.table}>Perfil Inversor</TableCell>
        <TableCell className={classes.table}>IBAN</TableCell>
        <TableCell className={classes.table} align='right'>
          Fecha de inversión
        </TableCell>
        <TableCell className={classes.table} align='right'>
          <span>Fecha de devolución</span>

          <span onClick={() => setOpenDatePicker(true)} className={classes.ChangeValue + ' ' + classes.ChangeValueFont}>
            Cambiar
          </span>
        </TableCell>
        <TableCell className={classes.table} align='right'>
          Importe de inversión
        </TableCell>
        <TableCell className={classes.table} align='right'>
          Liquidación
        </TableCell>
        <TableCell className={classes.table} align='right'>
          Participación
        </TableCell>
        <TableCell className={classes.table} align='right'>
          Retención
        </TableCell>
        <TableCell className={classes.table} align='right'>
          Reversión
        </TableCell>
        <TableCell className={classes.table} align='right'>
          Beneficio
        </TableCell>
        <TableCell className={classes.table} align='right'>
          Importe de retención
        </TableCell>
        <TableCell className={classes.table} align='right'>
          TIR Inversor
        </TableCell>
        <TableCell
          className={classes.table}
          align='right'
          style={{
            minWidth: 70,
            paddingLeft: 0,
          }}
        >
          A pagar
        </TableCell>
        <TableCell className={classes.table} align='right'>
          Días
        </TableCell>
        <TableCell className={classes.table} align='right'>
          Beneficio para TIR =12%
        </TableCell>
        <TableCell className={classes.table} align='right'>
          Base imponible para Success Fee
        </TableCell>
        <TableCell className={classes.table} align='right'>
          Fee tasa (50%)
        </TableCell>
        <TableCell className={classes.table} align='right'>
          Fee €
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export default InvestmentsTableHeaders
