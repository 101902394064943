import { createRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, makeStyles, Typography, Badge } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import ModalConfirmationInvestment from 'components/ModalConfirmationInvestment/ModalConfirmationInvestment'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import InputsLegalPerson from './InputsLegalProfile'
import InputsPhysicalPerson from './InputsPhysicalProfile'
import { getInvestorsProfileList } from 'local_redux/actions/investorProfileActions'
import { matchPersonType } from 'shared/matchData/matchData'
import { INVESTOR_PROFILE_BY_ADMIN_REGISTER_RESET } from 'local_redux/constants/investorProfileConstants'
import { getPersonTypeLabel } from 'shared/helpers/text-helpers'
import styles from '../styles/ProfileCreateModalStyle'

const useStyles = makeStyles(styles)

const ProfileCreateModal = ({ createModal, handleOpenClose, handleCreateProfile, setData, data, initialState }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const fileInput = createRef()

  const [personType, setPersonType] = useState('')
  const [alert, setAlert] = useState(null)
  const [errorTaxResidenceFile, setErrorTaxResidenceFile] = useState('')
  const [prescribers, setPrescribers] = useState([])

  const {
    errorInvestorProfileByAdminRegister,
    loadingInvestorProfileByAdminRegister,
    successInvestorProfileByAdminRegister,
  } = useSelector((state) => state.investorProfileByAdminRegister)
  const { userDetail } = useSelector((state) => state.userDetails)
  const { investorsProfileListData } = useSelector((state) => state.investorsProfileList)

  useEffect(() => {
    dispatch(getInvestorsProfileList())
  }, [dispatch])

  useEffect(() => {
    if (investorsProfileListData) {
      const prescribers = investorsProfileListData.filter((investor) => investor.isPrescriber)
      setPrescribers(prescribers)
    }
  }, [investorsProfileListData])

  useEffect(() => {
    if (successInvestorProfileByAdminRegister) {
      setAlert(<ModalConfirmationInvestment title='¡Hecho!' text='Perfil creado con exito' setAlert={setAlert} />)
    }
  }, [successInvestorProfileByAdminRegister])

  useEffect(() => {
    if (alert === null && successInvestorProfileByAdminRegister) {
      setData(initialState)
      setErrorTaxResidenceFile('')
      dispatch({ type: INVESTOR_PROFILE_BY_ADMIN_REGISTER_RESET })
      handleOpenClose()
    }
  }, [alert])

  useEffect(() => {
    return () => dispatch({ type: INVESTOR_PROFILE_BY_ADMIN_REGISTER_RESET })
  }, [dispatch])

  const handleSelectFile = () => {
    fileInput.current.click()
  }
  const handleChangeFile = (e) => {
    e.preventDefault()

    let pdfFile = e.target.files[0]

    setData({ ...data, residenceCertificate: pdfFile })
  }
  const handleRemoveFile = () => {
    setData({ ...data, residenceCertificate: '' })
  }

  const handlePersonType = (type) => {
    setData({ ...data, personType: type })
    setPersonType(type)
  }

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal + ' ' + classes.paddingModal,
      }}
      open={createModal}
      keepMounted
      onClose={handleOpenClose}
      aria-labelledby='user-modal-register-title'
      aria-describedby='user-modal-register-description'
    >
      <GridItem xs={12}>
        <GridContainer>
          <GridItem xs={12}>
            <h4>Añadir Perfil</h4>
          </GridItem>
          {personType === '' && (
            <GridItem xs={12}>
              <Typography variant='body1' className={classes.selectTitle}>
                Seleccione el <b>tipo de perfil</b> que desea crear para el usuario{' '}
                <b>
                  {userDetail.name} {userDetail.lastName}
                </b>
                .
              </Typography>
            </GridItem>
          )}
          <GridItem xs={12} className={classes.buttonGroup}>
            <Button
              disabled={loadingInvestorProfileByAdminRegister}
              onClick={() => handlePersonType('physical')}
              color={personType === 'physical' ? 'primary' : null}
            >
              Persona Física
            </Button>
            <Button
              disabled={loadingInvestorProfileByAdminRegister}
              onClick={() => handlePersonType('legal')}
              color={personType === 'legal' ? 'primary' : null}
            >
              Persona Jurídica
            </Button>
          </GridItem>
          <Button
            justIcon
            className={classes.modalCloseButton + ' ' + classes.closeIcon}
            key='close'
            aria-label='Close'
            color='transparent'
            onClick={handleOpenClose}
          >
            <Close className={classes.modalClose} />
          </Button>
        </GridContainer>

        {personType !== '' && (
          <>
            <Card className={classes.cardBorder}>
              <CardBody>
                <CardHeader className={classes.cardHeader}>
                  <h4 className={classes.title}>Nuevo Perfil - {getPersonTypeLabel(personType)}</h4>
                </CardHeader>

                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    if (data.country !== 'España' && !data.residenceCertificate) {
                      return setErrorTaxResidenceFile('Certificado de residencia fiscal obligatorio, salvo España')
                    }
                    handleCreateProfile()
                  }}
                  id='createProfileForm'
                >
                  {personType === 'physical' ? (
                    <InputsPhysicalPerson
                      setData={setData}
                      data={data}
                      investorsProfileListData={prescribers}
                      handleChangeFile={handleChangeFile}
                      fileInput={fileInput}
                      handleSelectFile={handleSelectFile}
                      setErrorTaxResidenceFile={setErrorTaxResidenceFile}
                    />
                  ) : (
                    <InputsLegalPerson
                      setData={setData}
                      data={data}
                      investorsProfileListData={prescribers}
                      handleChangeFile={handleChangeFile}
                      fileInput={fileInput}
                      handleSelectFile={handleSelectFile}
                      setErrorTaxResidenceFile={setErrorTaxResidenceFile}
                      userDetail={userDetail}
                    />
                  )}
                  <GridContainer>
                    {data.residenceCertificate && (
                      <GridItem xs={12}>
                        <Badge
                          badgeContent={
                            <Button size='sm' color='danger' round justIcon onClick={() => handleRemoveFile()}>
                              <Close />
                            </Button>
                          }
                          className={classes.fileBadge}
                        >
                          <div className={classes.fileDiv}>
                            {typeof data.residenceCertificate === 'object'
                              ? data.residenceCertificate.name.length > 20
                                ? data.residenceCertificate.name.slice(1, 10) +
                                  '...' +
                                  data.residenceCertificate.name.slice(
                                    data.residenceCertificate.name.length - 6,
                                    data.residenceCertificate.name.length
                                  )
                                : data.residenceCertificate.name
                              : data.residenceCertificate.length > 20
                              ? data.residenceCertificate.slice(1, 10) +
                                '...' +
                                data.residenceCertificate.slice(
                                  data.residenceCertificate.length - 6,
                                  data.residenceCertificate.length
                                )
                              : data.residenceCertificate}
                          </div>
                        </Badge>
                      </GridItem>
                    )}
                    {/* {!data.residenceCertificate && (
                      <GridItem xs={12} className={classes.addPhotoRoot}>
                        <div className='fileinput'>
                          <input
                            type='file'
                            name='residenceCertificate'
                            accept='.pdf,'
                            id='residenceCertificate'
                            onChange={handleChangeFile}
                            ref={fileInput}
                          />
                        </div>
                        <Tooltip id='tooltip-top' title='Certificado de Resicencia fiscal' placement='left'>
                          <Button color='primary' size='lg' justIcon round onClick={() => handleSelectFile()}>
                            <PictureAsPdf />
                          </Button>
                        </Tooltip>
                      </GridItem>
                    )} */}
                    {errorTaxResidenceFile && !data.residenceCertificate && (
                      <GridItem xs={12} sm={12}>
                        <SnackbarContent message={errorTaxResidenceFile} color='warning' />
                      </GridItem>
                    )}
                    {errorInvestorProfileByAdminRegister && (
                      <GridItem xs={12}>
                        <SnackbarContent message={errorInvestorProfileByAdminRegister} color='danger' />
                      </GridItem>
                    )}
                  </GridContainer>
                </form>
              </CardBody>
            </Card>
            <Button
              type='submit'
              color='primary'
              form='createProfileForm'
              className={classes.registerButton}
              disabled={loadingInvestorProfileByAdminRegister || (data.gender === '' && personType === 'physical')}
            >
              {loadingInvestorProfileByAdminRegister
                ? 'Creando usuario...'
                : `Registrar ${matchPersonType(personType)}`}
            </Button>
          </>
        )}
      </GridItem>
      {alert}
    </Dialog>
  )
}

export default ProfileCreateModal
