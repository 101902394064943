import { makeStyles } from '@material-ui/core'
import { CoPresent } from '@mui/icons-material'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Loader from 'components/Loader/Loader'
import ReactTable from 'components/ReactTable/ReactTable'
import { useEffect, useState } from 'react'
import styles from '../styles/prescriberListScreenstyles'

const useStyles = makeStyles(styles)

const ReferealProfilesPrescriber = ({ user }) => {
  const classes = useStyles()

  const [data, setData] = useState([])

  useEffect(() => {
    if (user) {
      const prescribers = user?.prescriberProfiles.map((item) => {
        return {
          id: item._id,
          profile: item.businessName,
          actions: '',
        }
      })
      setData(prescribers)
    }
  }, [user])

  return (
    <GridContainer>
      <GridItem xs={12} className={classes.rootItem}>
        {!user?.prescriberProfiles > 0 ? (
          <GridItem xs={12} className={classes.loader}>
            <Loader />
          </GridItem>
        ) : (
          <Card>
            <CardHeader color='primary' icon>
              <CardIcon color='primary'>
                <CoPresent />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Prescriptores</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                columns={[
                  {
                    Header: 'Perfil Referido',
                    accessor: 'profile',
                    size: 'big',
                  },
                  {
                    Header: 'Actions',
                    accessor: 'actions',
                    size: 'small',
                  },
                ]}
                data={data}
              />
            </CardBody>
          </Card>
        )}
      </GridItem>
    </GridContainer>
  )
}

export default ReferealProfilesPrescriber
