import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table, TableContainer, Paper, FormControlLabel, Switch } from '@mui/material/'
import { Tooltip, makeStyles } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import Button from 'components/CustomButtons/Button'
import ExcelDefaultExport from 'components/ReactTable/components/ExcelDefaultExport'
import TablePdlToLiquidateHeader from './TablePdlToLiquidateHeader'
import TablePdlToLiquidateBody from './TablePdlToLiquidateBody'
import { pdlsExportExcelMapper, pdlsMapper } from '../mappers/pdlsMapper'
import { pdlLiquidateTableExcelExport } from '../helpers/tableHeaderColumns'
import { updatePdlsDraftFields } from 'local_redux/actions/projectLiquidityActions'
import styles from '../styles/tablePdlToLiquidateStyles'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

const useStyles = makeStyles(styles)

const TablePdlToLiquidate = ({
	projectSelected,
	setProjectSelected,
	setConfirmationLiquidateModal,
	setSendEmails,
	sendEmails,
}) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const [pDLList, setPDLList] = useState([])
	const [openDatePicker, setOpenDatePicker] = useState(false)
	const [liquidityDate, setLiquidityDate] = useState(new Date())

	const { loadingProjectLiquidityLiquidate } = useSelector((state) => state.projectLiquidityLiquidate)

	useEffect(() => {
		setPDLList(pdlsMapper(projectSelected.pdls, liquidityDate))
	}, [projectSelected, liquidityDate])

	const handleChangeLiquidation = (e, info) => {
		const { name, value } = e.target

		if (name === 'csvProfileInclude' && info?.liquidationFields?.liquidation === 'reinvest') {
			return
		}

		let pdlInfo = {
			_id: info?._id,
			[name]: value,
			toPay: info?.liquidationFields?.toPay,
		}
		if (name === 'liquidation') {
			pdlInfo.toPay = value === 'benefits-only' ? info.fee - info.retentionFee : info?.liquidationFields?.toPay
		}

		dispatch(updatePdlsDraftFields(pdlInfo))

		if (!pdlInfo.liquidation) {
			pdlInfo.liquidation = info.liquidationFields.liquidation
		}

		const newLiquidationFields = {
			...info.liquidationFields,
			csvProfileInclude: pdlInfo?.liquidation === 'reinvest' ? false : info.liquidationFields?.csvProfileInclude,
			...pdlInfo,
		}

		const pdls = projectSelected?.pdls?.map((item) =>
			item._id === info._id ? { ...info, liquidationFields: newLiquidationFields } : item,
		)

		setProjectSelected((prev) => ({
			...prev,
			pdls: pdls,
		}))
	}
	const handleDepositDate = (date) => {
		setLiquidityDate(date)
	}
	const disabledBtn = () => {
		return pDLList.some((item) => !item.incomeRecivedDate)
	}

	return (
		<div style={{ marginTop: '2rem' }}>
			<GridContainer>
				<GridItem xs={6}>
					<FormControlLabel
						control={
							<Switch
								checked={sendEmails}
								onChange={(e) => {
									setSendEmails(Boolean(e.target.checked))
								}}
								value='sendEmails'
								classes={{
									switchBase: classes.switchBase,
									checked: classes.switchChecked,
									thumb: classes.switchIcon,
									track: classes.switchBar,
								}}
							/>
						}
						classes={{
							label: classes.label,
						}}
						label='Enviar por correo electronico'
					/>
				</GridItem>
				<GridItem xs={6}>
					<ExcelDefaultExport
						excelName='Lista de inversiones'
						tableColumns={pdlLiquidateTableExcelExport}
						data={pdlsExportExcelMapper(projectSelected.pdls, projectSelected.projectAddress, liquidityDate)}
					/>
				</GridItem>
			</GridContainer>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
					<TablePdlToLiquidateHeader classes={classes} setOpenDatePicker={setOpenDatePicker} />
					<TablePdlToLiquidateBody
						pdls={pDLList}
						projectAddress={projectSelected.projectAddress}
						handleChangeLiquidation={handleChangeLiquidation}
						classes={classes}
					/>
				</Table>
			</TableContainer>
			<div style={{ marginTop: '1rem' }}>
				<Tooltip
					disableHoverListener={
						!Boolean(
							projectSelected.pdls
								.filter((item) => !pDLList.includes(item._id))
								.some((item) => !item.incomeRecivedDate),
						)
					}
					title={'Existen pdl sin confirmar fecha de ingreso'}
				>
					<span>
						<Button
							key='close'
							aria-label='Close'
							disabled={disabledBtn() || loadingProjectLiquidityLiquidate}
							onClick={() => {
								setConfirmationLiquidateModal(true)
							}}
						>
							Liquidar PDLs
						</Button>
					</span>
				</Tooltip>
			</div>
			<DatePicker
				open={openDatePicker}
				onOpen={() => setOpenDatePicker(true)}
				onClose={() => setOpenDatePicker(false)}
				label='Fecha de Liquidacion'
				value={liquidityDate}
				onChange={handleDepositDate}
				TextFieldComponent={() => null}
			/>
		</div>
	)
}

export default TablePdlToLiquidate
