import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { ExpandMore } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import SweetAlert from 'react-bootstrap-sweetalert'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import ReactVirtualizedTable from 'components/ReactVirtualizedTable/ReactVirtualizedTable'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'
import TablePdlToLiquidate from './components/TablePdlToLiquidate'
import ConfirmationLiquidateModal from './components/ConfirmationLiquidateModal'
import DownloadCSVModal from './components/DownloadCSVModal'
import { pdlLiquidateScreenTableMapper } from './helpers/mapper'
import { pdlLiquidateScreenColumns } from './helpers/tableHeaderColumns'
import { getProjectsFromPdlLiquidate } from 'local_redux/actions/projectAction'
import { PROJECT_FROM_PDL_LIQUIDATE_LIST_RESET } from 'local_redux/constants/projectConstant'
import { PROJECT_LIQUIDITY_LIQUIDATE_RESET } from 'local_redux/constants/projectLiquidityConstants'
import styles from './styles/pdlLiquidateScreenStyles'

const useStyles = makeStyles(styles)

const PdlLiquidateScreen = () => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [projectList, setProjectList] = useState([]),
		[openAccordion, setOpenAccordion] = useState(false),
		[projectSelected, setProjectSelected] = useState({}),
		[alert, setAlert] = useState(null),
		[confirmationLiquidateModal, setConfirmationLiquidateModal] = useState(false),
		[sendEmails, setSendEmails] = useState(true),
		[openDownloadCSVModal, setOpenDownloadCSVModal] = useState(false)

	const {
		loadingProjectsToPdlLiquidate,
		successProjectsToPdlLiquidate,
		projectsToPdlLiquidate,
		errorProjectsToPdlLiquidate,
	} = useSelector((state) => state.projectsToPdlLiquidateList)
	const { successProjectLiquidityLiquidate, errorProjectLiquidityLiquidate } = useSelector(
		(state) => state.projectLiquidityLiquidate,
	)

	useEffect(() => {
		dispatch(getProjectsFromPdlLiquidate())
		return () => {
			dispatch({ type: PROJECT_FROM_PDL_LIQUIDATE_LIST_RESET })
		}
	}, [])
	useEffect(() => {
		if (successProjectsToPdlLiquidate) {
			setProjectList(pdlLiquidateScreenTableMapper(projectsToPdlLiquidate))
		}
	}, [successProjectsToPdlLiquidate])
	useEffect(() => {
		if (successProjectLiquidityLiquidate) {
			setConfirmationLiquidateModal(false)
			setOpenDownloadCSVModal(true)
		}
	}, [successProjectLiquidityLiquidate])
	useEffect(() => {
		if (errorProjectLiquidityLiquidate) {
			const handleClose = () => {
				dispatch({ type: PROJECT_LIQUIDITY_LIQUIDATE_RESET })
				setAlert(null)
				setConfirmationLiquidateModal(false)
			}

			setAlert(
				<SweetAlert
					warning
					style={{ display: 'block', marginTop: '-100px' }}
					title='¡Atención!'
					onConfirm={handleClose}
					onCancel={handleClose}
					confirmBtnCssClass={classes.confirmBtnCssClass}
				>
					<div style={{ textAlign: 'center' }}>Mensaje de error: {errorProjectLiquidityLiquidate}</div>
				</SweetAlert>,
			)
		}
	}, [errorProjectLiquidityLiquidate])

	const handleSelectProject = (project) => {
		setOpenAccordion(false)
		setProjectSelected(project)
	}

	return (
		<>
			<GridContainer>
				<GridItem xs={12}>
					<Accordion
						expanded={openAccordion}
						onChange={() => {
							setOpenAccordion(!openAccordion)
						}}
					>
						<AccordionSummary expandIcon={<ExpandMore />} aria-controls='panel1-content' id='panel1-header'>
							<Typography variant='h6'>PDLs</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<GridContainer>
								<GridItem xs={12}>
									<ReactVirtualizedTable
										columns={pdlLiquidateScreenColumns(handleSelectProject)}
										data={projectList}
										loading={Boolean(loadingProjectsToPdlLiquidate)}
										success={Boolean(successProjectsToPdlLiquidate)}
										error={errorProjectsToPdlLiquidate}
										emptyText={'No se encontraron PDLS'}
									/>
								</GridItem>
							</GridContainer>
						</AccordionDetails>
					</Accordion>
				</GridItem>
				{!!Object.values(projectSelected).length && (
					<>
						<GridItem xs={12} sm={8} md={6}>
							<Card>
								<CardHeader color='primary' icon>
									<h4 className={classes.cardIconTitle}>Informacion</h4>
								</CardHeader>
								<CardBody>
									<GridContainer>
										<GridItem xs={6}>Fecha Máxima de Liquidación:</GridItem>
										<GridItem xs={6}>
											<strong>
												{projectSelected?.dateMaximumPurchase
													? format(new Date(projectSelected?.dateMaximumPurchase), 'dd-MM-yyyy')
													: 'Sin Fecha'}
											</strong>
										</GridItem>
									</GridContainer>
								</CardBody>
							</Card>
						</GridItem>
						<GridItem xs={12}>
							<TablePdlToLiquidate
								projectSelected={projectSelected}
								setProjectSelected={setProjectSelected}
								setConfirmationLiquidateModal={setConfirmationLiquidateModal}
								setSendEmails={setSendEmails}
								sendEmails={sendEmails}
							/>
						</GridItem>
					</>
				)}
			</GridContainer>
			{confirmationLiquidateModal && (
				<ConfirmationLiquidateModal
					projectSelected={projectSelected}
					confirmationLiquidateModal={confirmationLiquidateModal}
					setConfirmationLiquidateModal={setConfirmationLiquidateModal}
					sendEmails={sendEmails}
				/>
			)}
			{openDownloadCSVModal && (
				<DownloadCSVModal
					projectSelected={projectSelected}
					downloadCSVModal={openDownloadCSVModal}
					setDownloadCSVModal={setOpenDownloadCSVModal}
					setAlert={setAlert}
					setProjectSelected={setProjectSelected}
					setProjectList={setProjectList}
					setConfirmationLiquidateModal={setConfirmationLiquidateModal}
				/>
			)}
			{alert}
		</>
	)
}

export default PdlLiquidateScreen
