import { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import ReactExport from 'react-data-export'
import { Close } from '@material-ui/icons'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Typography from '@mui/material/Typography'
import Clearfix from 'components/Clearfix/Clearfix'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { updateInvestmentPrescriptionLiquidate } from 'local_redux/actions/investmentActions'
import { INVESTMENT_UPDATE_PRESCRIPTION_LIQUIDATED_RESET } from 'local_redux/constants/investmentConstants'
import { FilterReactTableContext } from 'contexts/filterReactTableContext'
import styles from '../styles/prescriptionLiquidateModalStyles'

const useStyles = makeStyles(styles)

const PrescriptionLiquidateModal = ({
	handleClosePrescriptionLiquidateModal,
	prescriptionLiquidateModal,
	liquidatedPrescriberData,
}) => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const ExcelFile = ReactExport.ExcelFile
	const excelName = `Prescripciones-Liquidadas-${liquidatedPrescriberData.prescriberName}-Periodo-${format(
		new Date(liquidatedPrescriberData.date.startDate),
		'dd/MM/yyyy',
	)}-${format(new Date(liquidatedPrescriberData.date.endDate), 'dd/MM/yyyy')}}`

	const { filteredRows, filteredcolumns } = useContext(FilterReactTableContext)
	const {
		loadingInvestmentsUpdatePrescriptionLiquidated,
		errorInvestmentsUpdatePrescriptionLiquidated,
		successInvestmentsUpdatePrescriptionLiquidated,
	} = useSelector((state) => state.investmentsUpdatePrescriptionLiquidated)

	useEffect(() => {
		return () => dispatch({ type: INVESTMENT_UPDATE_PRESCRIPTION_LIQUIDATED_RESET })
	}, [dispatch])

	const handlePrescriptionLiquidated = (e) => {
		e.preventDefault()
		dispatch(updateInvestmentPrescriptionLiquidate({ prescriptionsId: filteredRows.map((item) => item.id) }))
	}
	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={prescriptionLiquidateModal}
			keepMounted
			onClose={handleClosePrescriptionLiquidateModal}
			aria-labelledby='prescription-liquidated-title'
			aria-describedby='prescription-liquidated-description'
		>
			<form onSubmit={handlePrescriptionLiquidated}>
				<DialogTitle id='prescription-liquidated-title' disableTypography className={classes.modalHeader}>
					<Button
						justIcon
						className={classes.modalCloseButton}
						key='close'
						aria-label='Close'
						color='transparent'
						onClick={handleClosePrescriptionLiquidateModal}
					>
						<Close className={classes.modalClose} />
					</Button>
					<h4>Liquidar Periodo</h4>
				</DialogTitle>
				<DialogContent id='prescription-liquidated-description' className={classes.modalBody}>
					<GridContainer>
						<GridItem xs={12}>
							<Typography>
								¿Está seguro de que desea liquidar la prescripción de <b>{liquidatedPrescriberData.prescriberName}</b>{' '}
								comprendida entre <b>{format(new Date(liquidatedPrescriberData.date.startDate), 'dd/MM/yyyy')}</b> y{' '}
								<b>{format(new Date(liquidatedPrescriberData.date.endDate), 'dd/MM/yyyy')}</b>?
							</Typography>
						</GridItem>
					</GridContainer>
					{errorInvestmentsUpdatePrescriptionLiquidated && (
						<GridContainer>
							<GridItem xs={12}>
								<SnackbarContent message={errorInvestmentsUpdatePrescriptionLiquidated} color='danger' />
							</GridItem>
						</GridContainer>
					)}
				</DialogContent>
				<DialogActions className={classes.modalFooter}>
					<GridContainer>
						<GridItem xs={6}>
							<Button onClick={handleClosePrescriptionLiquidateModal} block>
								Cancelar
							</Button>
						</GridItem>
						<GridItem xs={6}>
							<Button
								type='onSubmit'
								color={successInvestmentsUpdatePrescriptionLiquidated ? 'success' : 'primary'}
								block
							>
								{loadingInvestmentsUpdatePrescriptionLiquidated
									? 'Liquidando'
									: successInvestmentsUpdatePrescriptionLiquidated
									? 'Liquidado'
									: 'Liquidar'}
							</Button>
						</GridItem>
					</GridContainer>
				</DialogActions>
			</form>
			<Clearfix />
			{successInvestmentsUpdatePrescriptionLiquidated && (
				<ExcelFile filename={excelName} hideElement={true}>
					<ExcelFile.ExcelSheet data={filteredRows} name={excelName}>
						{filteredcolumns &&
							filteredcolumns.map((column, index) => (
								<ExcelFile.ExcelColumn key={index} label={column.Header} value={column.accessor} />
							))}
					</ExcelFile.ExcelSheet>
				</ExcelFile>
			)}
		</Dialog>
	)
}

export default PrescriptionLiquidateModal
