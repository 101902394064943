import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Typography from '@mui/material/Typography'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { deleteProject } from 'local_redux/actions/projectAction'
import { PROJECT_DELETE_RESET } from 'local_redux/constants/projectConstant'
import styles from '../styles/deleteProjectModalStyles'

const useStyles = makeStyles(styles)

const DeleteProjectModal = ({ handleCloseDeleteProjectModal, deleteProjectModal, showDeleteProjectInfo }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const { successProjectDelete, errorProjectDelete, loadingProjectDelete } = useSelector((state) => state.projectDelete)

  useEffect(() => {
    let timeOut = ''
    if (successProjectDelete) {
      timeOut = setTimeout(() => {
        dispatch({ type: PROJECT_DELETE_RESET })
        handleCloseDeleteProjectModal()
      }, 1000)
    }

    return () => clearTimeout(timeOut)
  }, [successProjectDelete])
  useEffect(() => {
    return () => dispatch({ type: PROJECT_DELETE_RESET })
  }, [dispatch])

  const handleDeleteProject = (e) => {
    e.preventDefault()
    dispatch(deleteProject(showDeleteProjectInfo?._id))
  }

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal,
      }}
      open={deleteProjectModal}
      keepMounted
      onClose={handleCloseDeleteProjectModal}
      aria-labelledby='project-modal-delete-title'
      aria-describedby='project-modal-delete-description'
    >
      <form onSubmit={handleDeleteProject}>
        <DialogTitle id='project-modal-delete-title' disableTypography className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key='close'
            aria-label='Close'
            color='transparent'
            onClick={handleCloseDeleteProjectModal}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4>Eliminar Proyecto</h4>
        </DialogTitle>

        <DialogContent id='project-modal-delete-description' className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12}>
              <Typography>
                ¿Estás seguro que queres eliminar el proyecto {showDeleteProjectInfo?.projectAddress}?
              </Typography>
            </GridItem>
          </GridContainer>

          {errorProjectDelete && (
            <GridContainer>
              <GridItem xs={12}>
                <SnackbarContent message={errorProjectDelete} color='danger' />
              </GridItem>
            </GridContainer>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <GridContainer>
            <GridItem xs={6}>
              <Button onClick={handleCloseDeleteProjectModal} block>
                Cancelar
              </Button>
            </GridItem>
            <GridItem xs={6}>
              <Button type='onSubmit' color={successProjectDelete ? 'success' : 'primary'} block>
                {loadingProjectDelete ? 'Eliminando' : successProjectDelete ? 'Eliminado' : 'Eliminar'}
              </Button>
            </GridItem>
          </GridContainer>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default DeleteProjectModal
