import {
  cardTitle,
  dangerColor,
  whiteColor,
  grayColor,
  blackColor,
  hexToRgb,
} from 'assets/jss/material-dashboard-pro-react'
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch'
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle'

const validationFormsStyle = {
  ...customCheckboxRadioSwitch,
  ...buttonStyle,
  cardTitle: {
    ...cardTitle,
    color: whiteColor,
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
  formCategory: {
    marginBottom: '0',
    color: grayColor[0],
    fontSize: '14px',
    padding: '10px 0 10px',
  },
  center: {
    textAlign: 'center',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  registerButton: {
    float: 'right',
  },
  danger: {
    color: dangerColor[0] + '!important',
  },
  confirmBtnCssClass: {
    backgroundColor: '#ebe6e2',
    color: '#333333',
    padding: '10px',
    width: '5rem',
    borderRadius: '3px',
    '&:hover': {
      color: '#333333',
    },
  },
  label: {
    cursor: 'pointer',
    paddingLeft: '0',
    color: 'rgba(' + hexToRgb(blackColor) + ', 0.26)',
    fontSize: '14px',
    lineHeight: '1.428571429',
    fontWeight: '400',
    display: 'inline-flex',
  },
  permissionsRoot: {
    display: 'flex',
    justifyContent: 'center',
    padding: '30px 0',
  },
}

export default validationFormsStyle
