import { useNavigate } from 'react-router-dom'
import { Close } from '@material-ui/icons'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Typography from '@mui/material/Typography'
import styles from '../styles/finishedDocumentValidationModalStyles'

const useStyles = makeStyles(styles)

const UnconfirmedInvestmentAlertModal = ({ openAlert, setOpenAlert }) => {
	const classes = useStyles()
	const navigate = useNavigate()

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={openAlert}
			keepMounted
			onClose={() => setOpenAlert(false)}
			aria-labelledby='finished-document-validation-title'
			aria-describedby='finished-document-validation-description'
		>
			<DialogTitle id='finished-document-validation-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={() => setOpenAlert(false)}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4>Inversión no confirmada</h4>
			</DialogTitle>

			<DialogContent id='finished-document-validation-description' className={classes.modalBody}>
				<GridContainer>
					<GridItem xs={12}>
						<Typography>Algunas de las inversiones estan pendientes de confirmar el ingreso.</Typography>
					</GridItem>
				</GridContainer>
			</DialogContent>
			<DialogActions className={classes.modalFooter}>
				<GridContainer>
					<GridItem xs={12} sm={6}>
						<Button onClick={() => navigate('/admin/investment-list')} block>
							Ir a lista de inversiones
						</Button>
					</GridItem>
					<GridItem xs={12} sm={6}>
						<Button onClick={() => setOpenAlert(false)} block color={'primary'}>
							Continuar
						</Button>
					</GridItem>
				</GridContainer>
			</DialogActions>
		</Dialog>
	)
}

export default UnconfirmedInvestmentAlertModal
