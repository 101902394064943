import { useContext, useEffect, useState } from 'react'
import { Attachment } from '@material-ui/icons'
import GridContainer from 'components/Grid/GridContainer'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridItem from 'components/Grid/GridItem'
import { FilterReactVirtualizedTableContext } from 'contexts/filterReactVirtualizedTableContext'
import { matchNumberToCurrency, replaceStringToNum } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'

const MyInvestmentResume = ({ filter, tableId }) => {
	const classes = {}

	const { getFilteredDataRows } = useContext(FilterReactVirtualizedTableContext)

	const [resume, setResume] = useState({})
	const [filteredRows, setFilteredRows] = useState([])

	useEffect(() => {
		// eslint-disable-next-line no-use-before-define
		calculateResume()
	}, [filteredRows])
	useEffect(() => {
		const dataRows = getFilteredDataRows({ tableId })
		if (!dataRows?.length) return
		setFilteredRows(dataRows)
	}, [getFilteredDataRows({ tableId })])

	const calculateResume = () => {
		let totalTIR = 0
		let totalAmount = 0
		let quantity = 0
		let benefit = 0
		let projectsQuantity = new Set()

		if (filter === 'Activas') {
			filteredRows.map((investment) => {
				projectsQuantity.add(investment.project)
				benefit += Number(investment.benefit)
				totalAmount += replaceStringToNum(investment.amount)
			})

			setResume({
				totalAmount,
				quantity: Array.from(projectsQuantity).length,
				benefit,
			})
		} else {
			filteredRows.map((investment) => {
				if (investment.status === 'Liquidado' && investment.incomeRecivedDate !== 'Sin Fecha') {
					quantity += 1
					totalAmount += replaceStringToNum(investment.toPay)
					benefit += Number(replaceStringToNum(investment.benefit))
					const investmentTIR = Number(investment.TIR?.replace('%', ''))
					if (investmentTIR > 0) {
						totalTIR += investmentTIR
					}
				}
			})

			setResume({
				totalTIR,
				totalAmount,
				quantity,
				benefit,
			})
		}
	}

	return (
		<GridContainer>
			{filter === 'Activas' && filteredRows.length > 0 && (
				<GridItem xs={12} sm={8} md={4}>
					<Card className={classes.cardStyle}>
						<CardHeader color='primary' icon>
							<CardIcon color='primary'>
								<Attachment />
							</CardIcon>
						</CardHeader>
						<CardBody>
							<hr />
							<p>
								Total de Inversión: <strong>{matchNumberToCurrency(resume?.totalAmount || '0')}</strong>
							</p>
							<p>
								Proyectos en los que tengo Inversión: <strong>{resume?.quantity || 0}</strong>
							</p>
						</CardBody>
					</Card>
				</GridItem>
			)}
			{filter === 'Finalizadas' && filteredRows.length > 0 && (
				<GridItem xs={12} sm={8} md={4}>
					<Card className={classes.cardStyle}>
						<CardHeader color='primary' icon>
							<CardIcon color='primary'>
								<Attachment />
							</CardIcon>
						</CardHeader>
						<CardBody>
							<hr />
							<p>
								Inversiones liquidadas: <strong>{resume?.quantity || 0}</strong>
							</p>
							<p>
								Importe liquidado: <strong>{matchNumberToCurrency(resume?.totalAmount || '0')}</strong>
							</p>
							<p>
								TIR Media:{' '}
								<strong>
									{isNaN(resume?.totalTIR / resume?.quantity) ? '0' : (resume?.totalTIR / resume?.quantity).toFixed(2)}{' '}
									%
								</strong>
							</p>
							<p>
								Beneficio Total: <strong>{matchNumberToCurrency(resume?.benefit || '0')}</strong>
							</p>
						</CardBody>
					</Card>
				</GridItem>
			)}
		</GridContainer>
	)
}

export default MyInvestmentResume
