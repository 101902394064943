import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import Typography from '@mui/material/Typography'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import CustomInput from 'components/CustomInput/CustomInput'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { PRE_REGISTER_DELETE_RESET } from 'local_redux/constants/preRegisterConstants'
import { deletePreRegister } from 'local_redux/actions/preRegisterActions'
import styles from '../styles/deletePreInvestorsModalStyles'

const useStyles = makeStyles(styles)

const DeletePreInvestorModal = ({ handleDeletePreinvestor, deletePreInvestorModal, deletePreInvestorInfo }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [phrase, setPhrase] = useState('')

  const { successPreRegisterDelete, errorPreRegisterDelete, loadingPreRegisterDelete } = useSelector(
    (state) => state.preRegisterDelete
  )

  useEffect(() => {
    if (successPreRegisterDelete) {
      setTimeout(() => {
        dispatch({ type: PRE_REGISTER_DELETE_RESET })
        handleDeletePreinvestor()
      }, 1000)
    }
  }, [successPreRegisterDelete])

  const handleDeleteUser = (e) => {
    e.preventDefault()
    dispatch(deletePreRegister(deletePreInvestorInfo?._id))
  }

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal,
      }}
      open={deletePreInvestorModal}
      keepMounted
      onClose={handleDeletePreinvestor}
      aria-labelledby='pre-investor-title'
      aria-describedby='pre-investor-description'
    >
      <DialogTitle id='pre-investor-title' disableTypography className={classes.modalHeader}>
        <Button
          justIcon
          className={classes.modalCloseButton}
          key='close'
          aria-label='Close'
          color='transparent'
          onClick={handleDeletePreinvestor}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h4>Eliminar Pre-Inversor</h4>
      </DialogTitle>
      <DialogContent id='user-modal-delete-description' className={classes.modalBody}>
        <form onSubmit={handleDeleteUser} id='delete-preinvestor-form'>
          <GridContainer>
            <GridItem xs={12}>
              <Typography>
                ¿Está seguro de que desea eliminar el Pre-Inversor <strong>{deletePreInvestorInfo.email}</strong>?
              </Typography>
              <br />
              <Typography>
                Una vez que un Pre-Inversor se elimina de forma permanente, no se puede recuperar.
              </Typography>
              <br />
              <Typography>Escriba la siguiente frase para habilitar el botón Eliminar.</Typography>
              <p className={classes.deletePhrase}>absolutamente seguro</p>
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                inputProps={{
                  autoFocus: true,
                  placeholder: 'Escriba la frase aquí',
                  type: 'text',
                  autoComplete: 'off',
                  onChange: (e) => setPhrase(e.target.value.toLowerCase()),
                }}
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </GridItem>
          </GridContainer>
          {errorPreRegisterDelete && (
            <GridContainer>
              <GridItem xs={12}>
                <SnackbarContent message={errorPreRegisterDelete} color='danger' />
              </GridItem>
            </GridContainer>
          )}
        </form>
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <GridContainer>
          <GridItem xs={6}>
            <Button onClick={handleDeletePreinvestor} block>
              Cancelar
            </Button>
          </GridItem>
          <GridItem xs={6}>
            <Button
              disabled={phrase !== 'absolutamente seguro'}
              type='submit'
              form='delete-preinvestor-form'
              color={successPreRegisterDelete ? 'success' : 'primary'}
              block
            >
              {loadingPreRegisterDelete ? 'Eliminando' : successPreRegisterDelete ? 'Eliminado' : 'Eliminar'}
            </Button>
          </GridItem>
        </GridContainer>
      </DialogActions>
    </Dialog>
  )
}

export default DeletePreInvestorModal
