import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import es from 'date-fns/locale/es'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import EditableInput from 'components/EditableInput/EditableInput'
import { matchNumberToCurrency } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import { PROJECT_LIQUIDITY_UPDATE_RESET } from 'local_redux/constants/projectLiquidityConstants'
import styles from '../styles/incomeConfirmationModalStyles'

const useStyles = makeStyles(styles)

const IncomeConfirmationModal = ({
	handleCloseIncomeUpdateModal,
	incomeUpdateModal,
	showIncomeUpdateInfo,
	updateIncomeRecivedDate,
}) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [date, setDate] = useState(new Date())
	const [userEmail, setUserEmail] = useState(showIncomeUpdateInfo?.profileInvestor?.email || '')

	const { loadingProjectLiquidityUpdate, successProjectLiquidityUpdate, errorProjectLiquidityUpdate } = useSelector(
		(state) => state.projectLiquidityUpdate,
	)

	useEffect(() => {
		let timeOut
		if (successProjectLiquidityUpdate) {
			timeOut = setTimeout(() => {
				dispatch({ type: PROJECT_LIQUIDITY_UPDATE_RESET })
				handleCloseIncomeUpdateModal()
			}, 1500)
		}
		return () => clearTimeout(timeOut)
	}, [successProjectLiquidityUpdate])
	useEffect(() => {
		return () => dispatch({ type: PROJECT_LIQUIDITY_UPDATE_RESET })
	}, [dispatch])

	const handleIncomeConfirmation = (e) => {
		e.preventDefault()
		updateIncomeRecivedDate(showIncomeUpdateInfo._id, date, userEmail)
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={incomeUpdateModal}
			keepMounted
			onClose={handleCloseIncomeUpdateModal}
			aria-labelledby='income-confirm-entry-modal-title'
			aria-describedby='income-confirm-entry-modal-description'
		>
			<DialogTitle id='income-confirm-entry-modal-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={handleCloseIncomeUpdateModal}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4>Confirmar ingreso de PDL</h4>
			</DialogTitle>
			<DialogContent id='income-confirm-entry-modal-description' className={classes.modalBody}>
				<form onSubmit={handleIncomeConfirmation} id='income-confirmation-entry-form'>
					<GridContainer>
						<GridItem xs={12}>
							<div
								style={{
									margin: 'auto',
								}}
							>
								<p>
									Recuerde que una vez confirmado el ingreso, esta operación no puede ser modificada, por favor
									corrobore bien los datos antes de confirmar.
								</p>
								<ol className={classes.ulStyles}>
									<li>
										Proyecto <b>{`${showIncomeUpdateInfo.projectAddress}`}</b>
									</li>
									<li>
										Importe <b>{matchNumberToCurrency(showIncomeUpdateInfo.amountPdl)} </b>
									</li>
									<li>
										Perfil <b>{`${showIncomeUpdateInfo?.profileInvestor?.businessName}`}</b>
									</li>
									<li>
										Fecha de PDL <b>{`${format(new Date(showIncomeUpdateInfo.createdAt), 'dd/MM/yyyy')}`}</b>
									</li>
									<li>
										<div style={{ display: 'flex' }}>
											<span style={{ marginRight: '3px' }}>Correo:</span>
											<b>
												<EditableInput
													tooltipTitle='Haz doble click para editar.'
													value={userEmail}
													setValue={setUserEmail}
												/>
											</b>
										</div>
									</li>
								</ol>
							</div>
						</GridItem>
						<GridItem xs={12}>
							<LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
								<CalendarPicker
									className={classes.calendaryContainer}
									date={date}
									onChange={(newDate) => setDate(newDate)}
									disableFuture
								/>
							</LocalizationProvider>
						</GridItem>
					</GridContainer>
					{errorProjectLiquidityUpdate && (
						<GridContainer>
							<GridItem xs={12}>
								<SnackbarContent message={errorProjectLiquidityUpdate} color='danger' />
							</GridItem>
						</GridContainer>
					)}
				</form>
			</DialogContent>
			<DialogActions className={classes.modalFooter}>
				<GridContainer>
					<GridItem xs={12}>
						<Button
							type='submit'
							color={successProjectLiquidityUpdate ? 'success' : 'primary'}
							block
							form='income-confirmation-entry-form'
							disabled={loadingProjectLiquidityUpdate || successProjectLiquidityUpdate || errorProjectLiquidityUpdate}
						>
							{loadingProjectLiquidityUpdate
								? 'Confirmando...'
								: successProjectLiquidityUpdate
								? 'Confirmado'
								: 'Confirmar'}
						</Button>
					</GridItem>
				</GridContainer>
			</DialogActions>
		</Dialog>
	)
}

export default IncomeConfirmationModal
