import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert'
import { makeStyles } from '@material-ui/core'
import { AddBusiness } from '@mui/icons-material'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import Card from 'components/Card/Card'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import CardBody from 'components/Card/CardBody'
import ProjectInputs from './components/ProjectInputs'
import { registerProject } from 'local_redux/actions/projectAction'
import { PROJECT_REGISTER_RESET, PROJECT_LIST_RESET } from 'local_redux/constants/projectConstant'
import styles from './styles/projectRegisterScreenStyles'

const useStyles = makeStyles(styles)

const ProjectRegisterScreen = () => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const initialState = {
		startDateDossier: null,
		finishDateDossier: null,
		projectAddress: '',
		description: '',
		status: '',
		coverImage: '',
		imageURL: '',
		simplePdf: '',
		projectSurface: '',
		city: 'Madrid',
		projectCost: '',
		projectSaleValueDossier: '',
		projectAddress: '',
		published: true,
		owner: '',
	}

	const [projectState, setProjectState] = useState(initialState)
	const [errorImage, setErrorImage] = useState('')
	const [finishDateDossierError, setFinishDateDossierError] = useState(false)
	const [startDateDossierError, setStartDateDossierError] = useState(false)
	const [alert, setAlert] = useState(null)

	const { loadingProjectRegister, successProjectRegister, errorProjectRegister } = useSelector(
		(state) => state.projectRegister,
	)

	useEffect(() => {
		if (successProjectRegister) {
			setAlert(
				<SweetAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='¡Hecho!'
					// eslint-disable-next-line no-use-before-define
					onConfirm={() => confirmSuccess()}
					confirmBtnCssClass={classes.confirmBtnCssClass}
				>
					Proyecto Registrado Correctamente
				</SweetAlert>,
			)
		}
	}, [successProjectRegister])

	const confirmSuccess = () => {
		setProjectState(initialState)
		setAlert(null)
		setErrorImage('')
		dispatch({ type: PROJECT_LIST_RESET })
		dispatch({ type: PROJECT_REGISTER_RESET })
	}
	const registerProjectHandler = (e) => {
		e.preventDefault()
		setStartDateDossierError(false)
		setFinishDateDossierError(false)
		if (!projectState.coverImage) {
			return setErrorImage('La imagen es Obligatoria')
		}
		if (!projectState.startDateDossier) {
			return setStartDateDossierError(true)
		}
		if (!projectState.finishDateDossier) {
			return setFinishDateDossierError(true)
		}
		const data = {
			projectCost: projectState.projectCost,
			city: projectState.city,
			startDateDossier: projectState.startDateDossier,
			finishDateDossier: projectState.finishDateDossier,
			projectAddress: projectState.projectAddress,
			description: projectState.description,
			projectSaleValueDossier: projectState.projectSaleValueDossier,
			projectSurface: projectState.projectSurface,
			status: projectState.status,
			simpleNoteFile: projectState.simplePdf,
			images: projectState.coverImage,
			published: projectState.published,
			owner: projectState.owner,
		}

		dispatch(registerProject(data))
	}

	return (
		<GridContainer>
			<GridItem xs={12} sm={12}>
				<Card>
					<CardHeader color='primary' icon>
						<CardIcon color='primary'>
							<AddBusiness />
						</CardIcon>
					</CardHeader>
					<CardBody>
						<form autoComplete='off' onSubmit={registerProjectHandler}>
							<ProjectInputs projectHandler={{ projectState, setProjectState }} />
							{errorImage && !projectState.imageURL && (
								<GridContainer>
									<GridItem xs={12}>
										<SnackbarContent message={errorImage} color='danger' />
									</GridItem>
								</GridContainer>
							)}
							{errorProjectRegister && (
								<GridContainer>
									<GridItem xs={12}>
										<SnackbarContent message={errorProjectRegister} color='danger' />
									</GridItem>
								</GridContainer>
							)}
							{startDateDossierError && (
								<GridContainer>
									<GridItem xs={12}>
										<SnackbarContent message={<span>Fecha de inicio en Dossier requerida</span>} color='danger' />
									</GridItem>
								</GridContainer>
							)}
							{finishDateDossierError && (
								<GridContainer>
									<GridItem xs={12}>
										<SnackbarContent message={<span>Fecha fin en Dossier requerida</span>} color='danger' />
									</GridItem>
								</GridContainer>
							)}
							<GridContainer>
								<GridItem xs={12}>
									<Button type='submit' color='primary' block>
										{`${loadingProjectRegister ? 'Registrando...' : 'Registrar Proyecto'}`}
									</Button>
								</GridItem>
							</GridContainer>
						</form>
					</CardBody>
				</Card>
			</GridItem>
			{alert}
		</GridContainer>
	)
}

export default ProjectRegisterScreen
