import { subMonths, subMinutes } from 'date-fns'

const defaultStartDate = subMonths(new Date(), 1)
const defaultEndDate = new Date()

const initialStateDates = {
	startDate: defaultStartDate,
	endDate: subMinutes(defaultEndDate, 1),
}

export default initialStateDates
