export const preInvestorListMapper = (preRegisterListData) => {
	return preRegisterListData?.map((item) => {
		return {
			id: item._id,
			item: item,
			name: item?.name || '-----',
			lastName: item?.lastName || '-----',
			email: item?.email || '-----',
			phone: item?.phone || '-----',
			registerUrl: item?.registerUrl,
			registered: item?.registered ? 'Registrado' : 'Sin Registrar',
			actions: '',
		}
	})
}
