import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle'

const styles = (theme) => ({
  infoRoot: {
    border: '1px solid #d4d3d3',
    borderRadius: '6px',
    padding: '15px 10px',
    margin: '0 0 20px',
    boxShadow: '0 1px 11px 0 rgb(0 0 0 / 14%)',
  },
  closeButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  filesDiv: {
    marginBottom: '20px',
  },
  addPhotoRoot: {
    marginTop: '3rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  imagesDiv: {
    marginBottom: '10px',
    '& img': {
      borderRadius: '6px',
      marginBottom: '10px',
      boxShadow: '0 1px 4px 0 rgb(0 0 0 / 14%)',
    },
  },
  fileBadge: {
    margin: '20px 0',
    width: '100%',
  },
  fileDiv: {
    textAlign: 'center',
    backgroundColor: '#b9b6b6',
    borderRadius: '5px',
    padding: '5px',
    width: '100%',
  },
  ...modalStyle(theme),
})

export default styles
