const styles = {
  card: {
    padding: '20px',
    margin: 'auto',
  },
  gridBody: {
    boxSizing: 'border-box',
    margin: 'auto',
    width: '100%',
  },
  image: {
    borderRadius: '50%',
    height: '60px',
    width: '60px',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    marginBottom: '10px',
  },
  cardTitle: {
    color: 'black',
  },
  infoRow: {
    marginTop: 20,
  },
  clipBoardText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '-3px',
    paddingLeft: '10px',
    border: '2px solid #c0bbac',
    cursor: 'pointer',
    borderRadius: '5px',
    overflow: 'hidden',
    fontWeight: 600,
    '&:hover': { backgroundColor: '#DFFEE7 !important' },
    '& span': { whiteSpace: 'nowrap', overflow: 'hidden' },
  },
  clipBoardButton: {
    marginTop: '-2px',
    padding: '0px -15px 0px',
  },
  helperText: {
    color: '#4caf50 !important',
  },
}

export default styles
