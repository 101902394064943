import { useEffect, useState, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import InfiniteScroll from 'react-infinite-scroll-component'
import { makeStyles } from '@material-ui/core'
import AlertDni from './components/AlertDni'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Loader from 'components/Loader/Loader'
import ModalConfirmationInvestment from 'components/ModalConfirmationInvestment/ModalConfirmationInvestment'
import ProjectCard from './components/ProjectCard'
import ProjectDetails from './components/ProjectDetails'
import role from 'config/roles/roles'
import { PROJECT_TO_INVEST_LIST_RESET } from 'local_redux/constants/projectConstant'
import { getAllProjectsToInvest } from 'local_redux/actions/projectAction'
import styles from './styles/projectsScreenStyles'

const useStyles = makeStyles(styles)

const ProjectsScreen = () => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const search = useLocation().search
	const loaderRef = useRef()
	const projectIdQuery = new URLSearchParams(search).get('projectId')

	const [identification] = useState('')
	const [disabled, setDisabled] = useState(true) // eslint-disable-line no-unused-vars
	const [projects, setProjects] = useState([])
	const [hasMore, setHasMore] = useState(true)
	const [page, setPage] = useState(0)
	const [alert, setAlert] = useState(null)

	const { userInfo } = useSelector((state) => state.userLogin)
	const { investmentRegisterData, investmentRegisterSuccess } = useSelector((state) => state.investmentRegister)
	const { loadingProjectList, successProjectList, projectListData } = useSelector((state) => state.projectListToInvest)
	const { investorsProfileListData } = useSelector((state) => state.investorsProfileList)

	const handleObserver = useCallback(
		(entries) => {
			const target = entries[0]
			if (target.isIntersecting && projects.length < projectListData?.total) {
				setPage((prev) => prev + 1)
				setHasMore(true)
			}
		},
		[projects],
	)

	useEffect(() => {
		const option = {
			root: null,
			rootMargin: '0px',
			threshold: 1,
		}
		const observer = new IntersectionObserver(handleObserver, option)
		if (loaderRef.current) {
			observer.observe(loaderRef.current)
		}

		return () => observer.disconnect()
	}, [handleObserver, loaderRef])
	useEffect(() => {
		if (identification !== '') {
			setDisabled(false)
		} else {
			setDisabled(true)
		}
	}, [identification])
	useEffect(() => {
		if (!projectIdQuery && hasMore) {
			// eslint-disable-next-line no-use-before-define
			fetchData(page)
			setHasMore(false)
		}
	}, [projectIdQuery, page, hasMore])
	useEffect(() => {
		if (successProjectList) {
			setProjects((prev) => [...prev, ...projectListData.projects])
		}
	}, [successProjectList])
	useEffect(() => {
		if (investmentRegisterSuccess && successProjectList) {
			// eslint-disable-next-line no-use-before-define
			handleModalConfirmation()
			let updateInvertibleAmount = projects.map((item) => {
				if (item._id === investmentRegisterData._doc.project) {
					item.investments = [...item.investments, investmentRegisterData._doc]
				}
				return item
			})
			setProjects([...updateInvertibleAmount])
		}
	}, [investmentRegisterSuccess, successProjectList])
	useEffect(() => {
		return () => dispatch({ type: PROJECT_TO_INVEST_LIST_RESET })
	}, [dispatch])

	const fetchData = (page) => dispatch(getAllProjectsToInvest(page))
	const handleModalConfirmation = () => {
		if (investmentRegisterSuccess && !projectIdQuery) {
			if (userInfo.role === role.INVESTOR_ROLE)
				setAlert(
					<ModalConfirmationInvestment
						title={'Inversión realizada correctamente!'}
						text={
							'En unos minutos recibirá el contrato por correo electrónico para su firma vía docuSign. En él encontrará también las instrucciones para realizar el ingreso. Por favor tenga en cuenta que su inversión es efectiva desde que el contrato está firmado y el importe de inversión se hace efectivo en nuestra cuenta corriente. Muchas gracias por su confianza en nosotros.'
						}
						setAlert={setAlert}
					/>,
				)
			else {
				let nameText = ''
				investorsProfileListData.map(
					(investor) => investor._id === investmentRegisterData.investorProfile && (nameText = investor.businessName),
				)

				const dataText = `En unos minutos el usuario ${nameText} recibirá un correo electronico con el contrato a su nombre para que pueda firmarlo vía docuSign. En él encontrará también las instrucciones para realizar el ingreso. Por favor recuerdele al usuario que la inversión será efectiva desde que el contrato este firmado y el importe de inversión se hace efectivo en nuestra cuenta corriente.`
				setAlert(
					<ModalConfirmationInvestment
						title={'Inversión realizada correctamente!'}
						text={dataText}
						setAlert={setAlert}
					/>,
				)
			}
		}
	}

	return (
		<>
			<GridContainer className={!projectIdQuery ? classes.rootCard : `${classes.rootCard} ${classes.noMargin}`}>
				{projectIdQuery ? (
					<GridItem xs={12} sm={10} className={classes.marginAuto}>
						<ProjectDetails projectId={projectIdQuery} projects={projectListData?.projects} />
					</GridItem>
				) : (
					<div className={classes.width100}>
						{loadingProjectList && !projectListData?.length && (
							<GridItem xs={12} className={classes.loader}>
								<Loader />
							</GridItem>
						)}
						<InfiniteScroll
							dataLength={projects?.length || 0}
							next={fetchData}
							hasMore={hasMore}
							loader={
								<GridItem xs={12} className={classes.loader}>
									<Loader />
								</GridItem>
							}
						>
							<GridItem xs={12} className={classes.infiniteItem}>
								<GridContainer className={classes.projectsContainer}>
									{projects.map((project, i) => (
										<ProjectCard forceNewOpportunity={i < 3} project={project} key={project._id} index={i} />
									))}
								</GridContainer>
							</GridItem>
						</InfiniteScroll>
					</div>
				)}
				{!userInfo?.dni && userInfo?.role === 'investor' ? (
					<AlertDni text={'Es necesario que registre su DNI. Haga click aqui'} />
				) : null}
				<div
					ref={loaderRef}
					style={{
						display: !loadingProjectList && projects?.length < projectListData?.total ? 'block' : 'none',
						visibility: 'hidden',
					}}
				>
					.
				</div>
			</GridContainer>
			{alert}
		</>
	)
}

export default ProjectsScreen
