import { NumericFormat } from 'react-number-format'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { matchNumberToCurrency } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import { useSelector } from 'react-redux'
import styles from '../styles/reinvestmentModalStyles'

const useStyles = makeStyles(styles)

const ReInvestTable = ({ data, projectData, handleChange, handleDateReinvest }) => {
	const classes = useStyles(styles)

	const { userInfo } = useSelector((state) => state.userLogin)

	const dateReinvest = userInfo.isAdmin || userInfo.isSuper ? ['Fecha de Inversión'] : []

	const handleChangeDate = (value, item) => {
		handleDateReinvest(value, item)
	}
	return (
		<div className='ReactTable -striped -highlight'>
			<table className={'rt-table '} style={{ display: 'table', width: '100%' }}>
				<thead className='rt-thead -header'>
					<tr className='rt-tr'>
						{['Proyectos', 'Importe Invertible', '¿Cuánto reinvierte?'].concat(dateReinvest).map((item, i) => (
							<th key={i} className={classnames('rt-th rt-resizable-header')}>
								{item}
							</th>
						))}
					</tr>
				</thead>
				<tbody className='rt-thead -header'>
					{data.map((item, i) => {
						return (
							<tr
								key={i}
								className={
									classnames('rt-tr', { ' -odd': i % 2 === 0 }, { ' -even': i % 2 === 1 }) +
									` ${classes.hoverCell} ${classes.trWidthBig}`
								}
							>
								<td className={classnames('rt-td', classes.widthBig)}>{item.projectAddress}</td>
								<td className={classnames('rt-td', classes.widthBig)}>
									{matchNumberToCurrency(item.amountInvertible)}
								</td>
								<td className={classnames('rt-td', classes.widthBig)}>
									{
										<NumericFormat
											className={classes.inputFormat}
											decimalSeparator=','
											thousandsGroupStyle='mil'
											thousandSeparator='.'
											decimalScale={2}
											allowedDecimalSeparators=','
											fixedDecimalScale={true}
											valueIsNumericString={true}
											value={projectData?.find((project) => project?._id === item._id)?.reinvestmentAmount}
											onValueChange={(value) => handleChange(value, item)}
											allowNegative={false}
											suffix={' €'}
											placeholder='0,00 €'
										/>
									}
								</td>
								{dateReinvest.length ? (
									<td className={classnames('rt-td', classes.widthBig)}>
										<DatePicker
											value={projectData?.find((project) => project?._id === item._id)?.dateReinvest || null}
											label=''
											onChange={(value) => handleChangeDate(value, item)}
											className={classes.dateInput}
											format='dd/MM/yyyy'
											disableFuture
										/>
									</td>
								) : null}
							</tr>
						)
					})}
				</tbody>
			</table>
		</div>
	)
}

export default ReInvestTable
