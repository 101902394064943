import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { pdlsMapper } from '../mappers/pdlsMapper'
import { liquidateProjectLiquidity } from 'local_redux/actions/projectLiquidityActions'
import styles from '../styles/confirmationLiquidateModalStyles'

const useStyles = makeStyles(styles)

const ConfirmationLiquidateModal = ({
	setConfirmationLiquidateModal,
	confirmationLiquidateModal,
	projectSelected,
	sendEmails,
}) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [pDLList, setPDLList] = useState([])

	const { successProjectLiquidityLiquidate, loadingProjectLiquidityLiquidate, errorProjectLiquidityLiquidate } =
		useSelector((state) => state.projectLiquidityLiquidate)

	useEffect(() => {
		setPDLList(pdlsMapper(projectSelected.pdls, new Date()))
	}, [projectSelected])

	const handleSubmit = (e) => {
		e.preventDefault()

		dispatch(
			liquidateProjectLiquidity({
				sigProjectRef: projectSelected.sigProjectRef,
				projectAddress: projectSelected.projectAddress,
				project: projectSelected._id,
				pdls: pDLList,
				sendEmails: sendEmails,
			}),
		)
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={confirmationLiquidateModal}
			keepMounted
			onClose={() => setConfirmationLiquidateModal(false)}
			aria-labelledby='income-confirm-modal-title'
			aria-describedby='income-confirm-modal-description'
		>
			<DialogTitle id='income-confirm-modal-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={() => setConfirmationLiquidateModal(false)}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4>
					Confirmar Liquidacion <b>{projectSelected.projectAddress}</b>
				</h4>
			</DialogTitle>
			<DialogContent id='income-confirm-modal-description' className={classes.modalBody}>
				<form onSubmit={handleSubmit} id='income-confirmation-form'>
					<GridContainer>
						<GridItem xs={12}>
							<div
								style={{
									margin: 'auto',
								}}
							>
								<p>{`¿Seguro que desea liquidar los PDLs el proyecto ${projectSelected.projectAddress}?`}</p>
								<ol className={classes.ulStyles}>
									<li>Se marcarán los pdls como liquidados</li>
									<li>Se enviarán un correo electrónico a los inversores notificando la liquidación de su PDL</li>
									<li>Se generará el CSV de la liquidación de su PDL</li>
								</ol>
							</div>
						</GridItem>
					</GridContainer>
				</form>
			</DialogContent>
			<DialogActions className={classes.modalFooter}>
				<GridContainer>
					<GridItem xs={12}>
						<Button
							type='submit'
							color={successProjectLiquidityLiquidate ? 'success' : 'primary'}
							block
							form='income-confirmation-form'
							disabled={
								loadingProjectLiquidityLiquidate || successProjectLiquidityLiquidate || errorProjectLiquidityLiquidate
							}
						>
							{loadingProjectLiquidityLiquidate
								? 'Cargando...'
								: successProjectLiquidityLiquidate
								? 'Confirmado'
								: 'Confirmar'}
						</Button>
					</GridItem>
				</GridContainer>
			</DialogActions>
		</Dialog>
	)
}

export default ConfirmationLiquidateModal
