import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert'
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	makeStyles,
	Switch,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import Loader from 'components/Loader/Loader'
import ReInvestTable from './ReInvestTable'
import {
	replaceStringToNum,
	matchCurrencyToDecimal,
	matchNumberToCurrency,
} from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import amountInWords from 'shared/amountInWords/amountInWords'
import { getAllProjectsToInvest } from 'local_redux/actions/projectAction'
import { registerReinvestPdlInvestments } from 'local_redux/actions/projectLiquidityActions'
import { PDL_REGISTER_REINVEST_INVESTMENTS_RESET } from 'local_redux/constants/projectLiquidityConstants'
import { PROJECT_TO_INVEST_LIST_RESET } from 'local_redux/constants/projectConstant'
import styles from '../styles/reinvestmentModalStyles'

const useStyles = makeStyles(styles)

const ReinvestmentModalPdlScreen = ({
	openReinvestmentModal,
	setOpenReinvestmentModal,
	handleCloseReinvestAdminModal,
	showReinvestAdminInfo,
}) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [projectdata, setProjectData] = useState([])
	const [changeProjectData, setChangeProjectData] = useState([])
	const [amountToReinvest, setAmountToReinvest] = useState([])
	const [pendingReinvestmentAmount, setPendingReinvestmentAmount] = useState([])
	const [alert, setAlert] = useState(null)
	const [error, setError] = useState({ message: '', show: false })
	const [sendContract, setSendContract] = useState(true)

	const { userInfo } = useSelector((state) => state.userLogin)
	const { loadingProjectList, projectListData, successProjectList } = useSelector((state) => state.projectListToInvest)
	const { successPdlRegisterReinvestPdlInvestments, loadingPdlRegisterReinvestPdlInvestments } = useSelector(
		(state) => state.pdlRegisterReinvestPdlInvestments,
	)

	useEffect(() => {
		if (successPdlRegisterReinvestPdlInvestments) {
			setAlert(
				<SweetAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='¡Hecho!'
					// eslint-disable-next-line no-use-before-define
					onConfirm={() => confirmSuccess()}
					confirmBtnCssClass={classes.confirmBtnCssClass}
				>
					<div style={{ textAlign: 'center' }}>Reinversión Registrada Correctamente</div>
				</SweetAlert>,
			)
			return () => {
				dispatch({ type: PROJECT_TO_INVEST_LIST_RESET })
				dispatch({ type: PDL_REGISTER_REINVEST_INVESTMENTS_RESET })
			}
		}
	}, [successPdlRegisterReinvestPdlInvestments])
	useEffect(() => {
		if (!successProjectList) {
			dispatch(getAllProjectsToInvest(0))
		}
	}, [successProjectList])
	useEffect(() => {
		if (successProjectList && showReinvestAdminInfo) {
			const data = projectListData?.projects?.map((item) => {
				let total = 0
				item?.investments?.forEach((investment) => {
					total = total + parseFloat(replaceStringToNum(investment.amount))
				})
				const amountInvertible = matchCurrencyToDecimal(item?.maxInvertible) - total
				if (amountInvertible > 0) {
					item.view = true
					item.amountInvertible = amountInvertible
				} else {
					item.view = false
				}
				return item
			})
			setProjectData(data.filter((project) => project.view === true && project.invertible === true))
		}
	}, [successProjectList, showReinvestAdminInfo])
	useEffect(() => {
		if (pendingReinvestmentAmount < 0 && error.show === false) {
			setError({
				message: `El monto maximo de reinversion disponible es ${matchNumberToCurrency(amountToReinvest)}`,
				show: true,
			})
		}
	}, [error])
	useEffect(() => {
		// eslint-disable-next-line no-use-before-define
		handleParams([])
	}, [])

	const confirmSuccess = () => {
		setProjectData([])
		setAlert(null)
		setOpenReinvestmentModal(false)
	}
	const handleParams = (data) => {
		const totalAmountInvested = data?.reduce(
			(previousValue, currentValue) =>
				previousValue + Number(matchCurrencyToDecimal(currentValue.reinvestmentAmount.toString())),
			0,
		)
		const reinvestingAmount = matchCurrencyToDecimal(
			showReinvestAdminInfo.liquidationFields?.liquidation === 'benefits-only'
				? showReinvestAdminInfo.amountPdl?.toString()
				: showReinvestAdminInfo.liquidationFields.toPay?.toString(),
		)

		setAmountToReinvest(reinvestingAmount)
		setPendingReinvestmentAmount(parseFloat(reinvestingAmount).toFixed(2) - totalAmountInvested.toFixed(2))
	}
	const handleChange = (values, item) => {
		const projectExists = changeProjectData.some((project) => project._id === item._id)

		const updateProject = changeProjectData.map((project) => {
			if (project._id === item._id) {
				return { ...project, reinvestmentAmount: values.value }
			}
			return project
		})

		if (!projectExists) {
			updateProject.push({ ...item, reinvestmentAmount: values.value })
		}

		setChangeProjectData(updateProject)

		handleParams(updateProject)
		if (values.value > item.amountInvertible) {
			setError({
				message: `El monto maximo invertible es ${matchNumberToCurrency(item.amountInvertible)}`,
				show: true,
			})
		} else {
			setError({
				messaje: ``,
				show: false,
			})
		}
	}
	const handleDateReinvest = (value, item) => {
		const projectExists = changeProjectData.some((project) => project._id === item._id)

		const updateProject = changeProjectData.map((project) => {
			if (project._id === item._id) {
				return { ...project, dateReinvest: value }
			}
			return project
		})

		if (!projectExists) {
			updateProject.push({ ...item, dateReinvest: value })
		}

		setChangeProjectData(updateProject)
	}
	const handleInvestmentData = () => {
		if (!error.show) {
			if (parseInt(pendingReinvestmentAmount) > 0) {
				return setError({
					message: `Faltan ${matchNumberToCurrency(pendingReinvestmentAmount)} por invertir`,
					show: true,
				})
			}
			const reinvestments = []
			changeProjectData.forEach((project) => {
				const amount = matchCurrencyToDecimal(project.reinvestmentAmount.toString())
				const isValidAmount = Number(project?.reinvestmentAmount || 0) > 0
				if (isValidAmount) {
					reinvestments.push({
						projectId: project._id,
						amount: amount,
						dateReinvest: project?.dateReinvest || new Date(),
						amountInWords: amountInWords(matchCurrencyToDecimal(project.reinvestmentAmount.toString()), 'EUROS'),
						profileId: showReinvestAdminInfo?.profileInvestor?._id,
					})
				}
			})
			const projectsToReinvest = {
				pdlId: showReinvestAdminInfo._id,
				reinvestments,
				sendContract,
			}
			dispatch(registerReinvestPdlInvestments(projectsToReinvest))
		}
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={openReinvestmentModal}
			keepMounted
			onClose={handleCloseReinvestAdminModal}
			aria-labelledby='notice-modal-slide-title'
			aria-describedby='notice-modal-slide-description'
		>
			<DialogTitle id='notice-modal-slide-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={handleCloseReinvestAdminModal}
				>
					<Close className={classes.modalClose} />
				</Button>
				Reinvertir {showReinvestAdminInfo?.liquidation === 'benefits-only' ? ' Importe Inicial' : 'todo'}
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				{loadingProjectList ? (
					<GridContainer>
						<GridItem xs={12}>
							<Loader
								message={'Cargando proyecto'}
								config={{ marginTop: '30px', with: '100%', alignItems: 'center' }}
							/>
						</GridItem>
					</GridContainer>
				) : (
					<>
						<div>
							<span className={classes.spanTitle}>€ A Reinvertir:</span>
							<span className={classes.spanTitle}>
								<b>{matchNumberToCurrency(amountToReinvest)}</b>
							</span>
						</div>
						<div>
							<span className={classes.spanTitle}>€ Pendientes de Reinvertir: </span>
							<span className={`${classes.spanTitle} ${pendingReinvestmentAmount < 0 ? classes.spanError : ''}`}>
								<b>{matchNumberToCurrency(pendingReinvestmentAmount)}</b>
							</span>
						</div>
						{userInfo.isAdmin || userInfo.isSuper ? (
							<FormControlLabel
								control={
									<Switch
										checked={sendContract}
										classes={{
											switchBase: classes.switchBase,
											checked: classes.switchChecked,
											thumb: classes.switchIcon,
											track: classes.switchBar,
										}}
										onChange={(e) => setSendContract(e.target.checked)}
									/>
								}
								label='Enviar contrato'
							/>
						) : null}
						<ReInvestTable
							data={projectdata}
							projectData={changeProjectData}
							handleChange={handleChange}
							handleDateReinvest={handleDateReinvest}
						/>
					</>
				)}
			</DialogContent>
			<DialogActions className={classes.modalFooter}>
				<GridContainer>
					{error.show && (
						<GridItem xs={12}>
							<SnackbarContent sx={{ width: 10 }} message={error.message} color='warning' />
						</GridItem>
					)}
					<GridItem xs={12}>
						<Button
							color={successPdlRegisterReinvestPdlInvestments ? 'success' : 'primary'}
							onClick={handleInvestmentData}
							disabled={error.show || loadingPdlRegisterReinvestPdlInvestments}
							block
						>
							{loadingPdlRegisterReinvestPdlInvestments ? 'Reinvirtiendo...' : 'Reinvertir'}
						</Button>
					</GridItem>
				</GridContainer>
			</DialogActions>
			{alert}
		</Dialog>
	)
}

export default ReinvestmentModalPdlScreen
