import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { makeStyles, FormControlLabel } from '@material-ui/core'
import { Switch } from '@mui/material'
import { Assignment } from '@mui/icons-material'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import InvestmentByAdminModal from 'components/InvestmentByAdminModal/InvestmentByAdminModal'
import ModalConfirmationInvestment from 'components/ModalConfirmationInvestment/ModalConfirmationInvestment'
import ReactVirtualizedTable from 'components/ReactVirtualizedTable/ReactVirtualizedTable'
import ConfirmCSVRemake from './components/ConfirmCSVRemake'
import ConfirmCSVPDLRemake from './components/ConfirmCSVPDLRemake'
import DeleteProjectModal from './components/DeleteProjectModal'
import DownloadCsvModal from './components/DownloadCsvModal'
import UploadDocumentModal from './components/UploadDocumentModal'
import ProjectUpdateScreen from './components/ProjectUpdateScreen'
import { projectListColumns } from './helpers/projectListColumns'
import { projectListMapper } from './mappers/projectListMapper'
import {
	PROJECT_LIST_RESET,
	PROJECT_BY_ID_RESET,
	PROJECT_REBUILD_CSV_RESET,
} from 'local_redux/constants/projectConstant'
import { getAllProjects } from 'local_redux/actions/projectAction'
import styles from './styles/projectListStyles'

const useStyles = makeStyles(styles)

const ProjectListScreen = () => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const navigate = useNavigate()
	const search = useLocation().search
	const projectQuery = new URLSearchParams(search).get('project')

	const [data, setData] = useState([])
	const [deleteProjectModal, setDeleteProjectModal] = useState(false)
	const [showDeleteProjectInfo, setShowDeleteProjectInfo] = useState({})
	const [investmentByAdminModal, setInvestmentByAdminModal] = useState(false)
	const [showInvestmentByAdminId, setShowInvestmentByAdminId] = useState('')
	const [uploadDocumentModal, setUploadDocumentModal] = useState(false)
	const [showUploadDocumentInfo, setShowUploadDocumentInfo] = useState('')
	const [downloadCsvModal, setDownloadCsvModal] = useState(false)
	const [infoDownloadCsvModal, setInfoDownloadCsvModal] = useState([])
	const [project, setProject] = useState([])
	const [idProject, setIdProject] = useState('')
	const [alert, setAlert] = useState(null)
	const [checked, setChecked] = useState(false)
	const [confirmCsvRemake, setConfirmCsvRemake] = useState(null)
	const [confirmCSVPDLRemake, setConfirmCSVPDLRemake] = useState(null)

	const { loadingProjectList, successProjectList, projectListData, errorProjectList } = useSelector(
		({ projectList }) => projectList,
	)
	const { investmentRegisterData, investmentRegisterSuccess } = useSelector((state) => state.investmentRegister)
	const { successProjectDelete } = useSelector((state) => state.projectDelete)
	const { investorsProfileListData } = useSelector((state) => state.investorsProfileList)
	const { successProjectRebuildCsv, errorProjectRebuildCsv } = useSelector((state) => state.projectRebuildCsv)
	const { userInfo } = useSelector(({ userLogin }) => userLogin)
	const { successProjectUpdate, projectUpdateData } = useSelector((state) => state.projectUpdate)
	const { successRebuildProjectLiquidity, rebuildProjectLiquidity, errorRebuildProjectLiquidity } = useSelector(
		(state) => state.rebuildProjectLiquidity,
	)

	useEffect(() => {
		dispatch(getAllProjects(checked))
	}, [checked])
	useEffect(() => {
		if (successProjectUpdate) {
			const projectsUpdated = project.map((projectSel) => {
				if (projectSel._id === projectUpdateData._id) {
					return {
						...projectSel,
						...projectUpdateData,
					}
				} else {
					return projectSel
				}
			})
			setProject(projectsUpdated)
		}
	}, [successProjectUpdate])
	useEffect(() => {
		if (projectListData) {
			setProject(projectListData)
		}
	}, [projectListData])
	useEffect(() => {
		if (successRebuildProjectLiquidity) {
			setProject((prev) => {
				return prev.map((item) => {
					if (item.id === rebuildProjectLiquidity.projectId) {
						return { ...item, pdlLiquidate: { ...item.pdlLiquidate, csvFilePath: rebuildProjectLiquidity.newFilePath } }
					}
					return item
				})
			})
		}
	}, [successRebuildProjectLiquidity])
	useEffect(() => {
		if (successProjectList) {
			const projectList = projectListMapper({ project })
			const filterData = projectList.filter((item) => item.status !== 'Liquidado' && item.status !== 'Finalizado')
			checked ? setData(filterData) : setData(projectList)
		}
	}, [project, setIdProject, successProjectList, checked])
	useEffect(() => {
		if (successProjectDelete) {
			if (idProject) {
				const projects = project.filter((data) => data._id !== idProject)
				setProject(projects)
			}
		}
	}, [successProjectDelete, setProject])
	useEffect(() => {
		return () => {
			dispatch({ type: PROJECT_REBUILD_CSV_RESET })
			dispatch({ type: PROJECT_LIST_RESET })
			dispatch({ type: PROJECT_BY_ID_RESET })
		}
	}, [dispatch])
	useEffect(() => {
		if (successProjectRebuildCsv) {
			dispatch({ type: PROJECT_REBUILD_CSV_RESET })
		}
	}, [successProjectRebuildCsv])

	const showDeleteInfoHandler = (id) => {
		const deleteProject = project.find((item) => item._id === id)
		setShowDeleteProjectInfo(deleteProject)
		setDeleteProjectModal(true)
	}
	const handleCloseDeleteProjectModal = () => {
		setDeleteProjectModal(false)
		setShowDeleteProjectInfo({})
	}
	const showInvesmentByAdminHandler = (id) => {
		setShowInvestmentByAdminId(id)
		setInvestmentByAdminModal(true)
	}
	const handleCloseUploadDocumentModal = () => {
		setUploadDocumentModal(false)
		setShowUploadDocumentInfo({})
	}
	const showUploadDocumentModal = (project) => {
		setShowUploadDocumentInfo(project)
		setUploadDocumentModal(true)
	}
	const handleCloseInvestmentByAdminModal = () => {
		setInvestmentByAdminModal(false)
		if (investmentRegisterSuccess === true) {
			let nameText = ''
			investorsProfileListData.map(
				(investor) => investor._id === investmentRegisterData.investorProfile && (nameText = investor.businessName),
			)

			const dataText = `En unos minutos el usuario ${nameText} recibirá un correo electronico con el contrato a su nombre para que pueda firmarlo vía docuSign. En él encontrará también las instrucciones para realizar el ingreso. Por favor recuerdele al usuario que la inversión será efectiva desde que el contrato este firmado y el importe de inversión se hace efectivo en nuestra cuenta corriente.`
			setAlert(
				<ModalConfirmationInvestment
					title={'Inversión realizada correctamente!'}
					text={dataText}
					setAlert={setAlert}
				/>,
			)
		}

		setShowInvestmentByAdminId('')
	}
	const handleOpenDownloadModal = (csvData) => {
		setDownloadCsvModal(true)
		setInfoDownloadCsvModal(csvData)
	}
	const handleCloseDownloadModal = () => {
		setDownloadCsvModal(false)
		setInfoDownloadCsvModal([])
	}

	return (
		<>
			<GridContainer>
				<GridItem xs={12}>
					{!projectQuery ? (
						<Card>
							<CardHeader color='primary' icon>
								<CardIcon color='primary'>
									<Assignment />
								</CardIcon>

								<FormControlLabel
									style={{ padding: '10px', marginLeft: 0 }}
									labelPlacement='start'
									label='Inmuebles en nuestro activo'
									classes={{
										label: classes.label,
									}}
									control={
										<Switch
											checked={checked}
											onChange={(e) => setChecked(e.target.checked)}
											value='ourProjects'
											color='success'
											classes={{
												switchBase: classes.switchBase,
												thumb: classes.switchIcon,
												track: classes.switchBar,
											}}
										/>
									}
								/>
							</CardHeader>
							<CardBody>
								<ReactVirtualizedTable
									data={data}
									columns={projectListColumns({
										userInfo,
										setConfirmCsvRemake,
										setConfirmCSVPDLRemake,
										handleOpenDownloadModal,
										showUploadDocumentModal,
										showInvesmentByAdminHandler,
										showDeleteInfoHandler,
										setIdProject,
										navigate,
										successProjectRebuildCsv,
										errorProjectRebuildCsv,
										successRebuildProjectLiquidity,
										errorRebuildProjectLiquidity,
										classes,
									})}
									loading={Boolean(loadingProjectList)}
									success={Boolean(successProjectList)}
									error={errorProjectList}
								/>
							</CardBody>
						</Card>
					) : (
						<ProjectUpdateScreen project={projectQuery} />
					)}
				</GridItem>
			</GridContainer>
			{deleteProjectModal && (
				<DeleteProjectModal
					handleCloseDeleteProjectModal={handleCloseDeleteProjectModal}
					deleteProjectModal={deleteProjectModal}
					showDeleteProjectInfo={showDeleteProjectInfo}
				/>
			)}
			{confirmCsvRemake && (
				<ConfirmCSVRemake
					closeModal={() => setConfirmCsvRemake(null)}
					item={confirmCsvRemake}
					openModal={confirmCsvRemake}
				/>
			)}
			{confirmCSVPDLRemake && (
				<ConfirmCSVPDLRemake
					closeModal={() => setConfirmCSVPDLRemake(null)}
					item={confirmCSVPDLRemake}
					openModal={!!confirmCSVPDLRemake}
				/>
			)}
			{investmentByAdminModal && (
				<InvestmentByAdminModal
					handleCloseInvestmentByAdminModal={handleCloseInvestmentByAdminModal}
					investmentByAdminModal={investmentByAdminModal}
					showInvestmentByAdminId={showInvestmentByAdminId}
				/>
			)}
			{uploadDocumentModal && (
				<UploadDocumentModal
					handleCloseUploadDocumentModal={handleCloseUploadDocumentModal}
					uploadDocumentModal={uploadDocumentModal}
					showUploadDocumentInfo={showUploadDocumentInfo}
					project={project}
					setProject={setProject}
				/>
			)}
			{downloadCsvModal && (
				<DownloadCsvModal
					downloadModal={downloadCsvModal}
					handleCloseDownloadModal={handleCloseDownloadModal}
					showCsvInfo={infoDownloadCsvModal}
				/>
			)}
			{alert}
		</>
	)
}

export default ProjectListScreen
