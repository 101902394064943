import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle'
import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle'
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js'

const styles = (theme) => ({
	...modalStyle(theme),
	...customCheckboxRadioSwitch,
	dialogContent: {
		overflow: 'auto',
		maxWidth: '100vw',
	},
	spanTitle: {
		margin: 10,
	},
	spanError: {
		color: 'red',
	},
	hoverCell: {
		'&:hover': {
			color: 'black',
			fontWeight: '400',
		},
	},
	...customSelectStyle,
	pagPosition: {
		position: 'sticky',
		top: '0',
		left: '0',
	},
	inputFormat: {
		background: 'transparent',
		border: 'none',
		borderBottom: '2px solid #c0bbac',
	},
	gridContainer: {
		justifyContent: 'center',
	},
	trWidthBig: {
		minWidth: '100% !important',
	},
	widthBig: {
		minWidth: '170px !important',
	},
	confirmBtnCssClass: {
		backgroundColor: '#ebe6e2',
		color: '#333333',
		padding: '10px',
		width: '5rem',
		borderRadius: '3px',
		'&:hover': {
			color: '#333333',
		},
	},
	dateInput: {
		display: 'flex',
		width: 'auto',
		'& .MuiInput-underline:before': {
			borderBottom: '1px solid #d2d2d2',
		},
		'& .MuiInputBase-input': {
			padding: '0px',
		},
		
	},
})

export default styles
