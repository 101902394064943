import { Box, makeStyles, Typography } from '@material-ui/core'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import styles from '../styles/projectDetailsSkeletonStyles'

const useStyles = makeStyles(styles)

const ProjectDetailsSkeleton = () => {
  const classes = useStyles()
  return (
    <>
      <Stack direction='row' justifyContent='center' alignItems='center' spacing={12}>
        <Box sx={{ width: '90%' }}>
          <Skeleton animation='wave' variant='rectangular' height={200} />
        </Box>
      </Stack>
      <Stack justifyContent='center' alignItems='center' spacing={12}>
        <Box sx={{ width: '30%' }}>
          <Typography variant='body1'>
            <Skeleton animation='wave' variant='text' />
          </Typography>
        </Box>
      </Stack>
      <Stack direction={{ xs: 'column', sm: 'row' }}>
        <Box sx={{ width: { xs: '100%', sm: '50%' } }} className={classes.tirBox}>
          <Box sx={{ width: '70%' }}>
            <Typography variant='h4'>
              <Skeleton animation='wave' variant='text' />
            </Typography>
          </Box>

          <Box sx={{ width: '70%' }}>
            <Typography variant='h4'>
              <Skeleton animation='wave' variant='text' />
            </Typography>
          </Box>

          <Box sx={{ width: '40%' }}>
            <Typography variant='body1'>
              <Skeleton animation='wave' variant='text' />
            </Typography>
          </Box>
          <Box sx={{ width: '60%' }}>
            <Typography variant='h4'>
              <Skeleton animation='wave' variant='text' />
            </Typography>
          </Box>
          <Box sx={{ width: '40%' }}>
            <Typography variant='body1'>
              <Skeleton animation='wave' variant='text' />
            </Typography>
          </Box>
        </Box>
        <Box sx={{ width: { xs: '100%', sm: '50%' } }} className={classes.tirBox}>
          <Box sx={{ width: '70%' }}>
            <Typography variant='h4'>
              <Skeleton animation='wave' variant='text' />
            </Typography>
          </Box>

          <Box sx={{ width: '70%' }}>
            <Typography variant='h4'>
              <Skeleton animation='wave' variant='text' />
            </Typography>
          </Box>

          <Box sx={{ width: '40%' }}>
            <Typography variant='body1'>
              <Skeleton animation='wave' variant='text' />
            </Typography>
          </Box>
          <Box sx={{ width: '60%' }}>
            <Typography variant='h4'>
              <Skeleton animation='wave' variant='text' />
            </Typography>
          </Box>
          <Box sx={{ width: '40%' }}>
            <Typography variant='body1'>
              <Skeleton animation='wave' variant='text' />
            </Typography>
          </Box>
        </Box>
      </Stack>

      <Box sx={{ width: '60%' }}>
        <Typography variant='h4'>
          <Skeleton animation='wave' variant='text' />
        </Typography>
      </Box>
      <Box sx={{ width: '40%' }}>
        <Typography variant='body1'>
          <Skeleton animation='wave' variant='text' />
        </Typography>
      </Box>
      <Box sx={{ width: '30%' }}>
        <Typography variant='h4'>
          <Skeleton animation='wave' variant='text' />
        </Typography>
      </Box>
      <Box sx={{ width: '40%' }}>
        <Typography variant='body1'>
          <Skeleton animation='wave' variant='text' />
        </Typography>
      </Box>
      <Skeleton animation='wave' variant='rectangular' height={30} />
    </>
  )
}
export default ProjectDetailsSkeleton
