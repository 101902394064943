const dashboardStyle = {
  marginAuto: {
    margin: '0% auto',
  },
  rootCard: {
    marginTop: '10px',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh',
    width: '100%',
  },
  width100: {
    width: '100%',
  },
  noMargin: {
    marginTop: '0 !important',
  },
  infiniteItem: {
    marginTop: '3.5rem',
  },
}

export default dashboardStyle
