import { Visibility, Delete as DeleteIcon } from '@mui/icons-material'
import Button from 'components/CustomButtons/Button'

export const ownersUsersListColumns = ({ navigate, showDeleteInfoHandler, setIdOwnerUser }) => {
	return [
		{
			header: 'Nombres',
			accessor: 'businessName',
		},
		{
			header: 'Email',
			accessor: 'email',
		},
		{
			header: 'Actions',
			accessor: 'actions',
			cellAlign: 'right',
			headerAlign: 'right',
			cell: ({ row }) => (
				<div className='actions-right'>
					<Button
						justIcon
						round
						simple
						onClick={() => navigate(`/admin/owners-list-user?ownerId=${row.id}`)}
						color='success'
						className='edit'
					>
						<Visibility />
					</Button>
					<Button
						justIcon
						round
						simple
						onClick={() => [showDeleteInfoHandler(row.id), setIdOwnerUser(row.id)]}
						color='danger'
						className='delete'
					>
						<DeleteIcon />
					</Button>
				</div>
			),
		},
	]
}
