import { useEffect, createRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import { Close, PictureAsPdf, Check, Delete, Visibility } from '@material-ui/icons'
import Typography from '@mui/material/Typography'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'
import { registerEndOfProjectDocument, deleteEndOfProjectDocument } from 'local_redux/actions/endOfProjectDocument'
import {
	END_OF_PROJECT_DOCUMENT_REGISTER_RESET,
	END_OF_PROJECT_DOCUMENT_DELETE_RESET,
} from 'local_redux/constants/endOfProjectDocumentConstants'
import styles from '../styles/uploadDocumentModalStyles'

const useStyles = makeStyles(styles)

const UploadDocumentModal = ({
	handleCloseUploadDocumentModal,
	uploadDocumentModal,
	showUploadDocumentInfo,
	project,
	setProject,
}) => {
	const dispatch = useDispatch()
	const simplePdfFile = createRef()
	const classes = useStyles()

	const [deleteConfirmation, setDeleteonfirmation] = useState(false)
	const [projectPdf, setProjectPdf] = useState('')

	const { userInfo } = useSelector((state) => state.userLogin)
	const {
		loadingEndOfProjectDocumentRegister,
		successEndOfProjectDocumentRegister,
		errorEndOfProjectDocumentRegister,
		endOfProjectDocumentRegisterData,
	} = useSelector((state) => state.endOfProjectDocumentRegister)
	const {
		loadingEndOfProjectDocumentDelete,
		successEndOfProjectDocumentDelete,
		errorEndOfProjectDocumentDelete,
		endOfProjectDocumentDeleteData,
	} = useSelector((state) => state.endOfProjectDocumentDelete)

	useEffect(() => {
		let timeOut = ''
		if (successEndOfProjectDocumentRegister) {
			timeOut = setTimeout(() => {
				const data = project.map((item) => {
					if (item._id === showUploadDocumentInfo._id) {
						item.endofprojectdocumentsList = [item.endofprojectdocumentsList, endOfProjectDocumentRegisterData].flat(
							Infinity,
						)
					}
					return item
				})
				setProject(data)
				dispatch({ type: END_OF_PROJECT_DOCUMENT_REGISTER_RESET })
				handleCloseUploadDocumentModal()
			}, 1500)
		}
		return () => clearTimeout(timeOut)
	}, [successEndOfProjectDocumentRegister])
	useEffect(() => {
		let timeOut = ''
		if (successEndOfProjectDocumentDelete) {
			const data = project.map((item) => {
				if (item._id === showUploadDocumentInfo._id) {
					item.endofprojectdocumentsList = item.endofprojectdocumentsList.filter(
						(dataDocument) => dataDocument._id !== endOfProjectDocumentDeleteData._id,
					)
				}
				return item
			})
			setProject(data)
			timeOut = setTimeout(() => {
				setDeleteonfirmation(false)
				dispatch({ type: END_OF_PROJECT_DOCUMENT_DELETE_RESET })
			}, 1500)
		}
		return () => clearTimeout(timeOut)
	}, [successEndOfProjectDocumentDelete])

	const selectFileHandler = () => {
		simplePdfFile.current.click()
	}

	const handleFileChange = (e) => {
		e.preventDefault()
		setProjectPdf(e.target.files)
	}

	const handleFileRemove = () => {
		setProjectPdf('')
	}

	const handleDeleteEndOfProjectDocuments = () => {
		setDeleteonfirmation(true)
	}

	const handleUploadDocumet = (e) => {
		e.preventDefault()
		dispatch(
			registerEndOfProjectDocument({
				projectId: showUploadDocumentInfo._id,
				endOfProjectDossier: projectPdf,
			}),
		)
	}

	const handleDeleteDocument = (documentId) => {
		dispatch(deleteEndOfProjectDocument(documentId))
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={uploadDocumentModal}
			keepMounted
			onClose={handleCloseUploadDocumentModal}
		>
			{deleteConfirmation ? (
				<>
					<DialogTitle id='project-modal-delete-title' disableTypography className={classes.modalHeader}>
						<GridContainer style={{ display: 'flex', justifyContent: 'space-around' }}>
							<GridItem xs={10}>
								<h4>Eliminar documento de fin de proyecto</h4>
							</GridItem>
							<GridItem xs={2} className={classes.closeButtonContainer}>
								<Button
									justIcon
									className={classes.closeButton}
									key='close'
									aria-label='Close'
									color='transparent'
									onClick={handleCloseUploadDocumentModal}
								>
									<Close className={classes.modalClose} />
								</Button>
							</GridItem>
						</GridContainer>
					</DialogTitle>
					<form>
						<DialogContent id='project-modal-delete-description' className={classes.modalBody}>
							<GridContainer>
								<GridItem xs={12}>
									<Typography>
										Está seguro que quiere eliminar el documento <b>Dossier fin de proyecto</b> relacionado con la
										finalización del proyecto <b>{`${showUploadDocumentInfo.projectAddress}`}</b>
									</Typography>
								</GridItem>
							</GridContainer>
						</DialogContent>
						{errorEndOfProjectDocumentDelete && (
							<GridContainer>
								<GridItem xs={12}>
									<SnackbarContent message={errorEndOfProjectDocumentDelete} color='danger' />
								</GridItem>
							</GridContainer>
						)}
						<DialogActions className={classes.modalFooter}>
							<GridContainer>
								<GridItem xs={12}>
									<Button onClick={() => setDeleteonfirmation(false)}>Cancelar</Button>
									<Button
										onClick={() => handleDeleteDocument(showUploadDocumentInfo?.endofprojectdocumentsList[0]._id)}
										disabled={loadingEndOfProjectDocumentDelete}
										color={successEndOfProjectDocumentDelete ? 'success' : 'primary'}
									>
										{loadingEndOfProjectDocumentDelete
											? 'Eliminando'
											: successEndOfProjectDocumentDelete
											? 'Eliminado'
											: 'Eliminar'}
									</Button>
								</GridItem>
							</GridContainer>
						</DialogActions>
					</form>
				</>
			) : (
				<form onSubmit={handleUploadDocumet}>
					<DialogTitle id='project-modal-upload-title' disableTypography className={classes.modalHeader}>
						<GridContainer>
							<GridItem xs={10}>
								<h4>Subir documentos de fin de proyecto</h4>
							</GridItem>
							<GridItem xs={2} className={classes.closeButtonContainer}>
								<Button
									justIcon
									className={classes.closeButton}
									key='close'
									aria-label='Close'
									color='transparent'
									onClick={handleCloseUploadDocumentModal}
								>
									<Close className={classes.modalClose} />
								</Button>
							</GridItem>
						</GridContainer>
					</DialogTitle>
					<DialogContent id='project-modal-upload-description'>
						<GridContainer style={{ marginBottom: '20px' }}>
							<GridItem xs={12} style={{ marginBottom: '40px' }}>
								<Typography>
									En esta sección usted puede subir documentos relacionados con la finalización del proyecto{' '}
									<b>{`${showUploadDocumentInfo.projectAddress}`}</b>
								</Typography>
							</GridItem>
							<GridItem xs={9} style={{ fontSize: '23px', display: 'flex', alignItems: 'center' }}>
								<h6>Dossier fin de proyecto</h6>
							</GridItem>
							<GridItem xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<div className='fileinput'>
									<input
										type='file'
										name='pdf'
										accept='.pdf,'
										id='simple-pdf'
										ref={simplePdfFile}
										onChange={handleFileChange}
									/>
								</div>
								{showUploadDocumentInfo?.endofprojectdocumentsList
									.map((item) => item?.documentName)
									.includes('endOfProjectDossier') ? (
									<>
										<Button
											color='success'
											size='md'
											justIcon
											round
											onClick={() =>
												window.open(
													getUrlToOpenFile(showUploadDocumentInfo.endofprojectdocumentsList[0].documentPath, userInfo),
												)
											}
										>
											<Visibility />
										</Button>
										<Button color='danger' size='md' justIcon round onClick={() => handleDeleteEndOfProjectDocuments()}>
											<Delete />
										</Button>
									</>
								) : (
									<>
										<Button
											color={typeof projectPdf === 'object' ? 'success' : 'primary'}
											size='md'
											justIcon
											round
											disabled={typeof projectPdf === 'object'}
											onClick={() => selectFileHandler()}
										>
											{typeof projectPdf === 'object' ? <Check /> : <PictureAsPdf />}
										</Button>
										{typeof projectPdf === 'object' && (
											<Button
												color='danger'
												size='md'
												justIcon
												round
												onClick={() => handleFileRemove()}
												style={{ marginLeft: '10px' }}
											>
												<Delete />
											</Button>
										)}
									</>
								)}
							</GridItem>
						</GridContainer>
						{errorEndOfProjectDocumentRegister && (
							<GridContainer>
								<GridItem xs={12}>
									<SnackbarContent message={errorEndOfProjectDocumentRegister} color='danger' />
								</GridItem>
							</GridContainer>
						)}
					</DialogContent>
					{showUploadDocumentInfo?.endofprojectdocumentsList
						.map((item) => item?.documentName)
						.includes('endOfProjectDossier') ? null : (
						<DialogActions className={classes.modalFooter}>
							<GridContainer>
								<GridItem xs={12}>
									<Button
										type='onSubmit'
										block
										disabled={loadingEndOfProjectDocumentRegister || !projectPdf}
										color={successEndOfProjectDocumentRegister ? 'success' : 'primary'}
									>
										{loadingEndOfProjectDocumentRegister
											? 'Guardando'
											: successEndOfProjectDocumentRegister
											? 'Guardado'
											: 'Guardar'}
									</Button>
								</GridItem>
							</GridContainer>
						</DialogActions>
					)}
				</form>
			)}
		</Dialog>
	)
}

export default UploadDocumentModal
