import { cardTitle } from 'assets/jss/material-dashboard-pro-react'

const styles = {
	confirmBtnCssClass: {
		backgroundColor: '#c0bbac',
		color: '#fff',
		padding: '10px',
		width: '5rem',
		borderRadius: '3px',
		'&:hover': {
			color: '#fff',
		},
	},
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
		textTransform: 'uppercase',
		fontWeight: 500,
	},
}

export default styles
