import { useEffect, useMemo, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import LinearProgress from '@mui/material/LinearProgress'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { slowDownloadAndZipPDFs } from 'shared/helpers/azure-files-handlers'
import styles from '../styles/downloadStatusModalStyles'

const useStyles = makeStyles(styles)

const DownloadStatusModal = ({ openModal, closeModal, urls, setUrlsToDownload, zipFileName }) => {
	const classes = useStyles()

	const [totalFiles, setTotalFiles] = useState(0)
	const [endsFiles, setEndsFiles] = useState(0)
	const [errorFiles, setErrorFiles] = useState([])
	const [alert, setAlert] = useState(null)

	const progress = useMemo(() => {
		let unitPercentage = 0
		if (totalFiles > 0) {
			unitPercentage = 100 / totalFiles
		}

		return endsFiles > 0 ? Math.round(endsFiles * unitPercentage) : 0
	}, [endsFiles, totalFiles])

	useEffect(() => {
		if (Boolean(urls?.length)) {
			setEndsFiles(0)
			setTotalFiles(urls.length)
		}
	}, [urls])
	useEffect(() => {
		const total = urls.length
		if (total) {
			// eslint-disable-next-line no-use-before-define
			slowDownloadAndZipPDFs(urls, zipFileName, addOneEndFile, addOneErrorFile)
		}
	}, [urls])
	useEffect(() => {
		if (endsFiles > 0 && totalFiles > 0 && endsFiles === totalFiles) {
			setAlert(
				<SweetAlert
					success
					style={{ display: 'block', marginTop: '-100px', textAlign: 'center' }}
					title='Hecho!'
					// eslint-disable-next-line no-use-before-define
					onConfirm={closeAlert}
					confirmBtnCssClass={classes.confirmBtnCssClass}
				>
					La descarga termino exitosamente!
				</SweetAlert>,
			)
		}
	}, [endsFiles])

	const closeAlert = () => {
		closeModal()
		setUrlsToDownload([])
	}
	const addOneEndFile = () => {
		setEndsFiles((prev) => prev + 1)
	}
	const addOneErrorFile = (blobName) => {
		setErrorFiles((prev) => [...prev, blobName])
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={openModal}
			keepMounted
			aria-labelledby='download-status-modal-title'
			aria-describedby='download-status-modal-description'
		>
			<DialogTitle id='download-status-modal-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={closeModal}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4>Descargando Contratos</h4>
			</DialogTitle>
			<DialogContent id='download-status-modal-description' className={classes.modalBody}>
				<GridContainer>
					<GridItem xs={12}>Este proceso puede tardar unos minutos, por favor no cierre la modal.</GridItem>
					<GridItem xs={12} style={{ marginTop: '10px' }}>
						<LinearProgress variant='determinate' value={progress} color='primary' />
					</GridItem>
					<GridItem xs={12}>
						<span>{`${progress}% (${endsFiles} / ${totalFiles})`}</span>
					</GridItem>
					<GridItem xs={12}>
						{Boolean(errorFiles.length) && <span>Archivos fallidos: {errorFiles.length}</span>}
					</GridItem>
				</GridContainer>
			</DialogContent>
			<DialogActions className={classes.modalFooter}>
				<GridContainer>
					<GridItem xs={12}>
						<Button color='primary' onClick={closeModal}>
							Cancelar
						</Button>
					</GridItem>
				</GridContainer>
			</DialogActions>
			{alert}
		</Dialog>
	)
}

export default DownloadStatusModal
