import { useEffect, useState } from 'react'
import { Close } from '@material-ui/icons'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { rebuildProjectCsv } from 'local_redux/actions/projectAction'
import { PROJECT_REBUILD_CSV_RESET } from 'local_redux/constants/projectConstant'
import styles from '../styles/confirmCSVRemakeStyles'

const useStyles = makeStyles(styles)

const ConfirmCSVRemake = ({ closeModal, openModal, item }) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const [alert, setAlert] = useState(null)

	const { successProjectRebuildCsv, loadingProjectRebuildCsv, errorProjectRebuildCsv } = useSelector(
		(state) => state.projectRebuildCsv,
	)

	const handleConfirm = () => {
		dispatch(rebuildProjectCsv(item._id, item?.csvFilePath))
	}

	useEffect(() => {
		return () => {
			dispatch({ type: PROJECT_REBUILD_CSV_RESET })
		}
	}, [])

	useEffect(() => {
		if (successProjectRebuildCsv) {
			setAlert(
				<SweetAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='¡Hecho!'
					onConfirm={() => closeModal()}
					confirmBtnCssClass={classes.confirmBtnCssClass}
				>
					<div style={{ textAlign: 'center' }}>CSV Regenerado exitosamente!</div>
				</SweetAlert>,
			)
		}
	}, [successProjectRebuildCsv])

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={openModal}
			keepMounted
			onClose={closeModal}
			aria-labelledby='confirm-update-finished-date-title'
			aria-describedby='confirm-update-finished-date-description'
		>
			<DialogTitle id='confirm-update-finished-date-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={closeModal}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4>¿Esta seguro de rehacer el CSV?</h4>
			</DialogTitle>

			<DialogContent id='confirm-update-finished-date-description' className={classes.modalBody}>
				{errorProjectRebuildCsv && (
					<GridContainer>
						<GridItem xs={12}>
							<SnackbarContent message={errorProjectRebuildCsv} color='danger' />
						</GridItem>
					</GridContainer>
				)}
			</DialogContent>
			<DialogActions className={classes.modalFooter}>
				<GridContainer>
					<GridItem xs={6}>
						<Button onClick={closeModal} block>
							Cancelar
						</Button>
					</GridItem>
					<GridItem xs={6}>
						<Button
							block
							type='submit'
							color={successProjectRebuildCsv ? 'success' : 'primary'}
							onClick={handleConfirm}
						>
							{loadingProjectRebuildCsv ? 'Regenerando...' : successProjectRebuildCsv ? 'CSV Regenerado' : 'Confirmar'}
						</Button>
					</GridItem>
				</GridContainer>
			</DialogActions>
			{alert}
		</Dialog>
	)
}

export default ConfirmCSVRemake
