const styles = {
  root: {
    margin: '0 auto',
  },
  confirmBtnCssClass: {
    backgroundColor: '#ebe6e2',
    color: '#333333',
    padding: '10px',
    width: '5rem',
    borderRadius: '3px',
    '&:hover': {
      color: '#333333',
    },
  },

  registerButton: {
    marginTop: '50px',
  },
}

export default styles
