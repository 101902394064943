import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, makeStyles, CircularProgress } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { getRetentionCertificateByInvestmentId } from 'local_redux/actions/investmentActions'
import { GET_RETENTION_CERTIFICATE_BY_INVESTMENT_RESET } from 'local_redux/constants/investmentConstants'
import styles from '../styles/generateRCModalStyles'

const useStyles = makeStyles(styles)

const GenerateRCModal = ({ open, close, id }) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const {
		loadingRetentionCertificateByInvestmentId,
		successRetentionCertificateByInvestmentId,
		retentionCertificate,
		errorRetentionCertificateByInvestmentId,
	} = useSelector((state) => state.retentionCertificateByInvestmentId)

	useEffect(() => {
		if (id) {
			dispatch(getRetentionCertificateByInvestmentId(id))
		}
		return () => {
			dispatch({ type: GET_RETENTION_CERTIFICATE_BY_INVESTMENT_RESET })
		}
	}, [id])
	useEffect(() => {
		if (successRetentionCertificateByInvestmentId && retentionCertificate?.certificate) {
			let pdfWindow = window.open('')
			pdfWindow.document.write(
				"<iframe width='100%' height='100%' src='" + encodeURI(retentionCertificate?.certificate) + "'></iframe>",
			)
			close()
		}
	}, [successRetentionCertificateByInvestmentId])

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={open}
			keepMounted
			onClose={close}
			aria-labelledby='investment-modal-download-title'
			aria-describedby='investment-modal-download-description'
		>
			<DialogTitle id='investment-modal-download-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={close}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4>Certificado de Retenciones</h4>
			</DialogTitle>

			<DialogContent id='investment-modal-download-description' className={classes.modalBody}>
				<GridContainer>
					{loadingRetentionCertificateByInvestmentId ? (
						<>
							<GridItem xs={12} className={classes.circularProgress}>
								<CircularProgress />
							</GridItem>
							<GridItem xs={12}>
								<h4 className={classes.textInfo}>
									Espere por favor, se esta generando el Certificado de Retenciones de la inversión...
								</h4>
							</GridItem>
						</>
					) : (
						errorRetentionCertificateByInvestmentId && (
							<SnackbarContent message={errorRetentionCertificateByInvestmentId} color='danger' />
						)
					)}
				</GridContainer>
			</DialogContent>
		</Dialog>
	)
}

export default GenerateRCModal
