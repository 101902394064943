import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import Typography from '@mui/material/Typography'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import CustomInput from 'components/CustomInput/CustomInput'
import { PROJECT_LIQUIDITY_DELETE_RESET } from 'local_redux/constants/projectLiquidityConstants'
import { deleteProjectLiquidity } from 'local_redux/actions/projectLiquidityActions'
import styles from '../styles/cancelPDLModalStyles'

const useStyles = makeStyles(styles)

const CancelPDLModal = ({ openModal, setOpenModal, pDLInfo, setProjects }) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [reason, setReason] = useState('')

	const {
		loadingProjectLiquidityDelete,
		successProjectLiquidityDelete,
		errorProjectLiquidityDelete,
		projectLiquidityDeleted,
	} = useSelector((state) => state.deleteProjectLiquidity)

	useEffect(() => {
		let timeOut = ''
		if (successProjectLiquidityDelete) {
			timeOut = setTimeout(() => {
				setProjects((prev) =>
					prev.map((item) =>
						item._id === projectLiquidityDeleted._id ? { ...item, ...projectLiquidityDeleted } : item,
					),
				)
				setOpenModal(false)
			}, 1500)
		}
		return () => clearTimeout(timeOut)
	}, [successProjectLiquidityDelete])
	useEffect(() => {
		return () => dispatch({ type: PROJECT_LIQUIDITY_DELETE_RESET })
	}, [dispatch])

	const handleDeclinePDL = (e) => {
		e.preventDefault()

		dispatch(deleteProjectLiquidity(pDLInfo._id, { reason: reason }))
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={openModal}
			keepMounted
			onClose={() => setOpenModal(false)}
			aria-labelledby='user-modal-delete-title'
			aria-describedby='user-modal-delete-description'
		>
			<DialogTitle id='user-modal-delete-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={() => setOpenModal(false)}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4>Anular PDL</h4>
			</DialogTitle>
			<DialogContent id='user-modal-delete-description' className={classes.modalBody}>
				<form onSubmit={handleDeclinePDL} id='delete-income-date-form'>
					<GridContainer>
						<GridItem xs={12}>
							<Typography>
								¿Estás seguro que quieres Anular el PDL <b>{pDLInfo?.projectAddress}</b>?
							</Typography>
						</GridItem>
						<GridItem xs={12}>
							<CustomInput
								labelText='Motivo *'
								id='reason'
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									value: reason,
									onChange: (e) => {
										setReason(e.target.value)
									},
									type: 'text',
									required: true,
								}}
							/>
						</GridItem>
					</GridContainer>
					{errorProjectLiquidityDelete && (
						<GridContainer>
							<GridItem xs={12}>
								<SnackbarContent message={errorProjectLiquidityDelete} color='danger' />
							</GridItem>
						</GridContainer>
					)}
				</form>
			</DialogContent>
			<DialogActions className={classes.modalFooter}>
				<GridContainer>
					<GridItem xs={6}>
						<Button onClick={() => setOpenModal(false)} block>
							Cancelar
						</Button>
					</GridItem>
					<GridItem xs={6}>
						<Button
							type='submit'
							color={successProjectLiquidityDelete ? 'success' : 'primary'}
							block
							form='delete-income-date-form'
							disabled={loadingProjectLiquidityDelete || successProjectLiquidityDelete || errorProjectLiquidityDelete}
						>
							{loadingProjectLiquidityDelete ? 'Anulando' : successProjectLiquidityDelete ? 'Anulado' : 'Anular'}
						</Button>
					</GridItem>
				</GridContainer>
			</DialogActions>
		</Dialog>
	)
}

export default CancelPDLModal
