import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle'

const styles = (theme) => ({
  ...modalStyle(theme),
  videoContainer: { height: 'auto', minHeight: '50vh', position: 'relative', top: '0', backgroundColor: 'black' },
  backgroundImg: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    transition: (props) => (props.isAnimationDisabled ? 'isAnimationDisabled' : 'opacity 1s ease'),
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    opacity: (props) => (props.backgroundImageLoaded ? 1 : 0),
    backgroundImage: (props) => `url(${props.loadedImage})`,
  },
  btnPlay: {
    textAlign: 'center',
    position: 'absolute',
    top: '40%',
    left: '44%',
  },
  iconBtn: {
    background: 'rgba(0,0,0,0.3)',
    '&:hover': {
      background: 'rgba(0,0,0,0.7)',
    },
  },
  icon: {
    fontSize: '5rem',
    color: '#fff',
    '&:hover': {
      opacity: 0.9,
    },
  },
  '@media screen and (max-width: 680px)': {
    btnPlay: {
      left: '44%',
      top: '40%',
    },
    icon: {
      fontSize: '3rem',
    },
  },
  '@media screen and (max-width: 480px)': {
    btnPlay: {
      left: '44%',
      top: '40%',
    },
  },
})

export default styles
