import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NotificationAdd } from '@mui/icons-material'
import { CardActions, Typography } from '@mui/material'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import CustomInput from 'components/CustomInput/CustomInput'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import GridItem from 'components/Grid/GridItem'
import { registerNotification } from 'local_redux/actions/notificationActions'
import { NOTIFICATION_REGISTER_RESET } from 'local_redux/constants/notificationConstants'

const NotificationRegisterScreen = () => {
  const dispatch = useDispatch()

  const initialState = {
    title: '',
    message: '',
  }

  const [notificationState, setNotificationState] = useState(initialState)

  const { loadingNotificationRegister, successNotificationRegister, errorNotificationRegister } = useSelector(
    (state) => state.notificationRegister
  )

  useEffect(() => {
    let timeOut
    if (successNotificationRegister) {
      timeOut = setTimeout(() => {
        setNotificationState(initialState)
        dispatch({ type: NOTIFICATION_REGISTER_RESET })
      }, 1900)
    }

    return () => clearTimeout(timeOut)
  }, [successNotificationRegister])

  const handleSendNotification = (e) => {
    e.preventDefault()
    dispatch(registerNotification(notificationState))
  }

  return (
    <GridContainer justifyContent='center'>
      <GridItem xs={8}>
        <Card>
          <CardHeader color='primary' icon>
            <CardIcon color='primary'>
              <NotificationAdd />
            </CardIcon>
            <h4>
              <b>Nueva Notificación</b>
            </h4>
          </CardHeader>
          <CardBody>
            <form onSubmit={handleSendNotification} style={{ width: '100%' }} id='register-notification'>
              <GridContainer>
                <GridItem xs={12}>
                  <Typography textAlign='center'>
                    En esta pantalla usted podrá enviar una notificación a todos los inversores.
                  </Typography>
                </GridItem>

                <GridItem xs={12}>
                  <CustomInput
                    labelText={'Titulo *'}
                    id='title'
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: notificationState.title,
                      onChange: (e) => {
                        setNotificationState({ ...notificationState, title: e.target.value })
                      },
                      type: 'text',
                      required: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <CustomInput
                    labelText={'Notificación *'}
                    id='notification'
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: notificationState.message,
                      onChange: (e) => {
                        setNotificationState({ ...notificationState, message: e.target.value })
                      },
                      type: 'text',
                      required: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
            </form>
            {errorNotificationRegister && (
              <GridContainer>
                <GridItem xs={12}>
                  <SnackbarContent message={errorNotificationRegister} color='danger' />
                </GridItem>
              </GridContainer>
            )}
          </CardBody>
          <CardActions>
            <Button
              type='submit'
              color={successNotificationRegister ? 'success' : 'primary'}
              disabled={loadingNotificationRegister || !notificationState.title || !notificationState.message}
              block
              form='register-notification'
            >
              {loadingNotificationRegister ? 'Enviando' : successNotificationRegister ? 'Enviado!' : 'Enviar'}
            </Button>
          </CardActions>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

export default NotificationRegisterScreen
