import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle'

const styles = (theme) => ({
  title: {
    fontSize: 27,
    fontWeight: 400,
    textAlign: 'center',
    margin: '0 0 35px',
  },
  marginAuto: {
    margin: '0% auto',
  },
  cardStyles: { padding: '20px' },
  confirmBtnCssClass: {
    backgroundColor: '#ebe6e2',
    color: '#333333',
    padding: '10px',
    width: '5rem',
    borderRadius: '3px',
    '&:hover': {
      color: '#333333',
    },
  },
  imageMask: {
    width: '75%',
    margin: '0 auto 40px',
    maxHeight: '400px',
    overflow: 'hidden',
    objectFit: 'cover',
    backgroundPosition: 'bottom',
    borderRadius: '6px',
    boxShadow: '0 0 10px 4px rgb(0 0 0 / 16%)',
  },
  infoDiv: {
    backgroundColor: '#f2f1eb',
    margin: '0 auto',
    border: '2px solid #c4c0b2',
    borderLeft: '6px solid #c4c0b2',
    borderRadius: '10px 0 0 10px',
    padding: '0px 5px 5px 5px !important',
    height: '100%',
  },
  detailImage: {
    display: 'flex',
    alignItems: 'center',
  },
  rightDetailTitle: {
    textAlign: 'right',
  },
  centerDetailTitle: {
    textAlign: 'center',
  },
  detailBotom: {
    marginTop: '20px',
  },
  infoItems: {
    margin: '0 0 12px',
  },
  detailTitle: {
    fontWeight: '900',
    marginTop: 10,
    textAlign: 'center',
  },
  detailCardSubtitle: {
    fontWeight: '900',
  },
  numbersTitle: {
    fontWeight: '900',
    fontSize: '19px',
  },
  numbersTitleCenter: {
    textAlign: 'center',
    fontWeight: '900',
  },
  infoRoot: {
    border: '1px solid #d4d3d3',
    padding: '0% !important',
    borderRadius: '6px',
    padding: '15px 10px',
    margin: '0 0 20px',
    width: '100%',
    boxShadow: '0 1px 11px 0 rgb(0 0 0 / 14%)',
  },
  filesDiv: {
    marginBottom: '20px',
  },
  imagesDiv: {
    marginBottom: '10px',
    '& img': {
      borderRadius: '6px',
      marginBottom: '10px',
      boxShadow: '0 1px 4px 0 rgb(0 0 0 / 14%)',
    },
  },
  ...modalStyle(theme),
})

export default styles
