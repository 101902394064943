import { Tooltip } from '@mui/material'
import { ArrowForward } from '@mui/icons-material'
import { Edit } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'

export const liquidatedProjectColumns = ({ handleSelectProject, openUpdateProjectModal }) => [
	{
		header: 'Dirección de Proyecto',
		accessor: 'projectAddress',
		size: 'sm',
	},
	{
		header: 'Inversión Total',
		accessor: 'projectCost',
		size: 'sm',
	},
	{
		header: 'Porcentaje de inversión Total',
		accessor: 'investments',
		size: 'sm',
	},
	{
		header: 'Acciones',
		accessor: 'actions',
		headerAlign: 'right',
		cellAlign: 'right',
		size: 'sm',
		cell: ({ row }) => (
			<div className='actions-right'>
				<Tooltip id='tooltip-top' title='Editar' placement='bottom'>
					<Button
						justIcon
						round
						simple
						onClick={() => {
							openUpdateProjectModal(row.item)
						}}
						color='success'
						className='toInvest'
					>
						<Edit />
					</Button>
				</Tooltip>
				<Tooltip id='tooltip-top' title='Liquidar Proyecto' placement='bottom'>
					<Button
						justIcon
						round
						simple
						onClick={() => {
							handleSelectProject(row.item)
						}}
						color='success'
						className='toInvest'
					>
						<ArrowForward />
					</Button>
				</Tooltip>
			</div>
		),
	},
]
