const styles = {
  tirBox: {
    backgroundColor: '#f2f1eb',
    border: '1px solid #c4c0b2',
    paddingBottom: '15px !important',
    margin: '15px 0',
  },
}

export default styles
