import { successColor, tooltip, cardTitle, grayColor } from 'assets/jss/material-dashboard-pro-react'
import hoverCardStyle from 'assets/jss/material-dashboard-pro-react/hoverCardStyle'

const dashboardStyle = {
	root: {
		marginBottom: '45px',
		maxWidth: '650px',
	},
	confirmBtnCssClass: {
		backgroundColor: '#ebe6e2',
		color: '#333333',
		padding: '10px',
		width: '5rem',
		borderRadius: '3px',
		'&:hover': {
			color: '#333333',
		},
	},
	...hoverCardStyle,
	cursorPointer: {
		cursor: 'pointer',
		position: 'relative',
		display: 'inline-block',
		textAlign: 'center',
		width: '100%',
	},
	imageText: {
		position: 'absolute',
		left: '0',
		right: '0',
		bottom: '10px',
		margin: 'auto',
		backgroundColor: '#ff0000',
		padding: '5px',
		width: '100%',
		height: 'fit-content',
	},
	newOpportunity: {
		position: 'absolute',
		left: '0',
		right: '0',
		bottom: '10px',
		margin: 'auto',
		backgroundImage: 'linear-gradient(to bottom right, #EABE3F, #E5A65E)',
		padding: '5px',
		width: '100%',
		height: 'fit-content',
	},
	imageBtn: {
		position: 'absolute',
		top: '10px',
		right: '10px',
		margin: '0px',
	},
	imageCard: {
		objectFit: 'cover',
		objectPosition: 'center',
		aspectRatio: '4 / 3',
	},
	videoCard: {
		backgroundSize: 'cover',
		objectPosition: 'center',
		aspectRatio: '4 / 3',
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		borderRadius: '6px',
	},
	buttonCard: {
		paddingLeft: '0',
		paddingRight: '0',
	},
	spanButtonCard: {
		overflow: 'hidden',
	},
	totalInvertibleContainer: {
		marginBottom: 10,
	},
	investmentsProgressBarContainer: {
		marginBottom: '15px',
	},
	projectStatusContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	projectStatus: {
		whiteSpace: 'nowrap',
	},
	videoIcon: {
		marginLeft: 5,
		background: 'transparent',
		boxShadow: 'none',
		color: '#c0bbac',
		'&:hover': {
			background: '#fefefe',
			color: '#c0bbac',
		},
		'&:focus': {
			background: '#fefefe',
			color: '#c0bbac',
		},
	},
	tooltip,
	cardTitle: {
		...cardTitle,
		marginTop: '0px',
		marginBottom: '3px',
	},
	cardBodyWithMargin: {
		marginTop: '10px',
	},
	cardViewDetailButton: {
		marginRight: '5px',
	},
	detailCardSubtitle: {
		fontSize: '12px',
		fontWeight: '600',
	},
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
	},
	cardProductTitle: {
		...cardTitle,
		fontWeight: '400 !important',
		color: '#686c73 !important',
		marginTop: '0px',
		marginBottom: '3px',
		textAlign: 'center',
	},
	cardCategory: {
		color: grayColor[0],
		fontSize: '14px',
		paddingTop: '10px',
		marginBottom: '0',
		marginTop: '0',
		margin: '0',
	},
	cardProductDesciprion: {
		textAlign: 'center',
		color: grayColor[0],
	},
	cardDescription: {
		padding: '10px',
	},
	productStats: {
		paddingTop: '7px',
		paddingBottom: '7px',
		margin: '0',
	},
	successText: {
		color: successColor[0],
	},
	upArrowCardCategory: {
		width: 14,
		height: 14,
	},
	underChartIcons: {
		width: '17px',
		height: '17px',
	},
	price: {
		color: 'inherit',
		'& h4': {
			marginBottom: '0px',
			marginTop: '0px',
		},
	},
	cardAnimation: {
		animation: '$fadeInOut ease-in-out 0.6s',
		animationIterationCount: '2',
	},

	'@keyframes fadeInOut': {
		'30%': { transform: 'translate3d(0, 0px, 0)' },
		'68%': { transform: 'translate3d(0, -50px, 0)' },
		'100%': { transform: 'translate3d(0, 0px, 0)' },
	},
}

export default dashboardStyle
