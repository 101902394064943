import { format } from 'date-fns'
import { investmentLiquidationOptions } from 'shared/general-config/investment-contrants'

const reinvestedIn = (projects) => {
	return projects.map((el) => ` ${el?.projectAddress || ''}`).join()
}
export const liquidationMatch = {
	pay: 'Recibidos',
	reinvest: 'Reinvertir',
	'benefits-only': 'Solo beneficios recibidos',
}
export const getReinvestProjects = (investment) => {
	let projects
	if (investment?.rootInvestment) {
		if (investment?.finished.length > 0) {
			const compareDate = format(new Date(investment?.finished[0]?.createdAt), 'yyyy-MM-dd')
			const projectsIds = investment?.childrenRootInvestment
				?.filter((invest) => invest?.incomeRecivedDate === compareDate)
				?.map((invest) => invest?.project)
			projects = investment?.projectFromChildrenRootInvestment?.filter((project) => projectsIds?.includes(project?._id))
		}
	} else {
		projects = investment?.projectFromChildrenInvestment
	}
	return projects
}

export const getLiquidationStatus = (investment) => {
	let status = 'No procesado'
	const projects = getReinvestProjects(investment)
	if (investment?.wasInvestment) {
		status = `${
			investment?.liquidation === investmentLiquidationOptions.benefitsOnly
				? 'Importe inicial reinvertido'
				: 'Reinvertidos'
		} en ${reinvestedIn(projects) || investment?.project?.projectAddress}`
	} else if (investment?.liquidation) {
		status = liquidationMatch[investment?.liquidation] || 'No procesado'
	}

	return status
}
