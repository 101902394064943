import React, { useContext, useEffect, useState } from 'react'
import ReactExport from 'react-data-export'
import { makeStyles, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import { differenceInCalendarDays, format } from 'date-fns'
import { changeIncomeReciveDate } from 'shared/helpers/functions-helpers'
import { matchNumberToCurrency, replaceStringToNum } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import { LiquidatedProjectContext } from 'contexts/liquidatedProjectContext'
import styles from '../styles/downloadInvestmentsExcelStyles'

const useStyles = makeStyles(styles)

const excelKeys = [
	'incluir',
	'Perfil Inversor',
	'IBAN',
	'Fecha de inversión',
	'Fecha de devolución',
	'Importe de inversión',
	'Liquidación',
	'Participación',
	'Retención',
	'Beneficio',
	'Importe de retención',
	'TIR Inversor',
	'A pagar',
	'Días',
	'Beneficio para TIR =12%',
	'Base imponible para Success Fee',
	'Fee tasa (50%)',
	'Fee',
]

const DownloadInvestmensExcel = ({ excelName, data }) => {
	const classes = useStyles()
	const ExcelFile = ReactExport.ExcelFile
	const [open, setOpen] = useState(false)
	const { selectedProject } = useContext(LiquidatedProjectContext)
	const [excelInfo, setExcelInfo] = useState([])

	useEffect(() => {
		const dataFromExcel = data.map((item) => {
			return {
				incluir: item.csvProfileInclude ? 'Incluido' : 'No Incluido',
				'Perfil Inversor': item.investorProfile.businessName,
				IBAN: item.investorProfile.bankAccountNumber || 'Sin iban',
				'Fecha de inversión': item.incomeRecivedDate
					? format(new Date(item.incomeRecivedDate), 'dd-MM-yyyy')
					: 'Sin fecha',
				'Fecha de devolución': format(new Date(selectedProject.depositDate), 'dd-MM-yyyy'),
				'Importe de inversión': item.amount,
				Liquidación:
					item.liquidation === 'reinvest'
						? 'Reinvertir'
						: item.liquidation === 'pay'
						? 'Pagar'
						: 'Pagar solo beneficios',
				Participación: `${parseFloat(
					(replaceStringToNum(item.amount) / replaceStringToNum(selectedProject.projectCost)) * 100,
				).toFixed(2)}`,
				Retención: item.retention || matchNumberToCurrency(0),
				Beneficio: item.benefit || matchNumberToCurrency(0),
				'Importe de retención': item.retentionAmount || matchNumberToCurrency(0),
				'TIR Inversor': item.investorTIR || matchNumberToCurrency(0),
				'A pagar': item.toPay || matchNumberToCurrency(0),
				Días: item.incomeRecivedDate
					? differenceInCalendarDays(
							new Date(selectedProject.depositDate),
							new Date(changeIncomeReciveDate(item.incomeRecivedDate, selectedProject.startDateUpdated)),
					  ) - 1
					: 'No Recibido',
				'Beneficio para TIR =12%': item.benefitForIRR ? item.benefitForIRR : matchNumberToCurrency(0),
				'Base imponible para Success Fee': item.taxBase
					? matchNumberToCurrency(item.taxBase)
					: matchNumberToCurrency(0),
				'Fee tasa (50%)': `${item.feeRate} %`,
				Fee: item.fee ? item.fee : matchNumberToCurrency(0),
			}
		})

		setExcelInfo(dataFromExcel)
	}, [open])

	const handleSendExcel = (e) => {
		e.preventDefault()
		setOpen(false)
	}

	const handleCloseModal = () => {
		setOpen(false)
	}

	return (
		<>
			<div className={classes.btnExport}>
				<Button color='primary' onClick={() => setOpen(true)}>
					Exportar Inversiones a Excel
				</Button>
			</div>

			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modal,
				}}
				open={open}
				keepMounted
				onClose={handleCloseModal}
				aria-labelledby='notice-modal-slide-title'
				aria-describedby='notice-modal-slide-description'
			>
				<form onSubmit={handleSendExcel} autoComplete='false'>
					<DialogTitle id='notice-modal-slide-title' disableTypography className={classes.modalHeader}>
						<Button
							justIcon
							className={classes.modalCloseButton}
							key='close'
							aria-label='Close'
							color='transparent'
							onClick={handleCloseModal}
						>
							<Close className={classes.modalClose} />
						</Button>
						<h4 className={classes.modalTitle}>{excelName}</h4>
					</DialogTitle>

					<DialogContent id='notice-modal-slide-description' className={classes.modalBody}>
						<GridContainer>
							<GridItem xs={12}>
								<p>¿Está seguro que desea exportar {excelName} en formato excel? </p>
							</GridItem>
						</GridContainer>
					</DialogContent>
					<DialogActions className={classes.modalFooter}>
						<GridContainer className={classes.rootModal}>
							<GridItem xs={12}>
								<Button className={classes.btnModal} onClick={() => setOpen(false)}>
									Cancelar
								</Button>

								<ExcelFile
									element={
										<Button type='submit' color='primary'>
											Exportar Excel
										</Button>
									}
									filename={excelName}
									hideElement={false}
								>
									<ExcelFile.ExcelSheet
										data={excelInfo.map((row) => {
											return {
												...row,
												'TIR Inversor': parseFloat(row['TIR Inversor']),
												Participación: parseFloat(row['Participación'].replace('%', '')),
												'Importe de inversión': replaceStringToNum(row['Importe de inversión']),
												'A pagar': replaceStringToNum(row['A pagar']),
												Beneficio: parseFloat(row['Beneficio']),
												'Importe de retención': replaceStringToNum(row['Importe de retención']),
												'Beneficio para TIR =12%': replaceStringToNum(row['Beneficio para TIR =12%']),
												'Base imponible para Success Fee': replaceStringToNum(row['Base imponible para Success Fee']),
												'Fee tasa (50%)': parseFloat(row['Fee tasa (50%)'].replace('%', '')),
											}
										})}
										name={excelName}
									>
										{excelKeys &&
											excelKeys.map((column, i) => (
												<ExcelFile.ExcelColumn key={i} label={excelKeys[i]} value={excelKeys[i]} />
											))}
									</ExcelFile.ExcelSheet>
								</ExcelFile>
							</GridItem>
						</GridContainer>
					</DialogActions>
				</form>
			</Dialog>
		</>
	)
}

export default DownloadInvestmensExcel
