import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import SweetAlert from 'react-bootstrap-sweetalert'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'
import { PROJECT_LIQUIDITY_LIQUIDATE_RESET } from 'local_redux/constants/projectLiquidityConstants'
import styles from '../styles/downloadCSVModalStyles'

const useStyles = makeStyles(styles)

const DownloadCSVModal = ({
	setDownloadCSVModal,
	downloadCSVModal,
	projectSelected,
	setAlert,
	setProjectSelected,
	setProjectList,
	setConfirmationLiquidateModal,
}) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const { projectLiquidityliquidated } = useSelector((state) => state.projectLiquidityLiquidate)
	const { userInfo } = useSelector((state) => state.userLogin)

	const handleClose = () => {
		setProjectList((prev) => prev.filter((item) => item.id !== projectLiquidityliquidated.project))
		setProjectSelected({})
		dispatch({ type: PROJECT_LIQUIDITY_LIQUIDATE_RESET })
		setAlert(null)
		setConfirmationLiquidateModal(false)
		setDownloadCSVModal(false)
	}
	const handleSubmitDownloadCSV = (e) => {
		e.preventDefault()

		window.open(getUrlToOpenFile(projectLiquidityliquidated.csvFilePath, userInfo))

		setAlert(
			<SweetAlert
				success
				style={{ display: 'block', marginTop: '-100px' }}
				title='¡Hecho!'
				onConfirm={handleClose}
				onCancel={handleClose}
				confirmBtnCssClass={classes.confirmBtnCssClass}
			>
				<div style={{ textAlign: 'center' }}>Se han enviado notificaciones por correo!</div>
			</SweetAlert>,
		)
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={downloadCSVModal}
			keepMounted
			onClose={() => setDownloadCSVModal(false)}
			aria-labelledby='income-confirm-modal-title'
			aria-describedby='income-confirm-modal-description'
		>
			<DialogTitle id='income-confirm-modal-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={() => setDownloadCSVModal(false)}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4>
					Proceso de Liquidación Completado para <b>{projectSelected.projectAddress}</b>
				</h4>
			</DialogTitle>
			<DialogContent id='income-confirm-modal-description' className={classes.modalBody}>
				<form onSubmit={handleSubmitDownloadCSV} id='income-confirmation-form'>
					<GridContainer>
						<GridItem xs={12}>
							<div
								style={{
									margin: 'auto',
								}}
							>
								<p>{`¿Desea descargar el archivo CSV del proyecto ${projectSelected.projectAddress}?`}</p>
							</div>
						</GridItem>
					</GridContainer>
				</form>
			</DialogContent>
			<DialogActions className={classes.modalFooter}>
				<GridContainer>
					<GridItem xs={12}>
						<Button type='submit' color='primary' block form='income-confirmation-form'>
							Descargar
						</Button>
						<Button type='button' color='secondary' block form='income-confirmation-form' onClick={() => handleClose()}>
							Cancelar
						</Button>
					</GridItem>
				</GridContainer>
			</DialogActions>
		</Dialog>
	)
}

export default DownloadCSVModal
