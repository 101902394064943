import { format } from 'date-fns'
import { Tooltip } from '@material-ui/core'
import { VideoCameraBack, Description } from '@mui/icons-material'
import Button from 'components/CustomButtons/Button'
import ProjectTimeProgressBars from 'components/ProjectTimeProgressBar/ProjectTimeProgressBar'
import { formatCurrency } from 'shared/helpers/currency-helpers'

export const investmentsActiveMapper = (investments, videoShowHandler, navigate) => {
	return investments.map((investment) => {
		const {
			deedOfPurchase,
			simpleNotePurchasedProperty,
			acquisitionExpenses,
			billsOfExpenses,
			deedOfSale,
			simpleNotePriorToPurchase,
		} = investment.project

		const isDocumentsPresent = new Set([
			deedOfPurchase,
			simpleNotePurchasedProperty,
			acquisitionExpenses,
			billsOfExpenses,
			deedOfSale,
			simpleNotePriorToPurchase,
		])

		return {
			profileId: investment.investorProfile || investment.profileInvestor,
			id: investment._id,
			profileInvestor: investment?.investor?.businessName,
			incomeRecivedDate: investment?.incomeRecivedDate
				? format(new Date(investment.incomeRecivedDate), 'dd-MM-yyyy')
				: 'Pendiente',
			amount:
				investment?.amount || (investment?.amountPdl && formatCurrency(investment?.amountPdl)) || 'No establecido',
			project: investment?.project?.projectAddress,
			investmentType: investment?.investmentType ? 'PDL' : 'Coinversión',
			status: 'Activa',
			contract: investment?.contractUrl,
			log: investment?.createdAt,
			progressProject:
				investment.project.startDateUpdated && investment.project.finishDateUpdated ? (
					<>
						<ProjectTimeProgressBars
							startDate={investment.project.startDateUpdated}
							endDate={investment.project.finishDateUpdated}
						/>
					</>
				) : (
					'Proyecto no encontrado'
				),
			actions: (
				<div className='actions-right'>
					{investment.project?.images?.length > 0 && (
						<Tooltip id='tooltip-top' title='Ver Evolución del Proyecto' placement='bottom'>
							<Button
								justIcon
								round
								simple
								onClick={() => videoShowHandler(investment.project)}
								color='success'
								className='download-Document'
							>
								<VideoCameraBack />
							</Button>
						</Tooltip>
					)}
					{Array.from(isDocumentsPresent).filter((item) => item).length > 0 && (
						<Tooltip id='tooltip-top' title='Transparencia' placement='bottom'>
							<Button
								justIcon
								round
								simple
								onClick={() => navigate(`/admin/projects?projectId=${investment.project._id}`)}
								color='success'
								className='transparency'
							>
								<Description />
							</Button>
						</Tooltip>
					)}
				</div>
			),
		}
	})
}
