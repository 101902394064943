const styles = {
  tooltip: {
    boxShadow: '0px 2px 20px lightgray',
    backGroundColor: 'fff !important',
    zIndex: `${100000}  !important`,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  tippy: {
    background: '#fff',
    padding: '15px',
    color: 'black',
    boxShadow: '0px 2px 20px lightgray',
  },
}
export default styles
