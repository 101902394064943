import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle'

const styles = (theme) => ({
	infoRoot: {
		border: '1px solid #d4d3d3',
		borderRadius: '6px',
		padding: '15px 10px',
		margin: '0 0 20px',
		width: '100%',
		boxShadow: '0 1px 11px 0 rgb(0 0 0 / 14%)',
	},
	ulStyles: {
		padding: '0 15px 0 15px',
	},
	confirmBtnCssClass: {
		backgroundColor: '#c0bbac',
		color: '#fff',
		padding: '10px',
		width: '5rem',
		borderRadius: '3px',
		'&:hover': {
			color: '#fff',
		},
	},
	...modalStyle(theme),
})

export default styles
