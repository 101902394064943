import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import Typography from '@mui/material/Typography'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { PRE_REGISTER_UPDATE_RESET } from 'local_redux/constants/preRegisterConstants'
import { updatePreRegister } from 'local_redux/actions/preRegisterActions'
import styles from '../styles/updatePreRegisterLinkModalStyles'

const useStyles = makeStyles(styles)

const UpdatePreRegisterLinkModal = ({
  handleCloseUpdatePreRegisterLinkModal,
  updatePreRegisterLinkModal,
  showUpdatePreRegisterLinkInfo,
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const { successPreRegisterUpdate, errorPreRegisterUpdate, loadingPreRegisterUpdate } = useSelector(
    (state) => state.preRegisterUpdate
  )

  useEffect(() => {
    let timeOut
    if (successPreRegisterUpdate) {
      timeOut = setTimeout(() => {
        handleCloseUpdatePreRegisterLinkModal()
      }, 1000)
    }

    return () => clearTimeout(timeOut)
  }, [successPreRegisterUpdate])
  useEffect(() => {
    return () => dispatch({ type: PRE_REGISTER_UPDATE_RESET })
  }, [dispatch])

  const handleUpdate = (e) => {
    e.preventDefault()
    dispatch(updatePreRegister(showUpdatePreRegisterLinkInfo))
  }

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal,
      }}
      open={updatePreRegisterLinkModal}
      keepMounted
      onClose={handleCloseUpdatePreRegisterLinkModal}
      aria-labelledby='user-modal-delete-title'
      aria-describedby='user-modal-delete-description'
    >
      <DialogTitle id='user-modal-delete-title' disableTypography className={classes.modalHeader}>
        <Button
          justIcon
          className={classes.modalCloseButton}
          key='close'
          aria-label='Close'
          color='transparent'
          onClick={handleCloseUpdatePreRegisterLinkModal}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h4>Link de Pre-Registro</h4>
      </DialogTitle>
      <DialogContent id='user-modal-delete-description' className={classes.modalBody}>
        <form onSubmit={handleUpdate} id="update-preregister-form">
          <GridContainer>
            <GridItem xs={12}>
              <Typography>¿Está seguro que quiere rehacer link de preregistro?</Typography>
            </GridItem>
          </GridContainer>
          {errorPreRegisterUpdate && (
            <GridContainer>
              <GridItem xs={12}>
                <SnackbarContent message={errorPreRegisterUpdate} color='danger' />
              </GridItem>
            </GridContainer>
          )}
        </form>
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <GridContainer>
          <GridItem xs={6}>
            <Button onClick={handleCloseUpdatePreRegisterLinkModal} block>
              Cancelar
            </Button>
          </GridItem>
          <GridItem xs={6}>
            <Button
            type='submit'
            form="update-preregister-form"
            color={successPreRegisterUpdate ? 'success' : 'primary'}
            block
            >
              {loadingPreRegisterUpdate ? 'Confirmando' : successPreRegisterUpdate ? 'Confirmado' : 'Confirmar'}
            </Button>
          </GridItem>
        </GridContainer>
      </DialogActions>
    </Dialog>
  )
}

export default UpdatePreRegisterLinkModal
