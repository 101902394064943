import { useNavigate } from 'react-router-dom'
import { Close } from '@material-ui/icons'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Typography from '@mui/material/Typography'
import styles from '../styles/finishedDocumentValidationModalStyles'

const useStyles = makeStyles(styles)

const FinishedDocumentNotExistsModal = ({
	handleCloseFinishedDocumentValidationModal,
	finishedDocumentValidationModal,
}) => {
	const navigate = useNavigate()
	const classes = useStyles()

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={finishedDocumentValidationModal}
			keepMounted
			onClose={handleCloseFinishedDocumentValidationModal}
			aria-labelledby='finished-document-validation-title'
			aria-describedby='finished-document-validation-description'
		>
			<DialogTitle id='finished-document-validation-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={handleCloseFinishedDocumentValidationModal}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4>Documento de Finalización de proyecto</h4>
			</DialogTitle>

			<DialogContent id='finished-document-validation-description' className={classes.modalBody}>
				<GridContainer>
					<GridItem xs={12}>
						<Typography>
							Este proyecto no incluye el documento <b>Dossier fin de Proyecto</b>, que se debe adjuntar en el mail de
							información al co-inversor.
						</Typography>
						<Typography>
							Puede cargar el <b>Dossier fin de Proyecto</b> desde la lista de proyectos.
						</Typography>
					</GridItem>
				</GridContainer>
			</DialogContent>
			<DialogActions className={classes.modalFooter}>
				<GridContainer>
					<GridItem xs={12}>
						<Button onClick={() => navigate(`/admin/list-projects`)} block>
							Cargar dossier
						</Button>
					</GridItem>
				</GridContainer>
			</DialogActions>
		</Dialog>
	)
}

export default FinishedDocumentNotExistsModal
