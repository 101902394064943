import { useSelector } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Typography from '@mui/material/Typography'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'
import styles from '../styles/deleteProjectModalStyles'

const useStyles = makeStyles(styles)

const DownloadCsvModal = ({ handleCloseDownloadModal, downloadModal, showCsvInfo }) => {
	const classes = useStyles()

	const { userInfo } = useSelector((state) => state.userLogin)

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={downloadModal}
			keepMounted
			onClose={handleCloseDownloadModal}
			aria-labelledby='project-modal-delete-title'
			aria-describedby='project-modal-delete-description'
		>
			<DialogTitle id='project-modal-delete-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={handleCloseDownloadModal}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4>Descargar CSV</h4>
			</DialogTitle>

			<DialogContent id='project-modal-delete-description' className={classes.modalBody}>
				<GridContainer>
					<GridItem xs={12}>
						<Typography>
							¿Está seguro que desea descargar el {showCsvInfo?.type} de {showCsvInfo?.label}?
						</Typography>
					</GridItem>
				</GridContainer>
			</DialogContent>
			<DialogActions className={classes.modalFooter}>
				<GridContainer>
					<GridItem xs={6}>
						<Button block onClick={handleCloseDownloadModal}>
							Cancelar
						</Button>
					</GridItem>
					<GridItem xs={6}>
						<Button
							Button
							color='primary'
							block
							onClick={() => {
								window.open(getUrlToOpenFile(showCsvInfo.csvFilePath, userInfo))
								handleCloseDownloadModal()
							}}
						>
							Descargar
						</Button>
					</GridItem>
				</GridContainer>
			</DialogActions>
		</Dialog>
	)
}

export default DownloadCsvModal
