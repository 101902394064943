import { makeStyles } from '@material-ui/core'
import { ContentPasteSearch } from '@mui/icons-material'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import styles from '../styles/makeASearchStyles'

const useStyles = makeStyles(styles)

const MakeASearch = () => {
  const classes = useStyles()

  return (
    <GridContainer className={classes.gridSearch}>
      <GridItem xs={12} className={classes.iconSearch}>
        <ContentPasteSearch />
      </GridItem>
      <GridItem xs={12}>
        <p>Realice una Búsqueda</p>
      </GridItem>
    </GridContainer>
  )
}

export default MakeASearch
