import { useState } from 'react'
import { NumericFormat } from 'react-number-format'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { matchNumberToCurrency } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import { useSelector } from 'react-redux'
import styles from '../styles/reinvestmentModalStyles'

const useStyles = makeStyles(styles)

const ReInvestPDLTable = ({ data, projectData, handleChange, handleDateReinvest }) => {
	const classes = useStyles(styles)

	const { userInfo } = useSelector((state) => state.userLogin)

	const [rowValues, setRowValues] = useState({ typeOfInterest: 12, reinvestmentAmount: 0 })

	const dateReinvest = userInfo.isAdmin || userInfo.isSuper ? ['Fecha de Inversión'] : []

	const handleChangeDate = (value, item) => {
		handleDateReinvest(value, item)
	}
	return (
		<div className='ReactTable -striped -highlight'>
			<table className={'rt-table '} style={{ display: 'table', width: '100%' }}>
				<thead className='rt-thead -header'>
					<tr className='rt-tr'>
						{['Proyectos', 'Importe Invertible', '¿Cuánto reinvierte?', 'Porcentaje de Interés']
							.concat(dateReinvest)
							.map((item, i) => (
								<th key={i} className={classnames('rt-th rt-resizable-header')}>
									{item}
								</th>
							))}
					</tr>
				</thead>
				<tbody className='rt-thead -header'>
					{data.map((item, i) => {
						return (
							<tr
								key={i}
								className={
									classnames('rt-tr', { ' -odd': i % 2 === 0 }, { ' -even': i % 2 === 1 }) +
									` ${classes.hoverCell} ${classes.trWidthBig}`
								}
							>
								<td className={classnames('rt-td', classes.widthBig)}>{item.projectAddress}</td>
								<td className={classnames('rt-td', classes.widthBig)}>
									{matchNumberToCurrency(item.amountInvertible)}
								</td>
								<td className={classnames('rt-td', classes.widthBig)}>
									{
										<NumericFormat
											className={classes.inputFormat}
											decimalSeparator=','
											thousandsGroupStyle='mil'
											thousandSeparator='.'
											decimalScale={2}
											allowedDecimalSeparators=','
											fixedDecimalScale={true}
											valueIsNumericString={true}
											value={projectData?.find((project) => project?._id === item._id)?.reinvestmentAmount}
											onValueChange={(value) => {
												setRowValues((prev) => {
													return { ...prev, reinvestmentAmount: value.value }
												})
												handleChange(
													{ reinvestmentAmount: value.value, typeOfInterest: rowValues.typeOfInterest },
													item,
												)
											}}
											allowNegative={false}
											suffix={' €'}
											placeholder='0,00 €'
										/>
									}
								</td>
								<td className={classnames('rt-td', classes.widthBig)}>
									{
										<NumericFormat
											className={classes.inputFormat}
											decimalSeparator=','
											thousandsGroupStyle='mil'
											thousandSeparator='.'
											decimalScale={2}
											allowedDecimalSeparators=','
											fixedDecimalScale={true}
											valueIsNumericString={true}
											value={projectData?.find((project) => project?._id === item._id)?.typeOfInterest}
											onValueChange={(value) => {
												setRowValues((prev) => {
													return { ...prev, typeOfInterest: value.value }
												})
												handleChange(
													{ reinvestmentAmount: rowValues.reinvestmentAmount, typeOfInterest: value.value },
													item,
												)
											}}
											allowNegative={false}
											suffix={' %'}
											placeholder='12%'
										/>
									}
								</td>
								{dateReinvest.length ? (
									<td className={classnames('rt-td', classes.widthBig)}>
										<DatePicker
											value={projectData?.find((project) => project?._id === item._id)?.dateReinvest || null}
											label=''
											onChange={(value) => handleChangeDate(value, item)}
											className={classes.dateInput}
											format='dd/MM/yyyy'
											disableFuture
										/>
									</td>
								) : null}
							</tr>
						)
					})}
				</tbody>
			</table>
		</div>
	)
}

export default ReInvestPDLTable
