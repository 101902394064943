import { percentageSum } from 'shared/helpers/functions-helpers'

export const liquidatedProjectMapper = (projects) => {
	return projects.map((item) => ({
		id: item._id,
		item: item,
		projectAddress: `${item.projectAddress}`.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
		projectCost: `${item.projectCost}`,
		investments: `${percentageSum(item.investments, item.projectCost)}`,
		actions: '',
	}))
}
