import { useState } from 'react'
import { FormControl, Select as Selectable, MenuItem, InputLabel } from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomInput from 'components/CustomInput/CustomInput'
import Buttom from 'components/CustomButtons/Button'
import GeneratePasswordModal from 'components/GeneratePasswordModal/GeneratePasswordModal'
import { matchGender } from 'shared/matchData/matchData'

const UserProfileInputs = ({ setUserInfo, userInfo }) => {
  const [genetatePasswordModal, setGenetatePasswordModal] = useState(false)
  const [showGeneratePasswordInfo, setShowGeneratePasswordInfo] = useState({})

  const showDeleteInfoHandler = () => {
    setShowGeneratePasswordInfo(userInfo)
    setGenetatePasswordModal(true)
  }
  const handleCloseGenetatePasswordModal = () => {
    setGenetatePasswordModal(false)
    setShowGeneratePasswordInfo({})
  }

  return (
    <GridItem xs={12}>
      <GridContainer style={{ marginBottom: '26px' }}>
        <GridItem xs={12} md={6}>
          <CustomInput
            labelText={'Nombre *'}
            id='name'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: userInfo.name,
              onChange: (e) => setUserInfo({ ...userInfo, name: e.target.value }),
              type: 'text',
              required: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <CustomInput
            labelText={'Apellido'}
            id='lastName'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: userInfo.lastName,
              onChange: (e) => setUserInfo({ ...userInfo, lastName: e.target.value }),
              type: 'text',
              required: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <CustomInput
            labelText={'Email *'}
            id='email'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: userInfo.email,
              onChange: (e) => setUserInfo({ ...userInfo, email: e.target.value }),
              type: 'email',
              required: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <CustomInput
            labelText='DNI/NIE *'
            id='dni'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: userInfo.dni,
              onChange: (e) => setUserInfo({ ...userInfo, dni: e.target.value }),
              type: 'text',
              required: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <CustomInput
            labelText='Teléfono *'
            id='phone'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: userInfo.phone,
              onChange: (e) => setUserInfo({ ...userInfo, phone: e.target.value }),
              type: 'text',
              required: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <CustomInput
            labelText='Teléfono 2 (No principal)'
            id='phone2'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: userInfo?.phone2 || '',
              onChange: (e) => setUserInfo({ ...userInfo, phone2: e.target.value }),
              type: 'text',
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <CustomInput
            labelText='Dirección *'
            id='address'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: userInfo.address,
              onChange: (e) => setUserInfo({ ...userInfo, address: e.target.value }),
              type: 'text',
              required: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={8}>
          <CustomInput
            labelText='Contraseña'
            id='passphrase'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: userInfo.password || '',
              onChange: (e) => setUserInfo({ ...userInfo, password: e.target.value }),
              type: 'password',
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={4} style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Buttom onClick={showDeleteInfoHandler} block>
            Generar Contraseña
          </Buttom>
        </GridItem>
        <GridItem xs={12} style={{ fontSize: '11px' }}>
          * La contraseña debe tener minimo 8 caracteres, una mayúscula, una minúscula y un número.
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={3}>
          <FormControl fullWidth>
            <InputLabel htmlFor='investor-register-gender'>Género *</InputLabel>
            <Selectable
              value={matchGender(userInfo.gender, true)}
              onChange={(e) => setUserInfo({ ...userInfo, gender: e.target.value })}
              inputProps={{
                name: 'investor-register-gender',
                id: 'investor-register-gender',
              }}
            >
              <MenuItem disabled>Selecciona una Opción</MenuItem>
              {['Mujer', 'Hombre'].map((entityType, index) => (
                <MenuItem value={entityType} key={index}>
                  {entityType}
                </MenuItem>
              ))}
            </Selectable>
          </FormControl>
        </GridItem>
      </GridContainer>
      {genetatePasswordModal && (
        <GeneratePasswordModal
          handleCloseGenetatePasswordModal={handleCloseGenetatePasswordModal}
          genetatePasswordModal={genetatePasswordModal}
          showGeneratePasswordInfo={showGeneratePasswordInfo}
        />
      )}
    </GridItem>
  )
}

export default UserProfileInputs
